import React, { useState, useEffect } from 'react';
import './ImageCarousel.css';

function ImageCarousel({ images }) {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 2500); // Slide every 2 seconds

    return () => {
      clearInterval(interval);
    };
  }, [images]);

  return (
    <div className="container-carousel">
      <div className="carousel-inner" style={{ transform: `translateX(-${activeIndex * 100}%)` }}>
        {images.map((image, index) => (
          <div key={index} className="carousel-slide">
            <img src={image} alt={`Slide ${index + 1}`} className="carousel-img" />
          </div>
        ))}
      </div>

      <div className="carousel-dots-container">
        {images.map((_, index) => (
          <div
            key={index}
            className={`carousel-dots ${index === activeIndex ? 'active' : ''}`}
          />
        ))}
      </div>
    </div>
  );
}

export default ImageCarousel;
