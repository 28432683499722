import React, { useRef, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AiFillCloseCircle } from "react-icons/ai";
import { useUserAuth } from "../../../context/UserAuthContext";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { ClickAwayListener } from '@mui/base';
import { Helmet } from "react-helmet";
import Nav from "../../Nav"
import OffersInfluenceOverview from "./OffersInfluenceOverview"
import Trafic from "./Trafic"
import AudienceInformations from "./AudienceInformations"


const StatsWrapper = () => {

    const navigate = useNavigate();
    const { user, logOut } = useUserAuth();
    const { parameter } = useParams();
    const [showProfilPanel, setShowProfilPanel] = useState(false);
    const [activeContent, setActiveContent] = useState(parameter);
    const ref = useRef(null);

    const dimension = {
        width: window.innerWidth,
        height: window.innerHeight,
    };

    const handleClose = () => { // close modal profil
        setShowProfilPanel(false);
    };

    const handleLogout = async () => { // logout user
        try {
            await logOut();
            navigate("/");
            setTimeout(() => {
                alert("Vous avez été déconnecté")
                console.clear()
            }, 250);
        } catch (error) {
            alert("Une erreur est survenue lors de votre déconnexion");
        }
    };

    const openAccountModal = () => {
        setShowProfilPanel(true)
    }

    useEffect(() => {
        setActiveContent(parameter)
    }, [parameter]);

    return (
        <div ref={ref}>
            <Helmet>
                <title>Sharlockpro - Statistiques </title>
                <meta name="keywords" content="sharlockpro, offers, aperçu, offres, communications, influences, overview, Sharlockpro.fr" />
                <meta name="description" content="Aperçu global de l'influence de mes communications" />
            </Helmet>

            <Modal
        open={showProfilPanel}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        hideBackdrop={dimension.width > 768 ? true : false}
      >
        <>
          <ClickAwayListener onClickAway={handleClose}>
            <Box className={user.uid === "p5aTQsaMqPdnL7ynK7v9dJSdgH83" ? "myOffers-box-admin" : "myOffers-box"}>
              <AiFillCloseCircle
                className="myOffers-modal-close"
                onClick={() => {
                  handleClose();
                }}
              />
              <div className="myOffers-modalContainer">
                <div className="myOffers-modal-profilCircle">
                  <p className="myOffers-modal-profiltext">
                    {user.name !== undefined ? user.name[0] : null}
                  </p>
                </div>
                <h2 className="myOffers-modal-title">
                  {user.name !== undefined ? user.name : null}
                </h2>
              </div>
              <div
                className="myOffers-modal-param"
                onClick={() => {
                  navigate("/params");
                }}
              >
                <p className="myOffers-modal-param-text">Paramètre du compte</p>
              </div>

              {user.uid === "p5aTQsaMqPdnL7ynK7v9dJSdgH83" &&
                <>
                  <div
                    className="myOffers-modal-param"
                    onClick={() => {
                      navigate("/partners-management");
                    }}
                  >
                    <p className="myOffers-modal-param-text">Gestion des partenaires</p>
                  </div>

                  <div
                    className="myOffers-modal-param"
                    onClick={() => {
                      navigate("/promotions-management");
                    }}
                  >
                    <p className="myOffers-modal-param-text">Gérer les promotions</p>
                  </div>
                </>
              }

              <div
                onClick={() => {
                  handleLogout();
                }}
                className="myOffers-modal-logoutBtn"
              >
                <p className="myOffers-modal-logoutText">Déconnexion</p>
              </div>
            </Box>
          </ClickAwayListener>
        </>
      </Modal>

            <Nav user={user} onPress={openAccountModal} />

            <div className="offerDetails-left-panel" >
                <div
                    className={
                        activeContent === "overview"
                            ? "offerDetails-panel-actionFocus"
                            : activeContent === ""
                                ? "offerDetails-panel-actionFocus"
                                : "offerDetails-panel-action"
                    }
                    onClick={() => {
                        const newParamValue = "overview"
                        navigate(`/statistics/${newParamValue}`);
                    }}
                >
                    <p className="offerDetails-panel-navTitle">Apercu global de l’influence de mes communications</p>
                </div>

                <div
                    className={
                        activeContent === "trafic"
                            ? "offerDetails-panel-actionFocus"
                            : "offerDetails-panel-action"
                    }
                    onClick={() => {
                        const newParamValue = "trafic"
                        navigate(`/statistics/${newParamValue}`);
                    }}
                >
                    <p className="offerDetails-panel-navTitle">Apercu du trafic</p>
                </div>

                <div
                    className={
                        activeContent === "audience"
                            ? "offerDetails-panel-actionFocus"
                            : "offerDetails-panel-action"
                    }
                    onClick={() => {
                        const newParamValue = "audience"
                        navigate(`/statistics/${newParamValue}`);
                    }}
                >
                    <p className="offerDetails-panel-navTitle">Information sur mon audience</p>
                </div>

            </div>

            <div className="offerDetails-right-panel-content" >
                <div className="preview-wrapper">
                    {activeContent === "overview" ?
                        <OffersInfluenceOverview user={user} />
                        : activeContent === "trafic" ?
                            (<Trafic user={user} />)
                            : activeContent === "audience" ?
                                <AudienceInformations user={user} />
                                : null
                    }
                </div>
            </div>
        </div>
    );
}

export default StatsWrapper;
