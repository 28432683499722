import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCiqQ-FjP4oHyLQSFiNsoPc51L3LN31Zzw",
  authDomain: "sharlock-36504.firebaseapp.com",
  databaseURL:
    "https://sharlock-36504-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "sharlock-36504",
  storageBucket: "sharlock-36504.appspot.com",
  messagingSenderId: "737202072455",
  appId: "1:737202072455:web:8acd9bc19a89ebac534122",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const getFirebaseStorage = getStorage(app);

export const auth = getAuth(app);
export const database = db;
export const storage = getFirebaseStorage;

export default app;
