import React, { useEffect, useState } from "react";
import "./ForgotPassword.css";
import { BsChevronLeft, BsFillXCircleFill } from "react-icons/bs";
import { AiFillCheckCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../../context/UserAuthContext";
import { Helmet } from "react-helmet";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState(null);
  const [select, setSelect] = useState(false);
  const { resetPassword } = useUserAuth();
  const [seconds, setSeconds] = useState(0);
  const [isResendEmailUsable, setResendEmailUsable] = useState(true)

  const onSubmitForm = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      await resetPassword(email);
      setResendEmailUsable(false)
      alert("Un lien de réinitialisation vient d'être envoyé sur " + email);
      setEmail("")

    } catch (err) {
      if (err.message === "Firebase: Error (auth/user-not-found).") {
        setError("Impossible de trouver votre compte Sharlock. Vérifiez l'adresse email");
      }
    }
  };

  useEffect(() => {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (regex.test(email.toLowerCase()) === true) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    if (email.length > 0) {
      setError(null);
    }
  }, [email]);

  useEffect(() => { // Timer between two press of resend sms code btn
    if (!isResendEmailUsable) {
      const interval = setInterval(() => {
        setSeconds(seconds => seconds + 1);
      }, 1000);
      setTimeout(() => {
        clearInterval(interval);
        setSeconds(0)
        setResendEmailUsable(true)
      }, 21000);  // clear timer after 20s
      return () => clearInterval(interval);
    }

  }, [isResendEmailUsable]);

  return (
    <div className="fgt-pass">

      <Helmet>
        <title>Vous avez oublié votre mot de passe Sharlockpro</title>
        <meta name="keywords" content="sharlockpro, motdepasse, password, forget password, mot de passe, mot de passe oubliée Sharlockpro.fr" />
        <meta name="description" content="Mot de passe oubliée. Nous vous aidons à le réinitialiser." />
      </Helmet>

      <div className="fgt-pass-desktop-banner">
        <div
          className="fgt-pass-desktop-banner-firstrow"
          onClick={() => {
            navigate("/");
          }}
        >
          <h1 className="fgt-pass-title">Sharlock</h1>
          <span className="fgt-pass-subTitle">pro</span>
        </div>
      </div>

      <h2 className="fgt-pass-h2">
        Réinitialiser le mot de passe de votre compte professionnel
      </h2>
      {error !== null ? <p className="fgt-pass-errorMsg">{error}</p> : null}

      <BsChevronLeft
        className="fgt-pass-navigation-back-icon"
        onClick={() => {
          navigate("/");
        }}
      />
      <div className="fgt-pass-banner">
        <p className="fgt-pass-navigation-text">Mot de passe oublié ?</p>
      </div>

      <form className="fgt-pass-form" onSubmit={onSubmitForm}>
        <div className="fgt-pass-details">
          <div className="fgt-pass-details-row">
            <div className="fgt-pass-circle-step">
              <p className="fgt-pass-circle-step-text">1</p>
            </div>
            <p className="fgt-pass-step-title">
              Rentrer votre adresse mail ci-dessous.
            </p>
          </div>
          <div className="fgt-pass-details-row">
            <div className="fgt-pass-circle-step">
              <p className="fgt-pass-circle-step-text">2</p>
            </div>
            <p className="fgt-pass-step-title">Vous recevrez un mail.</p>
          </div>
          <div className="fgt-pass-details-row">
            <div className="fgt-pass-circle-step">
              <p className="fgt-pass-circle-step-text">3</p>
            </div>
            <p className="fgt-pass-step-title">
              Cliquez sur le lien dans le mail et choisissez un nouveau mot de
              passe.
            </p>
          </div>
          <div className="fgt-pass-details-row">
            <div className="fgt-pass-circle-step">
              <p className="fgt-pass-circle-step-text">4</p>
            </div>
            <p className="fgt-pass-step-title">
              Après la validation de votre mot de passe vous pouvez vous
              connectez.
            </p>
          </div>
        </div>
        <div
          className={select ? "fgt-pass-form-row-focus" : "fgt-pass-form-row"}
        >
          <input
            type="email"
            name="email"
            placeholder="Email"
            onChange={(e) => {
              setEmail(e.currentTarget.value);
            }}
            className="fgt-pass-input"
            onClick={(e) => {
              setSelect(true);
            }}
            onBlur={(e) => {
              setSelect(false);
            }}
          />
          {disabled ? (
            <BsFillXCircleFill className="fgt-pass-form-cross" />
          ) : (
            <AiFillCheckCircle className="fgt-pass-form-check" />
          )}
        </div>

        {!isResendEmailUsable && error === null ?
          <div className="fgt-pass-submit-container">
            <input
              type="submit"
              value="Envoyer"
              className="fgt-pass-submit-disabled"
              disabled={true}
            />
            <div className="fgt-pass-resend-waiting-time">
              <p>{21 - seconds}</p>
            </div>
          </div>
          :
          disabled ?
            <input
              type="submit"
              value="Envoyer"
              className="fgt-pass-submit-disabled"
              disabled={true}
            />
            :
            <input
              type="submit"
              value="Envoyer"
              className="fgt-pass-submit"
              disabled={false}
            />
        }

      </form>
    </div>
  );
};

export default ForgotPassword;
