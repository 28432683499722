import "./Login.css";
import ImgRightPanel from "../../assets/img/login_right_panel.png";
import { MdOutlineWifiTethering } from "react-icons/md";
import { FaHandshake, FaLongArrowAltLeft } from "react-icons/fa";
import { BsShare } from "react-icons/bs";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dots } from "react-activity";
import "react-activity/dist/library.css";
import { Helmet } from "react-helmet";
import { useUserAuth } from "../../context/UserAuthContext";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState(null);
  const [isLoggin, setIsLoggin] = useState(false)
  const { logIn } = useUserAuth();

  const onSubmitForm = async (e) => {
    e.preventDefault();
    setError(null);
    setIsLoggin(true)
    try {
      await logIn(email, password);
      navigate("/profil");
    } catch (err) {
      setIsLoggin(false)
      console.log("ERR", err)
      switch (err.code) {
        case "auth/wrong-password":
          setError("Le mot de passe est incorrect")
          break;

        case "auth/user-not-found":
          setError("L'email saisit n'appartient à aucun compte")
          break;

        case "auth/too-many-requests":
          setError("L'accès à ce compte a été temporairement désactivé en raison de nombreuses tentatives de connexion infructueuses. Vous pouvez le restaurer immédiatement en réinitialisant votre mot de passe ou vous pouvez réessayer plus tard")
          break;

        default: setError("Une erreur s'est produite")
          break;
      }

    }
  };

  useEffect(() => {
    if (email !== "" && password !== "") {
      setDisabled(false);
    } else {
      setDisabled(true);
      setError(null);
    }
  }, [email, password]);
  return (
    <>
      <Helmet>
        <title>Connexion Sharlockpro</title>
        <meta name="keywords" content="sharlockpro, connexion, login, Sharlockpro.fr" />
        <meta name="description" content="Se connecter sur sharlockpro" />
      </Helmet>

      <div className="wrapper">
        <div className="firstColumn">
          <div className="headerTitle">
            <h1 className="title">Sharlock</h1>
            <span className="subTitle">pro</span>
          </div>
          <div className="container">
            <form className="form" onSubmit={onSubmitForm}>
              <h2 className="formTitle">Identifiez vous</h2>

              <input
                type="email"
                name="email"
                placeholder="Email"
                className="input-form"
                onChange={(e) => {
                  setEmail(e.currentTarget.value);
                }}
                onKeyPress={event => {
                  if (event.key === 'Enter') {
                    onSubmitForm(event)
                  }
                }}
              />

              <input
                type="password"
                name="password"
                placeholder="Mot de passe"
                autoComplete="off"
                onChange={(e) => {
                  setPassword(e.currentTarget.value);
                }}
                className="input-form"
                onKeyPress={event => {
                  if (event.key === 'Enter') {
                    onSubmitForm(event)
                  }
                }}
              />
              <div className="fgt-psw-container">
                <button
                  className="fgt-psw"
                  onClick={() => {
                    navigate("forgot-password");
                  }}
                >
                  <span>Mot de passe oublié ?</span>
                </button>
              </div>

              {error !== null ? <p className="errorMsg">{error}</p> : null}

              {!disabled && !isLoggin ?
                <input
                  className="input-form button-form"
                  type="submit"
                  value="Connectez-vous"
                />
                : disabled && !isLoggin ?
                  <input
                    className="input-form button-form-disabled"
                    type="submit"
                    value="Connectez-vous"
                    disabled={true}
                  />
                  :
                  <button className="input-form button-form" disabled> <Dots color="white" size={30} /> </button>
              }


              <a href="https://sharlock.fr" className="toKnowMore">Vous avez un site e-commerce ?{" "}
                <span className="toKnowMoreSpan">En savoir plus</span>
              </a>

            </form>
          </div>
        </div>
        <div className="secondColumn">
          <h2 className="secondTitle">
            Publiez vos communications en toute simplicité !
          </h2>
          <img
            src={ImgRightPanel}
            className="loginRightPanelImg"
            alt="Sharlock publications d'offres"
            loading="eager"
          />
          <div className="why-sharlock">
            <div className="flex-row">
              <FaHandshake color="white" className="icon" />
              <p className="icon-title">
                Simplifiez la vie de vos clients !
              </p>
            </div>
            <div className="flex-row">
              <div>
                <FaLongArrowAltLeft color="#AC97FF" className="icon" />
                <p className="back-icon-text">back</p>
              </div>
              <p className="icon-title">
                Faîtes revenir vos clients
              </p>
            </div>
            <div className="flex-row">
              <BsShare color="#AD00FF" className="icon" />
              <p className="icon-title">
                Rejoignez un réseau d’acheteurs en dehors des marketplaces
              </p>
            </div>
            <div className="flex-row">
              <MdOutlineWifiTethering color="#00CFFD" className="icon" />
              <p className="icon-title">
                Révolutionnez la façon dont vous communiquez
              </p>
            </div>
            {/* <button className="know-more-btn" onClick={() => { window.location.replace("https://sharlock.fr/ecommercant") }}>En savoir plus</button> */}
            <a href="https://sharlock.fr/ecommercant" className="know-more-btn">En savoir plus</a>

          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
