import "./MyPromotions.css";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiFillCloseCircle } from "react-icons/ai";
import { Icon } from '@iconify/react';
import { useUserAuth } from "../../context/UserAuthContext";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { ClickAwayListener } from '@mui/base';
import { Helmet } from "react-helmet";
import { database } from "../../firebase";
import { doc, updateDoc } from "firebase/firestore";
import { Dots } from "react-activity";
import "react-activity/dist/library.css";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { v4 } from "uuid";
import Nav from "../Nav"

const MyPromotions = () => {
    const navigate = useNavigate();
    const { user, logOut, updateUser } = useUserAuth();
    const [showProfilPanel, setShowProfilPanel] = useState(false);
    const [process, setProcess] = useState(false)
    const [promotionCode, setPromotionCode] = useState('');
    const [description, setDescription] = useState("");
    const [percent, setPercent] = useState("");
    const [expirationDate, setExpirationDate] = useState("");
    const dimension = {
        width: window.innerWidth,
        height: window.innerHeight,
    };

    const handleClose = () => { // close modal profil
        setShowProfilPanel(false);
    };

    const handleLogout = async () => { // logout user
        try {
            await logOut();
            navigate("/");
            setTimeout(() => {
                console.clear()
            }, 250);
        } catch (error) {
            alert("Une erreur est survenue lors de votre déconnexion");
        }
    };

    const openAccountModal = () => {
        setShowProfilPanel(true)
    }

    const handleDateChange = (date) => {
        setExpirationDate(date);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setProcess(true)
        const uuid = v4();

        const promotionData = {
            creationDate: new Date(),
            description: description,
            expirationDate: expirationDate,
            id: uuid,
            percent: percent,
            promoCode: promotionCode,
        };

        let newUser = { ...user }
        newUser.promotion = promotionData

        try {
            const partnerDocRef = doc(database, 'partners', user.uid);
            await updateDoc(partnerDocRef, { promotion: promotionData });
            //const response = await axios.get("https://europe-west1-sharlock-36504.cloudfunctions.net/sendNewPromotionNotification")
            updateUser(newUser)
            setProcess(false)
        } catch (error) {
            alert("Une erreur est survenue lors de l'enregistrement de votre code promo");
        }
    }

    return (
        <>
            <Helmet>
                <title>Sharlockpro - Mes promotions</title>
                <meta name="keywords" content="sharlockpro, Mes promotions, code promotion, Sharlockpro.fr" />
                <meta name="description" content="Définissez la promotion de votre compte partenaire." />
            </Helmet>

            <Modal
                open={showProfilPanel}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                hideBackdrop={dimension.width > 768 ? true : false}
            >
                <>
                    <ClickAwayListener onClickAway={handleClose}>
                        <Box className={user.uid === "p5aTQsaMqPdnL7ynK7v9dJSdgH83" ? "myOffers-box-admin" : "myOffers-box"}>
                            <AiFillCloseCircle
                                className="myOffers-modal-close"
                                onClick={() => {
                                    handleClose();
                                }}
                            />
                            <div className="myOffers-modalContainer">
                                <div className="myOffers-modal-profilCircle">
                                    <p className="myOffers-modal-profiltext">
                                        {user.name !== undefined ? user.name[0] : null}
                                    </p>
                                </div>
                                <h2 className="myOffers-modal-title">
                                    {user.name !== undefined ? user.name : null}
                                </h2>
                            </div>

                            <div
                                className="myOffers-modal-param"
                                onClick={() => {
                                    navigate("/params");
                                }}
                            >
                                <p className="myOffers-modal-param-text">Paramètre du compte</p>
                            </div>

                            {user.uid === "p5aTQsaMqPdnL7ynK7v9dJSdgH83" &&
                                <>
                                    <div
                                        className="myOffers-modal-param"
                                        onClick={() => {
                                            navigate("/partners-management");
                                        }}
                                    >
                                        <p className="myOffers-modal-param-text">Gestion des partenaires</p>
                                    </div>

                                    <div
                                        className="myOffers-modal-param"
                                        onClick={() => {
                                            navigate("/promotions-management");
                                        }}
                                    >
                                        <p className="myOffers-modal-param-text">Gérer les promotions</p>
                                    </div>
                                </>
                            }

                            <div
                                onClick={() => {
                                    handleLogout();
                                }}
                                className="myOffers-modal-logoutBtn"
                            >
                                <p className="myOffers-modal-logoutText">Déconnexion</p>
                            </div>
                        </Box>
                    </ClickAwayListener>
                </>
            </Modal>

            <Nav user={user} onPress={openAccountModal} />

            <div className="vote-header">
                <h1 className="vote-header-title">Mes promos</h1>
                <p className="vote-header-subtitle">
                    Je créé un code promo
                </p>
            </div>

            <div className="vote-rules">
                {user.promotion.promoCode === "" ?
                    <div className="promotion-card">
                        <form className="reward-form" onSubmit={handleSubmit}>
                            <h3>Nouveau code promo</h3>
                            <div className="reward-form-container">
                                <div className="reward-form-left-col">
                                    <div className="form-group">
                                        <label className="form-label">Réduction en %</label>
                                        <input className="form-input" placeholder="Exemple: 20%" type="number" step=".01" value={percent} onChange={(e) => setPercent(e.target.value)} maxLength={30} />
                                    </div>

                                    <div className="form-group">
                                        <label className="form-label">Description</label>
                                        <textarea className="form-textarea" placeholder="Ce qui concerne la promotion (code promo applicable sur une catégorie de produit/tout le site etc …)" value={description} onChange={(e) => setDescription(e.target.value)} maxLength={70} />
                                        <p className="modify-form-length">{description.length}/70</p>
                                    </div>

                                    <div className="form-group">
                                        <label className="form-label">Code promo</label>
                                        <input className="form-input" placeholder="Sharlock2023" type="text" value={promotionCode} onChange={(e) => setPromotionCode(e.target.value)} />
                                    </div>

                                    <div className="form-group">
                                        <label className="form-label">Date de fin</label>
                                        <DatePicker
                                            selected={expirationDate}
                                            onChange={handleDateChange}
                                            dateFormat="dd/MM/yyyy"
                                            minDate={new Date()} // Only allow selecting dates from today onwards
                                            placeholderText={new Date().toLocaleDateString()}
                                            className="form-input"
                                        />
                                    </div>
                                </div>

                            </div>

                            {!process && percent !== "" && description !== "" && expirationDate !== "" && promotionCode !== "" ?
                                <button className="submit-button" type="submit">Enregistrer la récompense</button>
                                : process ?

                                    <div className="submit-dots">
                                        <Dots color="white" size={30} />
                                    </div>

                                    :
                                    <button disabled={true} className="submit-button-disable">Enregistrer mon code promo</button>
                            }

                        </form>
                    </div>

                    :

                    <div className="promotion-card">
                        <div className="promotion-card-header">
                            <h3>Mon code promo</h3>
                            <p className="promotion-card-expirationDate">Jusqu'au {new Date(user.promotion.expirationDate.seconds * 1000).toLocaleDateString()}</p>
                            <Icon icon="icon-park-solid:check-one" color="#27E88B" className="promotion-card-check-icon" />
                        </div>
                        <div className="promotion-card-content">
                            <p className="promotion-card-content-header">{user.promotion.promoCode}</p>
                            <p className="promotion-card-content-title">-{user.promotion.percent}%</p>
                            <p className="promotion-card-content-description">{user.promotion.description}</p>
                        </div>
                    </div>
                }
            </div>
        </>
    );
}

export default MyPromotions;
