import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import "./Register.css";
import axios from 'axios'


const Register = () => {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleRegister = async (e) => {

    if (!password || !email) {
      setError("Veuillez remplir tout les champs");
      return;
    }
    e.preventDefault();

    const requestData = {
      email: email,
      password: password
    };

    try {
      const response = await axios.post("https://europe-west1-sharlock-36504.cloudfunctions.net/createPartnerAccount", requestData, {
      });
      navigate("/new-partner", {
        state: {
          user: response.data,
        }
      });

    } catch (error) {
      console.error('Error making request:', error);
      setError(error.message)
    }
  };

  return (
    <div>
      <div className="headerTitle" style={{ paddingTop: 40 }}>
        <h1 className="title">Création de compte:</h1>
      </div>

      <div className="container">

        {error !== null ? <p className="errorMsg">{error}</p> : null}

        <form onSubmit={handleRegister}>

          <input
            className="input-form"
            type="email"
            value={email}
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
          />

          <input
            className="input-form"
            type="password"
            value={password}
            placeholder="Mot de passe"
            minLength={6}
            onChange={(e) => setPassword(e.target.value)}
          />


          <button className={!password || !email ? "input-form button-form-disabled" : "input-form button-form"} type="submit">S'enregistrer</button>
        </form>

        <div>
          <Link to="/">Vous avez déjà un compte ? Connectez-vous !</Link>
        </div>
      </div>

    </div>
  );
}
export default Register;
