/*eslint-disable */
import './Admin.css'
import React, { useEffect, useState } from 'react';
import Nav from "../../Nav"
import { database } from "../../../firebase";
import { arrayUnion, arrayRemove, updateDoc, collection, doc, getDoc } from "firebase/firestore";
import { useNavigate, useLocation } from "react-router-dom";
import { AiFillCloseCircle } from "react-icons/ai";
import { useUserAuth } from "../../../context/UserAuthContext";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { ClickAwayListener } from '@mui/base';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const PromotionCategories = () => {

    const dimension = {
        width: window.innerWidth,
        height: window.innerHeight,
    };

    const { user, logOut } = useUserAuth();
    const navigate = useNavigate();
    const location = useLocation()

    const partners = location.state.partners;
    const [showProfilPanel, setShowProfilPanel] = useState(false);
    const [categories, setCategories] = useState([""]);
    const [existingCategories, setExistingCategories] = useState([])
    const [categoriesOrder, setCategoriesOrder] = useState([]);

    const handleClose = () => { // close modal profil
        setShowProfilPanel(false);
    };

    const handleLogout = async () => { // logout user
        try {
            await logOut();
            navigate("/");
            setTimeout(() => {
                console.clear()
            }, 250);
        } catch (error) {
            alert("Une erreur est survenue lors de votre déconnexion");
        }
    };

    const openAccountModal = () => {
        setShowProfilPanel(true)
    }

    const handleAddCategory = () => {
        setCategories([...categories, ""]);

    };

    const handleRemoveCategory = (index) => {
        const updatedCategories = [...categories];
        updatedCategories.splice(index, 1);
        setCategories(updatedCategories);
    };

    const handleCategoryChange = (index, value) => {
        const updatedCategories = [...categories];
        updatedCategories[index] = value;
        setCategories(updatedCategories);
    };

    const deleteCategory = async (category) => {

        // Tableau contenant les ID à supprimer de nos partenaires
        const partnersIDToRemoveFromCategory = []
        partners.forEach(element => {
            if (element.data.promotion.categories.includes(category)) {
                partnersIDToRemoveFromCategory.push(element.data.uid)
            }
        });

        try {

            if (partnersIDToRemoveFromCategory.length > 0) {

                partnersIDToRemoveFromCategory.forEach(element => {
                    const partnerCategoriesRef = doc(database, 'partners', element);
                    updateDoc(partnerCategoriesRef, {
                        'promotion.categories': arrayRemove(category),
                    });
                    console.log("La catégorie a été supprimé du partenaire")
                });

            }

            const categoriesDocRef = doc(database, 'promotions', 'categories');

            // Mettez à jour le document avec le tableau mis à jour
            await updateDoc(categoriesDocRef, {
                'list': arrayRemove(category),
            });

            await updateDoc(categoriesDocRef, {
                'sort': arrayRemove(category),
            });

            let existingCategoriesCopy = [...existingCategories]
        
            const indexToRemove = existingCategoriesCopy.indexOf(category)

            if (indexToRemove !== -1) {
                existingCategoriesCopy.splice(indexToRemove, 1)
                setExistingCategories(existingCategoriesCopy)
                setCategoriesOrder(existingCategoriesCopy)
            }

            console.log('Tableau de catégories mis à jour avec succès !');

        } catch (error) {
            // Gérez les erreurs ici
            console.error("Erreur lors de la suppresion d'une des catégories :", error);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const categoriesDocRef = doc(database, 'promotions', 'categories');

            // Mettez à jour le document avec le tableau mis à jour
            await updateDoc(categoriesDocRef, {
                'list': arrayUnion(...categories),
            });

            //Duplicate variable 
            let existingCategoriesCopy = [...existingCategories]
            let categoriesOrderCopy = [...categoriesOrder]

            categories.forEach(element => {
                const index = existingCategories.indexOf(element)
                if (index === -1) {
                    existingCategoriesCopy.push(element)
                }
            });

            categories.forEach(element => {
                const index = categoriesOrder.indexOf(element)
                if (index === -1) {
                    categoriesOrderCopy.push(element)
                }
            });


            await updateDoc(categoriesDocRef, {
                'sort': arrayUnion(...categoriesOrderCopy),
            });

            setExistingCategories(existingCategoriesCopy)
            setCategoriesOrder(categoriesOrderCopy)


            console.log('Tableau de catégories mis à jour avec succès !');

        } catch (error) {
            // Gérez les erreurs ici
            console.error('Erreur lors de la mise à jour des catégories :', error);
        }
    };

    const fetchData = async () => {

        try {
            const promotionsCollectionRef = collection(database, 'promotions');
            const categoriesDocRef = doc(promotionsCollectionRef, 'categories');

            const docSnapshot = await getDoc(categoriesDocRef);
            if (docSnapshot.exists()) {
                // Le document "categories" existe
                const categoriesData = docSnapshot.data().list;
                const catOrder = docSnapshot.data().sort;
                setExistingCategories(categoriesData);
                setCategoriesOrder(catOrder)
            } else {
                // Le document "categories" n'existe pas
                console.error('Le document "categories" n\'existe pas.');
            }
        } catch (error) {
            // Gérez les erreurs ici
            console.error('Erreur lors de la récupération des catégories :', error);
        }
    };

    // Function to handle drag-and-drop reordering
    const handleDragEnd = (result) => {
        if (!result.destination) return; // Dropped outside the list
        const updatedCategoriesOrder = [...categoriesOrder];
        const [reorderedItem] = updatedCategoriesOrder.splice(result.source.index, 1);
        updatedCategoriesOrder.splice(result.destination.index, 0, reorderedItem);
        setCategoriesOrder(updatedCategoriesOrder);
    };

    // Function to save the updated order to Firestore
    const saveOrderToFirestore = async () => {

        try {
            const categoriesDocRef = doc(database, 'promotions', 'categories');

            // Mettez à jour le document avec le tableau mis à jour
            await updateDoc(categoriesDocRef, {
                'sort': categoriesOrder,
            });

            alert("L'ordre des catégories à été mis à jour !");

        } catch (error) {
            // Gérez les erreurs ici
            console.error('Erreur lors de la mise à jour des catégories :', error);
        }
    };


    useEffect(() => {
        try {
            fetchData();
        } catch (error) {
            console.log(error)
        }

    }, []);

    return (

        <>
            <Modal
                open={showProfilPanel}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                hideBackdrop={dimension.width > 768 ? true : false}
            >
                <>
                    <ClickAwayListener onClickAway={handleClose}>
                        <Box className={user.uid === "p5aTQsaMqPdnL7ynK7v9dJSdgH83" ? "myOffers-box-admin" : "myOffers-box"}>
                            <AiFillCloseCircle
                                className="myOffers-modal-close"
                                onClick={() => {
                                    handleClose();
                                }}
                            />
                            <div className="myOffers-modalContainer">
                                <div className="myOffers-modal-profilCircle">
                                    <p className="myOffers-modal-profiltext">
                                        {user.name !== undefined ? user.name[0] : null}
                                    </p>
                                </div>
                                <h2 className="myOffers-modal-title">
                                    {user.name !== undefined ? user.name : null}
                                </h2>
                            </div>
                            <div
                                className="myOffers-modal-param"
                                onClick={() => {
                                    navigate("/params");
                                }}
                            >
                                <p className="myOffers-modal-param-text">Paramètre du compte</p>
                            </div>

                            {user.uid === "p5aTQsaMqPdnL7ynK7v9dJSdgH83" &&
                                <>
                                    <div
                                        className="myOffers-modal-param"
                                        onClick={() => {
                                            navigate("/partners-management");
                                        }}
                                    >
                                        <p className="myOffers-modal-param-text">Gestion des partenaires</p>
                                    </div>

                                    <div
                                        className="myOffers-modal-param"
                                        onClick={() => {
                                            navigate("/promotions-management");
                                        }}
                                    >
                                        <p className="myOffers-modal-param-text">Gérer les promotions</p>
                                    </div>
                                </>
                            }

                            <div
                                onClick={() => {
                                    handleLogout();
                                }}
                                className="myOffers-modal-logoutBtn"
                            >
                                <p className="myOffers-modal-logoutText">Déconnexion</p>
                            </div>
                        </Box>
                    </ClickAwayListener>
                </>
            </Modal>

            <Nav user={user} onPress={openAccountModal} />

            <div className="selection-of-the-week-header">
                <h1 className="selection-of-the-week-title">Gestion des catégories de promotions</h1>
            </div>

            <div className="promotions-wrapper">
                <div className="promotion">
                    <h2>Création de catégories</h2>

                    <form onSubmit={handleSubmit}>

                        {categories.map((category, index) => (
                            <div key={index} className="promotion-category-input">
                                <input
                                    type="text"
                                    placeholder="Nouvelle catégorie"
                                    value={category}
                                    onChange={(e) => handleCategoryChange(index, e.target.value)}
                                    className="promotion-category-text-input"
                                />

                                <button
                                    type="button"
                                    onClick={() => handleRemoveCategory(index)}
                                    className="promotion-remove-category-button"
                                >
                                    Supprimer
                                </button>
                            </div>
                        ))}

                        <button type="button" onClick={handleAddCategory} className="promotion-add-category-button">
                            Ajouter une catégorie
                        </button>

                        <button type="submit" className="promotion-submit-button">
                            Enregistrer
                        </button>

                    </form>
                </div>

                <div className="promotion">
                    <h2>{existingCategories.length > 0 ? "Catégories existantes" : "Aucunes catégories n'existent"}</h2>
                    {existingCategories.length > 0 &&
                        <div className="promotion-existing-category">

                            {existingCategories.map((category, index) => (
                                <div key={index} style={{ marginTop: 5 }}>
                                    <p>{category}</p>
                                    <button onClick={() => deleteCategory(category)}>Supprimer catégorie</button>
                                </div>
                            ))}

                        </div>
                    }
                    <button className="promotion-redirect-assignPartners" onClick={() => navigate("/promotions-management/")}>Affecter des catégories à des marques</button>
                </div>
            </div>

            <div className="promotions-order">

                <h2>Ordre de catégories</h2>

                <div className="promotions-order-dragdrop">
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="categories">
                            {(provided) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    className="promotions-order category-container" // Apply the class prefixes
                                >
                                    {categoriesOrder.map((category, index) => (
                                        <Draggable key={category} draggableId={category} index={index}>
                                            {(provided) => (
                                                <div
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    ref={provided.innerRef}
                                                    className="promotions-order category-box" // Apply the class prefixes
                                                >
                                                    {category}
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>

                    </DragDropContext>
                </div>
                <button className="promotions-order-dragdrop-update-btn" onClick={saveOrderToFirestore}>Mettre à jour</button>

            </div>
        </>
    );
}

export default PromotionCategories;
