/*eslint-disable */
import "./Params.css";
import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BsChevronDown } from "react-icons/bs";
import { AiFillCloseCircle } from "react-icons/ai";
import { HiOutlinePlus } from "react-icons/hi";
import { useUserAuth } from "../../context/UserAuthContext";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { storage, database, auth } from "../../firebase";
import { doc, updateDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 } from "uuid";
import { Dots } from "react-activity";
import "react-activity/dist/library.css";
import { ClickAwayListener } from '@mui/base';
import { Helmet } from "react-helmet";
import Nav from "../Nav"

const Params = () => {
  const {
    user,
    logOut,
    updateUserEmail,
    updateCurrentUserEmail,
    reAuth,
    changePassword,
  } = useUserAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [slogan, setSlogan] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState(null);
  const [file2, setFile2] = useState(null);
  const inputFileRef1 = useRef(null);
  const inputFileRef2 = useRef(null);
  const [isModifEmailVisible, setModifEmailVisible] = useState(false);
  const [isModifPasswordVisible, setModifPasswordVisible] = useState(false);
  const [
    isModifPartnerInformationVisible,
    setModifPartnerInformationVisible,
  ] = useState(false);
  const [showProfilPanel, setShowProfilPanel] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false)
  const dimension = {
    width: window.innerWidth,
    height: window.innerHeight,
  };
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const handleClick = (ref) => { // create a useRef of the file click
    ref.current.click();
  };

  const handleChange = (e) => { // Verify size of the image & mutate file state
    if (e.target.files[0].size >= 5000000) {
      alert("Le fichier est trop volumineux");
    } else {
      if (e.currentTarget.name === "logo") {
        let file = e.target.files[0];
        file.url = URL.createObjectURL(e.target.files[0]);
        setFile(file);
      }
      if (e.currentTarget.name === "profil") {
        let file = e.target.files[0];
        file.url = URL.createObjectURL(e.target.files[0]);
        setFile2(file);
      }
    }
  };

  const handleClose = () => {// close profil modal
    setShowProfilPanel(false);
  }

  const handleLogout = async () => { // logout user
    try {
      await logOut();
      navigate("/");
      setTimeout(() => {
        console.clear()
      }, 250);
    } catch (error) {
      alert("Une erreur est survenue lors de votre déconnexion");
    }
  };

  const updatePartnerEmail = async () => {
    try {
      await reAuth(password);
      await updateUserEmail(email);
      await updateCurrentUserEmail(email);
      setModifEmailVisible(false);
      setConfirmEmail("")
      setEmail("")
      alert("Votre email a été modifié")
    } catch (err) {
      switch (err.code) {
        case "auth/user-mismatch":
          alert("L'identifiant donné ne correspond pas à l'utilisateur. Prenez contact avec un administrateur.")
          break;

        case "auth/user-not-found":
          alert("Utilisateur non trouvé")
          break;

        case "auth/invalid-credential":
          alert("Les informations d'identification du fournisseur ne sont pas valides. Prenez contact avec un administrateur.")
          break;

        case "auth/invalid-email":
          alert("L'adresse email utilisé n'est pas valide")
          break;

        case "auth/wrong-password":
          alert("Le mot de passe utilisé est incorrect")
          break;

        case "auth/email-already-in-use":
          alert("Cette email est déjà utilisé par un autre utilisateur")
          break;
      }
    }
  };

  const updatePartnerPassword = async () => {
    try {
      await reAuth(password);
      await changePassword(newPassword);
      setModifPasswordVisible(false);
      alert("Votre mot de passe a été modifié")
    } catch (err) {
      if (err.code === "auth/wrong-password") {
        alert("Le mot de passe est incorrect")
      }
      else {
        setModifEmailVisible(false);
        console.log("Code error: ", err.code, "Code message: ", err.message)
        alert("La modification de votre mot de passe a échouée.")
      }

    }
  };

  const updatePartnerInformation = async () => {  // Update the profil of the user
    const userData = auth.currentUser;
    const itemRef = doc(database, "partners", userData.uid);
    let urlLogo = user.logo;
    let urlPicture = user.picture;
    if (file !== null || file2 !== null) {
      if (file !== null && file2 !== null) {
        try {
          updateDoc(itemRef, {
            description: description !== "" ? description : user.description,
            slogan: slogan !== "" ? slogan : user.slogan,
            picture: urlPicture,
            logo: urlLogo,
          });
          setModifPartnerInformationVisible(false);
          alert("Profil mis à jour");
        } catch (error) {
          console.log("Code error: ", err.code, "Code message: ", err.message)
        }
      } else {
        if (file !== null) {
          const uuid = v4();
          const imageRef = ref(
            storage,
            `${user.uid}/images/${file.name + uuid}`
          );
          uploadBytes(imageRef, file)
            .then(() => {
              getDownloadURL(
                ref(storage, `${user.uid}/images/${file.name + uuid}`)
              )
                .then((url) => {
                  try {
                    updateDoc(itemRef, {
                      description:
                        description !== "" ? description : user.description,
                      slogan: slogan !== "" ? slogan : user.slogan,
                      logo: url,
                    });
                    setModifPartnerInformationVisible(false);
                    alert("Profil mis à jour");
                  } catch (error) { }
                })
                .catch((error) => { });
            })
            .catch((error) => { });
        }
        if (file2 !== null) {
          const uuid2 = v4();
          const imageRef2 = ref(
            storage,
            `${user.uid}/images/${file2.name + uuid2}`
          );
          uploadBytes(imageRef2, file2)
            .then(() => {
              getDownloadURL(
                ref(storage, `${user.uid}/images/${file2.name + uuid2}`)
              )
                .then((url2) => {
                  return url2;
                })
                .catch((error) => { });
            })
            .catch((error) => { });
        }
      }
    }
  }

  const openAccountModal = () => {
    setShowProfilPanel(true)
  }

  useEffect(() => {
    let isSubscribed = true;
    if (auth.currentUser !== null) {  // Verify that AuthContext return user data
      setLoading(true)
    }
    return () => (isSubscribed = false)
  }, [auth.currentUser]);

  return (
    <>
      <Helmet>
        <title>Sharlockpro - Paramètre du compte</title>
        <meta name="keywords" content="sharlockpro, paramètre, params, mon compte, account, Sharlockpro.fr" />
        <meta name="description" content="Paramètre de votre compte sharlockpro. Vous pouvez y modifier votre email ou votre mot de passe." />
      </Helmet>

      <Modal
        open={showProfilPanel}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        hideBackdrop={dimension.width > 768 ? true : false}
      >
        <>
          <ClickAwayListener onClickAway={handleClose}>
            <Box className={user.uid === "p5aTQsaMqPdnL7ynK7v9dJSdgH83" ? "myOffers-box-admin" : "myOffers-box"}>
              <AiFillCloseCircle
                className="myOffers-modal-close"
                onClick={() => {
                  handleClose();
                }}
              />
              <div className="myOffers-modalContainer">
                <div className="myOffers-modal-profilCircle">
                  <p className="myOffers-modal-profiltext">
                    {user.name !== undefined ? user.name[0] : null}
                  </p>
                </div>
                <h2 className="myOffers-modal-title">
                  {user.name !== undefined ? user.name : null}
                </h2>
              </div>
              <div
                className="myOffers-modal-param"
                onClick={() => {
                  navigate("/params");
                }}
              >
                <p className="myOffers-modal-param-text">Paramètre du compte</p>
              </div>

              {user.uid === "p5aTQsaMqPdnL7ynK7v9dJSdgH83" &&
                <>
                  <div
                    className="myOffers-modal-param"
                    onClick={() => {
                      navigate("/partners-management");
                    }}
                  >
                    <p className="myOffers-modal-param-text">Gestion des partenaires</p>
                  </div>

                  <div
                    className="myOffers-modal-param"
                    onClick={() => {
                      navigate("/promotions-management");
                    }}
                  >
                    <p className="myOffers-modal-param-text">Gérer les promotions</p>
                  </div>
                </>
              }

              <div
                onClick={() => {
                  handleLogout();
                }}
                className="myOffers-modal-logoutBtn"
              >
                <p className="myOffers-modal-logoutText">Déconnexion</p>
              </div>
            </Box>
          </ClickAwayListener>
        </>
      </Modal>

      <Nav user={user} onPress={openAccountModal} />


      {isModifEmailVisible ? (
        <div className="params-modify-container" style={{ top: (window.innerHeight - 580) / 2 + "px" }}>
          <div className="params-modify-email-card">
            <h2 className="params-modify-title">
              Modification de l'adresse email
            </h2>

            <label className="params-modify-label">Mot de passe actuel</label>
            <input
              name="password"
              className="params-modify-input"
              type="password"
              onChange={(e) => {
                setPassword(e.currentTarget.value);
              }}
            />

            <label className="params-modify-label">
              Nouvelle adresse email
            </label>
            <div
              className={
                email !== "" && regex.test(email.toLowerCase()) === false || email === user.email
                  ? "params-modify-input-parent-error"
                  : "params-modify-input-parent-confirm"
              }
            >
              <input
                name="email"
                className="params-modify-input-confirm"
                type="email"
                onChange={(e) => {
                  setEmail(e.currentTarget.value);
                }}
              />
              {email !== "" && regex.test(email.toLowerCase()) === false ?
                <AiFillCloseCircle className="params-modify-confirm-error" />
                : email === user.email ?
                  <p className="params-input-errorMsg">Vous utilisez déjà ce mail</p>
                  : null}
            </div>


            <label className="params-modify-label">
              Confirmer la nouvelle adresse email
            </label>
            <div
              className={
                email !== "" &&
                  email !== confirmEmail &&
                  email !== user.email &&
                  confirmEmail !== ""
                  ? "params-modify-input-parent-error"
                  : "params-modify-input-parent-confirm"
              }
            >
              <input
                name="confirmEmail"
                className="params-modify-input-confirm"
                type="email"
                onChange={(e) => {
                  setConfirmEmail(e.currentTarget.value);
                }}
              />
              {email !== "" &&
                confirmEmail !== "" &&
                email !== confirmEmail &&
                email !== user.email ? (
                <AiFillCloseCircle className="params-modify-confirm-error" />
              ) : email !== "" &&
                confirmEmail !== "" &&
                email !== confirmEmail &&
                email === user.email ? (
                <></>
              ) : null}
            </div>

            <div className="params-modify-btnContainer">
              <button
                className="params-modify-cancel"
                onClick={() => {
                  setEmail("");
                  setConfirmEmail("");
                  setModifEmailVisible(false);
                }}
              >
                Annuler
              </button>

              {email !== "" &&
                confirmEmail !== "" && email === confirmEmail
                &&
                email !== user.email && regex.test(email.toLowerCase()) === true && password !== ""
                ?
                <button
                  onClick={() => {
                    updatePartnerEmail();
                  }}
                  className="params-modify-submit-active"
                >
                  Enregistrer
                </button>
                :
                <button
                  disabled
                  className="params-modify-submit"
                >
                  Enregistrer
                </button>
              }

            </div>
          </div>
        </div>
      ) : null}

      {isModifPasswordVisible ? (
        <div className="params-modify-container" style={{ top: (window.innerHeight - 580) / 2 + "px" }}>
          <div className="params-modify-email-card">
            <h2 className="params-modify-title">
              Modification du mot de passe
            </h2>
            <label className="params-modify-label">Mot de passe actuel</label>
            <input
              name="password"
              className="params-modify-input"
              type="password"
              onChange={(e) => {
                setPassword(e.currentTarget.value);
              }}
            />
            <label className="params-modify-label">Nouveau mot de passe</label>
            <input
              name="newpassword"
              className="params-modify-input"
              type="password"
              onChange={(e) => {
                setNewPassword(e.currentTarget.value);
              }}
            />
            <label className="params-modify-label">
              Confirmer le nouveau mot de passe
            </label>
            <div
              className={
                newPassword !== "" &&
                  confirmPassword !== "" &&
                  newPassword !== confirmPassword
                  ? "params-modify-input-parent-error"
                  : "params-modify-input-parent-confirm"
              }
            >
              <input
                name="confirmPassword"
                className="params-modify-input-confirm"
                type="password"
                onChange={(e) => {
                  setConfirmPassword(e.currentTarget.value);
                }}
              />
              {newPassword !== "" &&
                confirmPassword !== "" &&
                newPassword !== confirmPassword ? (
                <AiFillCloseCircle className="params-modify-confirm-error" />
              ) : null}
            </div>
            <div className="params-modify-btnContainer">
              <button
                className="params-modify-cancel"
                onClick={() => {
                  setPassword("");
                  setConfirmPassword("");
                  setModifPasswordVisible(false);
                }}
              >
                Annuler
              </button>
              <button
                onClick={() => {
                  updatePartnerPassword();
                }}
                className={
                  password !== "" &&
                    newPassword !== "" &&
                    confirmPassword !== "" &&
                    newPassword === confirmPassword
                    ? "params-modify-submit-active"
                    : "params-modify-submit"
                }
              >
                Enregistrer
              </button>
            </div>
          </div>
        </div>
      ) : null}

      {isModifPartnerInformationVisible ? (
        <div className="params-modify-container2" style={{ top: (window.innerHeight - 750) / 2 + "px" }}>
          <div className="params-modify-informations-card">
            <h2 className="params-modify-title2">
              Modification de mes informations sur sharlock
            </h2>
            <label className="params-modify-label">Logo</label>
            <div style={{ position: "relative", marginBottom: 25 }}>
              {file !== null ? (
                <img src={file.url} alt={file.name} className="params-modify-logo" />
              ) : user.logo !== "" && user.logo !== undefined ? (
                <img src={user.logo} alt={user.firstname + " " + user.lastname} className="profil-modify-logo" />
              ) : (
                <div
                  className="params-modify-nologo"
                  onClick={() => {
                    handleClick(inputFileRef1);
                  }}
                ></div>
              )}
              <input
                type="file"
                name="logo"
                style={{ display: "none" }}
                onChange={handleChange}
                ref={inputFileRef1}
                required
              />
              <div className="params-modify-logo-icon-btn">
                <HiOutlinePlus
                  className="params-modify-logo-icon"
                  onClick={() => {
                    handleClick(inputFileRef1);
                  }}
                />
              </div>
            </div>
            <label className="params-modify-label">Slogan</label>
            <input
              name="slogan"
              className="params-modify-information-input"
              type="text"
              onChange={(e) => {
                setSlogan(e.currentTarget.value);
              }}
              placeholder={slogan !== "" ? slogan : user.slogan}
            />
            <label className="params-modify-label">Photo de profil</label>
            <div style={{ position: "relative", marginBottom: 25 }}>
              {file2 !== null ? (
                <img src={file2.url} alt={file2.name} className="params-modify-profilPicture" />
              ) : user.picture !== "" && user.picture !== undefined ? (
                <img src={user.picture} alt={"Photo de profil" + user.firstname + " " + user.lastname} className="params-modify-profilPicture" />
              ) : (
                <div
                  className="params-modify-noprofilPicture"
                  onClick={() => {
                    handleClick(inputFileRef1);
                  }}
                ></div>
              )}
              <input
                type="file"
                name="profil"
                style={{ display: "none" }}
                onChange={handleChange}
                ref={inputFileRef2}
                required
              />
              <div className="params-modify-profilPicture-icon-btn">
                <HiOutlinePlus
                  className="params-modify-profilPicture-icon"
                  onClick={() => {
                    handleClick(inputFileRef2);
                  }}
                />
              </div>
            </div>
            <label className="params-modify-label">Description</label>
            <textarea
              name="description"
              className={
                description !== ""
                  ? "params-modify-information-textarea"
                  : "params-modify-information-textarea-placeholder"
              }
              onChange={(e) => {
                setDescription(e.currentTarget.value);
              }}
              value={description !== "" ? description : user.description}
            />

            <div className="params-modify-btnContainer2">
              <button
                className="params-modify-cancel"
                onClick={() => {
                  setFile(null);
                  setFile2(null);
                  setDescription("");
                  setSlogan("");
                  setIsUpdating(false);
                  setModifPartnerInformationVisible(false);
                }}
              >
                Annuler
              </button>
              {isUpdating ? (
                <div style={{
                  display: "flex", alignItems: "center", justifyContent: "center", width: 150, height: 42
                }}>
                  <Dots color="#AA80F9" size={25} />
                </div>
              ) : (
                file !== null ||
                  description !== "" ||
                  slogan !== "" ||
                  file2 !== null ?
                  (
                    <button
                      onClick={() => {
                        updatePartnerInformation();
                        setIsUpdating(true);
                      }}
                      className="params-modify-submit-active"
                    >
                      Enregistrer
                    </button>
                  ) : (
                    <button className="params-modify-submit-inactive">
                      Enregistrer
                    </button>
                  )
              )
              }

            </div>
          </div>
        </div>
      ) : null
      }

      {loading ?
        <div className="params-main">
          <h2 className="params-main-title">Paramètre du compte</h2>
          <p className="params-main-subtitle">Informations de connexion</p>
          <div style={{ minHeight: dimension.height * 0.75 }}>
            <div className="params-form-card">
              <label className="params-form-label">Adresse email</label>
              <div className="params-form-row">
                <input
                  name="email"
                  className="params-email-input"
                  type="email"
                  placeholder={user !== null ? user.email : ""}
                  readOnly="readonly"
                />
                <button
                  onClick={() => {
                    setModifEmailVisible(true);
                  }}
                  className="params-form-modify-btn"
                >
                  Modifier
                </button>
              </div>
              <label className="params-form-label">Mot de passe</label>
              <div className="params-form-row">
                <input
                  name="password"
                  className="params-form-input"
                  type="password"
                  placeholder="********"
                  readOnly="readonly"
                  style={{ border: "none" }}
                />
                <button
                  onClick={() => {
                    setModifPasswordVisible(true);
                  }}
                  className="params-form-modify-btn"
                >
                  Modifier
                </button>
              </div>
            </div>

          </div>
        </div>
        :
        <div style={{
          width: "100%", height: "100%", alignItems: "center", justifyContent: "center", background: "#F4F2FF"
        }}>
          <Dots color="#AA80F9" size={25} />
        </div>
      }
    </>
  );
};

export default Params;
