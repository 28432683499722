import React, { useEffect, useState } from "react";
import { PieChart, Pie, Legend, Tooltip, Cell } from 'recharts';
import { database } from "../../../firebase";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { Dots } from "react-activity";
import "react-activity/dist/library.css";

const AudienceInformations = (item) => {

    const [audienceNumber, setAudienceNumber] = useState()
    const [usersByAgeRange, setUsersByAgeRange] = useState([])
    const [usersByGender, setUsersByGender] = useState([])
    const [usersByCity, setUsersByCity] = useState([])
    const [userLocationTotalCount, setUserLocationTotalCount] = useState(0)
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const usersSnapshot = await getDocs(collection(database, "users"));

                let audienceUserNumber = 0;
                let genderCounts = [
                    { name: 'Homme', value: 0 },
                    { name: 'Femme', value: 0 },
                    { name: 'Non genré', value: 0 },
                ];
                let ageCountMap = {
                    "0-18 ans": 0,
                    "18-24 ans": 0,
                    "25-34 ans": 0,
                    "35-44 ans": 0,
                    "+44 ans": 0,
                    "Non renseigné": 0,
                };
                let usersLocations = []

                const userPromises = usersSnapshot.docs.map(async (userDoc) => {
                    const websiteDoc = await getDoc(doc(collection(database, "users", userDoc.id, "websites"), item.user.uid));
                    let ageRange = "";

                    if (websiteDoc.exists()) {
                        audienceUserNumber++;
                        const gender = userDoc.data().gender;
                        const birthday = userDoc.data().birthday;
                        const birthYear = Number(birthday.split("/")[2]);
                        const age = new Date().getFullYear() - birthYear;

                        if (gender === 'Homme') {
                            genderCounts[0].value++;
                        } else if (gender === 'Femme') {
                            genderCounts[1].value++;
                        } else {
                            genderCounts[2].value++;
                        }

                        if (age <= 18) {
                            ageRange = "0-18 ans";
                        } else if (age <= 24) {
                            ageRange = "18-24 ans";
                        } else if (age <= 34) {
                            ageRange = "25-34 ans";
                        } else if (age <= 44) {
                            ageRange = "35-44 ans";
                        } else if (age > 44) {
                            ageRange = "+44 ans";
                        } else {
                            ageRange = "Non renseigné";
                        }

                        ageCountMap[ageRange]++;
                    }
                    if (userDoc.data().location === undefined) {
                        console.log(userDoc.data())
                    }
                    if (userDoc.data().location !== undefined && userDoc.data().location !== null) {
                        const location = {
                            name: userDoc.data().location.city,
                            count: 1,
                            coordinates: [userDoc.data().location.coords.latitude, userDoc.data().location.coords.longitude]
                        };

                        if (usersLocations.length === 0) {
                            usersLocations.push(location)
                        }
                        else {
                            const existingLocation = usersLocations.find((loc) => loc.name === location.name);
                            if (existingLocation) {
                                existingLocation.count++;
                            } else {
                                usersLocations.push(location);
                            }
                        }

                    }


                });


                await Promise.all(userPromises);

                const userAgeData = [
                    { name: "0-18 ans", value: ageCountMap["0-18 ans"] },
                    { name: "18-24 ans", value: ageCountMap["18-24 ans"] },
                    { name: "25-34 ans", value: ageCountMap["25-34 ans"] },
                    { name: "35-44 ans", value: ageCountMap["35-44 ans"] },
                    { name: "+44 ans", value: ageCountMap["+44 ans"] },
                    { name: "Non renseigné", value: ageCountMap["Non renseigné"] },
                ];


                setAudienceNumber(audienceUserNumber);
                setUsersByGender(genderCounts);
                setUsersByAgeRange(userAgeData);

                usersLocations.sort((a, b) => b.count - a.count)
                setUsersByCity(usersLocations)

                let locationCount = 0
                usersLocations.forEach((userLoc) => {
                    locationCount = locationCount + userLoc.count
                })
                setUserLocationTotalCount(locationCount)

                setLoaded(true);

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };


        fetchData();
    }, []);


    const COLORS = ['#74A8F7', '#0C4AA8', '#250EC2', '#54F1FF', '#2DFF96', '#FFA1C8']; // Custom colors for each data segment

    const legendAgeItems = usersByAgeRange.map((entry, index) => {
        const color = COLORS[index % COLORS.length];
        return {
            value: entry.name,
            color: color,
        };
    });

    const legendGenderItems = usersByGender.map((entry, index) => {
        const color = COLORS[index % COLORS.length];
        return {
            value: entry.name,
            color: color,
        };
    });

    useEffect(() => {
        if (loaded) {
            // Initialize the map
            const map = L.map('map').setView([46.603354, 1.888334], 6); // Set initial center and zoom level

            // Add a tile layer to the map
            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: 'Map data © OpenStreetMap contributors'
            }).addTo(map);

            // Add user markers with radial gradient
            usersByCity.forEach((location) => {
                console.log(location)
                const marker = L.circleMarker(location.coordinates, {
                    radius: location.count/3, // Set the marker radius based on the count and reduced radius /3
                    fill: true,
                    fillColor: 'rgba(42, 122, 245,0.7)', // Radial gradient fill color
                    color: 'rgba(42, 122, 245,0.7)', // Border color
                    weight: 1, // Border weight
                    opacity: 1, // Border opacity
                    fillOpacity: 0.9 // Fill opacity
                }).addTo(map);
                marker.bindPopup(location.name); // Set the popup content
            });
        }
    }, [loaded]);

    return (
        <div className="stats-wrapper">
            <h1 className="preview-header-title">Information sur mon audience</h1>

            <div className="offers-overview-row">
                <div className="offers-overview-card3">
                    <p className="offers-overview-card-text">Audience globale (nombre d’utilisateurs avec votre marque dans leur espace personnel)</p>
                    {loaded ?
                        <p className="offers-overview-card-number">{audienceNumber}</p>
                        :
                        <Dots color="#2A7AF5" size={25} />
                    }
                </div>
            </div>

            <div className="audience-information-row">
                <div className="audience-information-pie-card hide-rechart-curve">
                    <h2 className="chart-title">Utilisateurs par tranche d’âge</h2>

                    <PieChart width={390} height={250}>
                        <Pie
                            data={usersByAgeRange}
                            dataKey="value"
                            cx="50%"
                            cy="50%"
                            outerRadius={120}
                            fill="#8884d8"
                            label={({ cx, cy, midAngle, innerRadius, outerRadius, value, index }) => {
                                const RADIAN = Math.PI / 180;
                                const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
                                const x = cx + radius * Math.cos(-midAngle * RADIAN);
                                const y = cy + radius * Math.sin(-midAngle * RADIAN);

                                return (
                                    <text x={x} y={y} fill="#fff" textAnchor="middle" dominantBaseline="central" fontFamily="Figtree" fontSize={14} fontWeight="bold" color="white">
                                        {`${value}%`}
                                    </text>
                                );
                            }}
                        >
                            {usersByAgeRange.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                        <Legend align="right" verticalAlign="middle" layout="vertical" />
                        <Tooltip />
                    </PieChart>

                    <div className="custom-legend" style={{ top: "17%" }}>
                        {legendAgeItems.map((item, index) => (
                            <div key={index} className="legend-age-range">
                                <span className="legend-icon" style={{ backgroundColor: item.color }}></span>
                                <span className="legend-label">{item.value}</span>
                            </div>
                        ))}

                    </div>

                </div>

                <div className="audience-information-pie-card hide-chart-labels">
                    <h2 className="chart-title">Utilisateurs par genre</h2>

                    <PieChart width={390} height={250}>
                        <Pie
                            data={usersByGender}
                            dataKey="value"
                            cx="50%"
                            cy="50%"
                            outerRadius={120}
                            fill="#8884d8"
                            label={({ cx, cy, midAngle, innerRadius, outerRadius, value, index }) => {
                                const RADIAN = Math.PI / 180;
                                const radius = 25 + innerRadius + (outerRadius - innerRadius);
                                const x = cx + radius * Math.cos(-midAngle * RADIAN);
                                const y = cy + radius * Math.sin(-midAngle * RADIAN);

                                return (
                                    <text x={x} y={y} fill="#000" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" fontFamily="Figtree" fontSize={12}>
                                        {`${usersByAgeRange[index].name} (${value}%)`}
                                    </text>
                                );
                            }}
                        >
                            {usersByGender.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                        <Legend
                            align="right"
                            verticalAlign="middle"
                            layout="vertical"
                            wrapperStyle={{
                                fontFamily: 'Figtree',
                                fontSize: '14px',
                                color: 'black'
                            }}
                        />
                        <style>
                            {`
                                .recharts-legend-item:nth-child(1),
                                .recharts-legend-item:nth-child(2) {
                                  margin-bottom: 50px; 
                                }
                               `}
                        </style>
                        <Tooltip />
                    </PieChart>

                    <div className="custom-legend">
                        {legendGenderItems.map((item, index) => (
                            <div key={index} className="legend-item">
                                <span className="legend-icon" style={{ backgroundColor: item.color }}></span>
                                <span className="legend-label">{item.value}</span>
                            </div>
                        ))}
                    </div>

                </div>
            </div>

            <div className="audience-information-location-card">
                <h2 className="chart-title">Utilisateurs par villes</h2>

                <div className="audience-information-location-row">
                    <div id="map"></div>
                    <div className="audience-information-location-legend">
                        {usersByCity.slice(0, 7).map((item, index) => (
                            <div key={index}>
                                <div className="legend-row">
                                    <span className="legend-label">{item.name}</span>
                                    <span className="legend-number">{item.count}</span>
                                </div>
                                <div className="legend-bar">
                                    <div className="legend-bar-pourcent" style={{ width: 213 * (item.count / userLocationTotalCount) }}></div>
                                </div>
                            </div>
                        ))}
                        {/* {usersByCity.length > 7 ?
                            <button>Voir toute la liste</button>
                            :
                            null
                        } */}
                    </div>
                </div>

            </div>


        </div>
    );
}

export default AudienceInformations;
