import "./Preview.css";
import { useUserAuth } from "../../context/UserAuthContext";
import React from "react";
import { ImArrowDown } from "react-icons/im";
import { IoClose, IoCheckmark, IoSearchOutline } from "react-icons/io5";
import { AiOutlineWarning } from "react-icons/ai";

const Preview = (item) => {
  const { user } = useUserAuth();
  return (
    <div className="preview-wrapper">
      {item.item !== undefined ? (
        <>
          <div className="preview-header-container">
            <h1 className="preview-header-title">
              Aperçu de la communication : {item.item.data.name} du{" "}
              {new Date(
                item.item.data.creationDate.seconds * 1000
              ).toLocaleDateString()}
            </h1>
          </div>
          <div className="preview-iphone-container">
            <div className="preview-iphone">
              <p className="preview-iphone-clock">
                {new Date().getHours() + ":"}
                {new Date().getMinutes() < 10
                  ? "0" + new Date().getMinutes()
                  : new Date().getMinutes()}
              </p>
              {item.item.data.date.seconds * 1000 > new Date().getTime() || item.item.data.date.seconds * 1000 + 86400000 > new Date().getTime() ?
                <div className="preview-iphone-offer-status">
                  <AiOutlineWarning color="white" style={{ marginRight: 5 }} />
                  <p className="preview-iphone-offer-status-text">
                    Prend fin le {new Date(item.item.data.date.seconds * 1000).toLocaleDateString().substring(0, 5)}
                  </p>
                </div>
                :
                item.item.data.date === "" ?
                  <></>
                  :
                  <div className="preview-iphone-offer-status-expired">
                    <p className="preview-iphone-offer-status-text">
                      Expiré
                    </p>
                  </div>
              }

              <div className="preview-iphone-actions-wrapper">
                <div className="preview-iphone-actions-btn-container">
                  <IoClose className="preview-iphone-actions-btn" color="red" />
                </div>
                <div className="preview-iphone-actions-btn-container">
                  <IoSearchOutline
                    className="preview-iphone-actions-btn"
                    color="#2A7AF5"
                  />
                </div>
                <div className="preview-iphone-actions-btn-container">
                  <IoCheckmark
                    className="preview-iphone-actions-btn"
                    color="#06DE42"
                  />
                </div>
              </div>

              <div className="preview-iphone-scrollable">
                <div className="preview-iphone-scrollable-elements">
                  <img
                    className="preview-iphone-scrollable-element-img"
                    src={item.item.data.picture}
                    alt={item.item.data.name}
                  />
                  <div className="preview-iphone-arraydown-btn-container">
                    <ImArrowDown
                      color="white"
                      className="preview-iphone-icon-arraydown"
                    />
                  </div>
                </div>

                <div className="preview-iphone-scrollable-elements">
                  <div className="row pt-20">
                    <img
                      src={user.logo}
                      alt={user.name}
                      className="preview-iphone-scrollable-element-logo"
                    />

                    <h3 className="preview-iphone-scrollable-element-partner">
                      {user.name}
                    </h3>
                  </div>
                  <p className="preview-iphone-scrollable-element-title">
                    {item.item.data.name}
                  </p>
                  <p className="preview-iphone-scrollable-element-description">
                    {item.item.data.description}
                  </p>
                  {item.item.data.picture2 !== undefined &&
                    item.item.data.picture2 !== "" ? (
                    <img
                      className="preview-iphone-scrollable-element-img2"
                      src={item.item.data.picture2}
                      alt=""
                    />
                  ) : null}
                  {item.item.data.description2 !== undefined &&
                    item.item.data.description2 !== "" ? (
                    <p className="preview-iphone-scrollable-element-description">
                      {item.item.data.description2}
                    </p>
                  ) : null}
                  {item.item.data.picture3 !== undefined &&
                    item.item.data.picture3 !== "" ? (
                    <img
                      className="preview-iphone-scrollable-element-img2"
                      src={item.item.data.picture3}
                      alt=""
                    />
                  ) : null}
                  {item.item.data.picture4 !== undefined &&
                    item.item.data.picture4 !== "" ? (
                    <img
                      className="preview-iphone-scrollable-element-img2"
                      src={item.item.data.picture4}
                      alt=""
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default Preview;
