import React from "react";
import { useNavigate } from "react-router-dom";
import { BsChevronDown } from "react-icons/bs";

const Nav = (param) => {
    const navigate = useNavigate();

    return (

        <div className="myOffers-header">
            <div
                className="myOffers-logo"
            >
                <div style={{ display: "flex", flexDirection: "row" }} onClick={() => {
                   // navigate("/home");
                }}>
                    <h1 className="myOffers-logo-title">Sharlock</h1>
                    <span className="myOffers-logo-subTitle">pro</span>
                </div>

                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    {/* <div
                        onClick={() => {
                            navigate("/home");
                        }}
                        className="myOffers-nav"
                    >
                        <p className="myOffers-nav-link">
                            Mes communications
                        </p>
                    </div> */}

                    <div
                        onClick={() => {
                            navigate("/promotions")
                        }}
                        className="myOffers-nav"
                    >
                        <p className="myOffers-nav-link">
                            Mes promos
                        </p>
                    </div>

                   {/*  <div
                        onClick={() => {
                            navigate("/vote");
                        }}
                        className="myOffers-nav"
                    >
                        <p className="myOffers-nav-link">
                            Votes
                        </p>
                    </div> */}

                    <div
                        onClick={() => {
                            navigate("/profil");
                        }}
                        className="myOffers-nav"
                    >
                        <p className="myOffers-nav-link">
                            Mon profil
                        </p>
                    </div>

                    <div
                        onClick={() => {
                            navigate("/statistics")
                        }}
                        className="myOffers-nav"
                    >
                        <p className="myOffers-nav-link">
                            Mes statistiques
                        </p>
                    </div>

                </div>
            </div>

            <div className="myOffers-container-profil">
                <div
                    className="myOffers-profil"
                    onClick={() => {
                        param.onPress()
                    }}
                >
                    <p className="myOffers-profil-initial">
                        {param.user.name !== undefined ? param.user.name[0] : null}
                    </p>
                </div>
                <div
                    className="myOffers-profil-desktopHeader"
                    onClick={() => {
                        param.onPress()
                    }}
                >
                    <p className="myOffers-profil-username">
                        {param.user.name !== undefined ? param.user.name : null}
                    </p>
                    <BsChevronDown className="myOffers-profil-arraydown" />
                </div>
            </div>
        </div>
    );
}

export default Nav;