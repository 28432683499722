import "./MyOffers.css";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IoGiftSharp } from "react-icons/io5";
import { AiFillCloseCircle } from "react-icons/ai";
import { useUserAuth } from "../../context/UserAuthContext";
import { database } from "../../firebase";
import { onSnapshot, collection } from "firebase/firestore";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { ClickAwayListener } from '@mui/base';
import { Dots } from "react-activity";
import "react-activity/dist/library.css";
import { Helmet } from "react-helmet";

import Nav from "../Nav"

const MyOffers = () => {
  const { user, logOut } = useUserAuth();
  const navigate = useNavigate();
  const [offers, setOffers] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [showProfilPanel, setShowProfilPanel] = useState(false);
  const dimension = {
    width: window.innerWidth,
    height: window.innerHeight,
  };
  const handleClose = () => { // close modal profil
    setShowProfilPanel(false);
  };

  const handleLogout = async () => { // logout user
    try {
      await logOut();
      navigate("/");
      setTimeout(() => {
        console.clear()
      }, 250);
    } catch (error) {
      alert("Une erreur est survenue lors de votre déconnexion");
    }
  };

  const openAccountModal = () => {
    setShowProfilPanel(true)
  }

  useEffect(() => { // Auth Context have some time to return user data so verify in first that user.uid !== undefined to be sure
    if (user.uid !== undefined) {

      onSnapshot(
        collection(database, "partners", user.uid, "offers"),
        (snapshot) => {
          const temp = [];

          let forEachExecuted = new Promise((resolve, reject) => {
            if (snapshot.docs.length !== 0) {
              // Promise in order to use a .then after forEach loop iterate the array
              snapshot.docs.forEach((element, index, array) => {
                temp.push({ id: element.id, data: element.data() });
                if (index === array.length - 1) resolve();  // Loop is over
              })
            }
            else {
              setLoaded(true)
            }
          })

          forEachExecuted.then(() => {
            setOffers(temp);
            setLoaded(true)
          })

        },
        (error) => { }
      );

    }
  }, [user]);

  return (
    <>

      <Helmet>
        <title>Sharlockpro - Mes communications</title>
        <meta name="keywords" content="sharlockpro, offers, home, accueil, mes offres, mes communications, offres, Sharlockpro.fr" />
        <meta name="description" content="Visualiser toutes les communications de votre compte partenaire." />
      </Helmet>

      <Modal
        open={showProfilPanel}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        hideBackdrop={dimension.width > 768 ? true : false}
      >
        <>
          <ClickAwayListener onClickAway={handleClose}>
            <Box className={user.uid === "p5aTQsaMqPdnL7ynK7v9dJSdgH83" ? "myOffers-box-admin" : "myOffers-box"}>
              <AiFillCloseCircle
                className="myOffers-modal-close"
                onClick={() => {
                  handleClose();
                }}
              />
              <div className="myOffers-modalContainer">
                <div className="myOffers-modal-profilCircle">
                  <p className="myOffers-modal-profiltext">
                    {user.name !== undefined ? user.name[0] : null}
                  </p>
                </div>
                <h2 className="myOffers-modal-title">
                  {user.name !== undefined ? user.name : null}
                </h2>
              </div>
              <div
                className="myOffers-modal-param"
                onClick={() => {
                  navigate("/params");
                }}
              >
                <p className="myOffers-modal-param-text">Paramètre du compte</p>
              </div>

              {user.uid === "p5aTQsaMqPdnL7ynK7v9dJSdgH83" &&
                <>
                  <div
                    className="myOffers-modal-param"
                    onClick={() => {
                      navigate("/partners-management");
                    }}
                  >
                    <p className="myOffers-modal-param-text">Gestion des partenaires</p>
                  </div>

                  <div
                    className="myOffers-modal-param"
                    onClick={() => {
                      navigate("/promotions-management");
                    }}
                  >
                    <p className="myOffers-modal-param-text">Gérer les promotions</p>
                  </div>
                </>
              }

              <div
                onClick={() => {
                  handleLogout();
                }}
                className="myOffers-modal-logoutBtn"
              >
                <p className="myOffers-modal-logoutText">Déconnexion</p>
              </div>
            </Box>
          </ClickAwayListener>
        </>
      </Modal>

      <Nav user={user} onPress={openAccountModal} />

      <div className="myOffers-main">
        <div className="myOffers-main-row">
          <div className="myOffers-main-col-1">
            <h1 className="myOffers-mainTitle">Mes communications</h1>
            <p className="myOffers-mainSubtitle">
              Choisissez une communication pour la consulter, la modifier ou avoir un aperçu de ses performances
            </p>
          </div>
          <div className="myOffers-main-col-2">
            <div
              onClick={() => {
                navigate("/create-offer");
                //navigate("/new-partner");
              }}
              className="myOffers-newOfferBtn"
            >
              <IoGiftSharp className="myOffers-newOfferBtn-icon" />
              <p className="myOffers-newOfferBtn-text">
                Créer une nouvelle communication
              </p>
            </div>
          </div>
        </div>
        <div className="myOffers-allOffers">
          <h2 className="myOffers-allOffers-title">Toutes mes communications</h2>
          <hr className="myOffers-allOffers-hr" />
        </div>
        {loaded && offers.length > 0 ? (
          <div className="myOffers-container-desktop-list">
            <div className="myOffers-content-list">
              {offers &&
                offers
                  .sort((a, b) => a.data.date.seconds - b.data.date.seconds)
                  .map((offer, index) => (
                    <div
                      key={index}
                      className="myOffers-content-wrap"
                      onClick={() => {
                        navigate("/offer-detail", { state: { item: offer } });
                      }}
                    >
                      <img
                        src={offer.data.picture}
                        alt={offer.data.name}
                        className="myOffers-content-wrap-img"
                      />
                      <div className="myOffers-content-wrap-date">
                        <p className="myOffers-content-wrap-item-date">
                          {new Date(
                            offer.data.creationDate.seconds * 1000
                          ).toLocaleDateString()}
                        </p>
                      </div>
                      {offer.data.date === "" || offer.data.date.seconds * 1000 > new Date().getTime() || offer.data.date.seconds * 1000 + 86400000 > new Date().getTime() ?
                        <div className="myOffers-content-wrap-status">
                          <p className="myOffers-content-wrap-item-status">
                            En cours
                          </p>
                        </div>
                        :
                        <div className="myOffers-content-wrap-status-expired">
                          <p className="myOffers-content-wrap-item-status">
                            Expiré
                          </p>
                        </div>
                      }
                      <div className="myOffers-content-wrap-description">
                        <p className="myOffers-content-wrap-item-description">
                          {offer.data.name}
                        </p>
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        ) : loaded && offers.length === 0 ?
          <div className="myOffers-container-desktop-list">
            <div className="myOffers-content-list">
            </div>
          </div>
          :
          <div style={{ height: "70vh", paddingTop: 50, display: "flex", justifyContent: "center", background: "#FEF7EA" }}>
            <Dots color="#2A7AF5" size={30} />
          </div>
        }
        <div className="myOffers-listOfItems">
          {offers
            .sort((a, b) => a.data.creationDate - b.data.creationDate)
            .map((offer) => {
              return (
                <div
                  key={offer.id}
                  onClick={() => {
                    navigate("/offer-detail", { state: { item: offer } });
                  }}
                  className="myOffers-item-container"
                >
                  <div className="myOffers-item">
                    <div className="myOffers-item-imgContainer">
                      <img
                        src={offer.data.picture}
                        alt={offer.data.name}
                        className="myOffers-item-img"
                      />
                    </div>
                    <div className="myOffers-item-description">

                      {offer.data.date.seconds * 1000 > new Date().getTime() || offer.data.date.seconds * 1000 + 86400000 > new Date().getTime() ?
                        <div className="myOffers-item-status">
                          <p className="myOffers-item-status-text">En cours</p>
                        </div>
                        :
                        <div className="myOffers-item-status-expired">
                          <p className="myOffers-item-status-text">Expiré</p>
                        </div>
                      }

                      <p className="myOffers-item-description-text">
                        {offer.data.name}
                      </p>
                      <p className="myOffers-item-description-text">
                        {new Date(
                          offer.data.creationDate.seconds * 1000
                        ).toLocaleDateString()}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        <div className="myOffers-container-newOfferBtn">
          <div
            onClick={() => {
              navigate("/create-offer");
            }}
            className="myOffers-newOfferBtn"
          >
            <IoGiftSharp className="myOffers-newOfferBtn-icon" />
            <p className="myOffers-newOfferBtn-text">
              Créer une nouvelle communication
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyOffers;
