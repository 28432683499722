import React, { createContext, useContext, useCallback, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  sendPasswordResetEmail,
  updateEmail,
  reauthenticateWithCredential,
  EmailAuthProvider,
  getAuth,
  updatePassword
} from "firebase/auth";
import { auth, database } from "../firebase";
import {
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import ls from 'localstorage-slim';
import { useNavigate } from 'react-router-dom';

const userAuthContext = createContext();

const lsUserData = ls.get('sharlock-partner', { decrypt: true })

export function UserAuthContextProvider({ children }) {

  const navigate = useNavigate();
  const [user, setUser] = useState(JSON.parse(lsUserData));

  const logIn = useCallback((email, password) => {
    return signInWithEmailAndPassword(auth, email, password)
  }, []);

  const reAuth = (password) => { // Firebase need a recent Auth on some function who are sensitive (change email/password...)
    const auth = getAuth();
    const { currentUser } = auth;
    const credential = EmailAuthProvider.credential(user.email, password);
    return reauthenticateWithCredential(currentUser, credential);
  }

  const signUp = useCallback((email, password) => {
    return createUserWithEmailAndPassword(auth, email, password);
  }, []);

  const logOut = () => {
    ls.remove('sharlock-partner'); // Clear local storage
    return signOut(auth);
  }

  const updateUserEmail = useCallback((email) => {
    return updateEmail(auth.currentUser, email);
  }, []);

  const updateCurrentUserEmail = (email) => {
    const userData = auth.currentUser;
    const itemRef = doc(database, "partners", userData.uid);

    updateDoc(itemRef, {
      email: auth.currentUser.email,
    });
    let newUser = { ...user }
    newUser.email = email
    setUser(newUser)

  }

  const changePassword = useCallback((password) => {
    return updatePassword(auth.currentUser, password);
  }, []);

  const resetPassword = useCallback((email) => {
    return sendPasswordResetEmail(auth, email);
  }, []);

  const getUser = useCallback(async (currentUser) => {
    try {
      const docRef = doc(database, "partners", currentUser.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return { id: docSnap.id, data: docSnap.data() }
      } else {
        return null;
      }
    } catch (error) {
      // console.error(error);
    }
  }, []);

  const updateUser = (data) => {
    const updateNestedObject = (obj, newData) => {
      for (const key in newData) {
        const value = newData[key];
        if (typeof value === "object" && !Array.isArray(value)) {
          obj[key] = updateNestedObject(obj[key] || {}, value);
        } else {
          obj[key] = value;
        }
      }
      return obj;
    };

    const updatedUser = updateNestedObject({ ...user }, data);
    const JSONdata = JSON.stringify(updatedUser);
    ls.set("sharlock-partner", JSONdata, { encrypt: true });
    setUser(updatedUser);
  };

  useEffect(() => {
    let user;

    if (lsUserData !== null) {
      user = JSON.parse(lsUserData);
      setUser(user);
      if (user) {
        // Redirect to home page
        navigate('/profil', { replace: true });
      }
    } else {
      const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
        if (currentUser !== null) {
          try {
            const response = await getUser(currentUser);

            if (response && response.data) {
              user = response.data;
              const JSONdata = JSON.stringify(user);
              ls.set('sharlock-partner', JSONdata, { encrypt: true });
              setUser(user);
            } else {
              console.error('Failed to fetch user data');
              setUser(null);
            }
          } catch (error) {
            console.error('Error fetching user data:', error);
            setUser(null);
          }
        } else {
          setUser(null);
        }
      });

      return () => {
        unsubscribe();
      };
    }
  }, []);


  return (
    <userAuthContext.Provider
      value={{
        user,
        logIn,
        signUp,
        logOut,
        resetPassword,
        updateUserEmail,
        updateCurrentUserEmail,
        changePassword,
        reAuth,
        getUser,
        updateUser
      }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}
