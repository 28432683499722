import React from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend } from 'recharts';

const LineChartComponent = (item) => {

    return (
        <div className="linechart-container">
            <h2 className="chart-title">Statistiques au fil du temps</h2>

            <LineChart width={950} height={350} data={item.data}>
                <XAxis dataKey="day" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" name={<span style={{ fontFamily: 'Figtree', fontSize: '16px', paddingRight: 20 }}>Ouverture</span>} dataKey="ouverture" stroke="#00BEFF" />
                <Line type="monotone" name={<span style={{ fontFamily: 'Figtree', fontSize: '16px', paddingRight: 20 }}>Swipe à droite</span>} dataKey="swipeDroite" stroke="#0AFF00" />
                <Line type="monotone" name={<span style={{ fontFamily: 'Figtree', fontSize: '16px', paddingRight: 20 }}>Swipe à gauche</span>} dataKey="swipeGauche" stroke="#FF0404" />
                <Line type="monotone" name={<span style={{ fontFamily: 'Figtree', fontSize: '16px' }}>Accès au site</span>} dataKey="accesSite" stroke="#4D34E8" />
            </LineChart>
        </div>

    );
};


export default LineChartComponent;
