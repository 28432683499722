import React, { useEffect } from 'react';

const CrispChat = (props) => {

    useEffect(() => {
        window.$crisp = [];
        window.CRISP_WEBSITE_ID = "410406ef-04b7-409d-8513-2605e0bbcdae";

        const script = document.createElement("script");
        script.src = "https://client.crisp.chat/l.js";
        script.async = true;
        document.body.appendChild(script);
        window.$crisp.push(['set', 'user:email', props.user.email]);
        window.$crisp.push(['set', 'user:nickname', props.user.name]);
        window.$crisp.push(['set', 'user:avatar', props.user.logo]);
        window.$crisp.push(['set', 'user:company', props.user.name]);
    }, []);

    return (

        <React.Fragment />

    );
};

export default CrispChat;