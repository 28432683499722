import React, { useEffect, useState } from "react";
import "./Stats.css";
import { Dots } from "react-activity";
import "react-activity/dist/library.css";
import LineChartComponent from './LineChartComponent';
import ProgressBarComponent from "./ProgressBarComponent";
import { database } from "../../../firebase";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";

const OffersInfluenceOverview = (item) => {
    const [offerPublished, setOfferPublished] = useState([])
    const [audienceNumber, setAudienceNumber] = useState()
    const [loaded, setLoaded] = useState(false)

    const data = [
        { day: 'Jour 1', ouverture: 10, swipeDroite: 40, swipeGauche: 96, accesSite: 10 },
        { day: 'Jour 2', ouverture: 46, swipeDroite: 55, swipeGauche: 124, accesSite: 25 },
        { day: 'Jour 3', ouverture: 85, swipeDroite: 124, swipeGauche: 185, accesSite: 105 },
        { day: 'Jour 4', ouverture: 157, swipeDroite: 206, swipeGauche: 197, accesSite: 201 },
        { day: 'Jour 5', ouverture: 230, swipeDroite: 230, swipeGauche: 230, accesSite: 230 },
    ];

    const getOffersPublished = async () => {
        const offersCollectionRef = collection(database, "partners", item.user.uid, "offers");
        const querySnapshot = await getDocs(offersCollectionRef);

        const offers = querySnapshot.docs.map((doc) => {
            return doc.data();
        });
        setOfferPublished(offers.length);
    };

    const calculateGlobalAudience = async () => {
        let audienceUserNumber = 0;

        const usersSnapshot = await getDocs(collection(database, "users"));

        const userPromises = usersSnapshot.docs.map(async (userDoc) => {
            const websiteDoc = await getDoc(doc(collection(database, "users", userDoc.id, "websites"), item.user.uid));

            if (websiteDoc.exists()) {
                audienceUserNumber++;
            }
        });

        await Promise.all(userPromises);

        // Use the audienceUserNumber for further processing or display
        setAudienceNumber(audienceUserNumber)
    }


    useEffect(() => {
        const fetchData = async () => {
            try {
                await Promise.all([getOffersPublished(), calculateGlobalAudience()]);
                setLoaded(true);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    return (
        <div className="stats-wrapper">
            <h1 className="preview-header-title">Apercu global de l’influence de mes communications</h1>

            <div className="offers-overview-row">
                <div className="offers-overview-card">
                    <p className="offers-overview-card-text">Audience globale (nombre d’utilisateurs avec votre marque dans leur espace personnel) </p>
                    {loaded ?
                        <p className="offers-overview-card-number">{audienceNumber}</p>
                        :
                        <Dots color="#2A7AF5" size={25} />
                    }
                </div>

                <div className="offers-overview-card">
                    <p className="offers-overview-card-text">Nombre de communications publiées</p>
                    {loaded ?
                        <p className="offers-overview-card-number">{offerPublished}</p>
                        :
                        <Dots color="#2A7AF5" size={25} />
                    }
                </div>
            </div>

            <div className="offers-overview-row-card-container">
                <div className="offers-overview-row-card">
                    <p className="offers-overview-card-text">Nombre d'ouverture</p>
                    {loaded ?
                        <p className="offers-overview-card-number">{item.user.tapCardCount}</p>
                        :
                        <Dots color="#2A7AF5" size={25} />
                    }
                    <div style={{ backgroundColor: "#00BEFF" }} className="offers-overview-hr"></div>
                </div>

                <div className="offers-overview-row-card">
                    <p className="offers-overview-card-text">Nombre de swipe à droite</p>
                    {loaded ?
                        <p className="offers-overview-card-number">{item.user.swipeRightCount}</p>
                        :
                        <Dots color="#2A7AF5" size={25} />
                    }
                    <div style={{ backgroundColor: "#0AFF00" }} className="offers-overview-hr"></div>
                </div>

                <div className="offers-overview-row-card">
                    <p className="offers-overview-card-text">Nombre de swipe à gauche</p>
                    {loaded ?
                        <p className="offers-overview-card-number">{item.user.swipeLeftCount}</p>
                        :
                        <Dots color="#2A7AF5" size={25} />
                    }
                    <div style={{ backgroundColor: "#FF0404" }} className="offers-overview-hr"></div>
                </div>

                <div className="offers-overview-row-card">
                    <p className="offers-overview-card-text">Nombre d'accès au site</p>
                    {loaded ?
                        <p className="offers-overview-card-number">{item.user.offerVisitsCount}</p>
                        :
                        <Dots color="#2A7AF5" size={25} />
                    }
                    <div style={{ backgroundColor: "#4D34E8" }} className="offers-overview-hr"></div>
                </div>
            </div>

            <LineChartComponent data={data} />
            <ProgressBarComponent audienceNumber={audienceNumber} user={item.user} offerPublished={offerPublished} />
        </div>
    );
}

export default OffersInfluenceOverview;
