import React from "react";
import { Routes, Route } from "react-router-dom";
import { Navigate } from "react-router";
import ProtectedRoute from "./components/ProtectedRoute";
import { UserAuthContextProvider } from "./context/UserAuthContext";
import Login from "./components/Login/Login";
import Register from "./components/Login/Register";
import ForgotPassword from "./components/Login/ForgotPassword";
import MyOffers from "./components/Home/MyOffers";
import CreateOffer from "./components/Home/CreateOffer";
import OfferDetails from "./components/Home/OfferDetails";
import Analytics from "./components/Home/Analytics";
import Params from "./components/Home/Params";
import MyProfil from "./components/Home/MyProfil";
import MyStats from "./components/Home/MyStats";
import StatsWrapper from "./components/Home/Stats/StatsWrapper"
import Vote from "./components/Home/Vote"
import MyPromotions from "./components/Home/MyPromotions"
import PromotionsDashboard from "./components/Home/Admin/PromotionsDashboard";
import PromotionCategories from "./components/Home/Admin/PromotionCategories";
import PromotionsAssignPartners from "./components/Home/Admin/PromotionsAssignPartners";
import PromotionsAssignPartnersText from "./components/Home/Admin/PromotionsAssignPartnersText";
import NewPartner from "./components/Home/Admin/NewPartner";
import RemovePartner from "./components/Home/Admin/RemovePartner";
import PartnersDashboard from "./components/Home/Admin/PartnersDashboard"
import AdminVote from "./components/Home/Admin/AdminVote";


function App() {
  return (
    <div className="App">
      <UserAuthContextProvider>
        <Routes>

          <Route exact path="/" element={<Login />} />

          <Route exact path="/forgot-password" element={<ForgotPassword />} />

          <Route exact path="/home" element={
            <ProtectedRoute>
              <MyOffers />
            </ProtectedRoute>
          }
          />

          <Route exact path="/vote" element={
            <ProtectedRoute>
              <Vote />
            </ProtectedRoute>
          }
          />

          <Route exact path="/promotions" element={
            <ProtectedRoute>
              <MyPromotions />
            </ProtectedRoute>
          }
          />

          <Route exact path="/create-offer" element={
            <ProtectedRoute>
              <CreateOffer />
            </ProtectedRoute>
          }
          />

          <Route exact path="/offer-detail" element={
            <ProtectedRoute>
              <OfferDetails />
            </ProtectedRoute>
          }
          />

          <Route exact path="/analytics" element={
            <ProtectedRoute>
              <Analytics />
            </ProtectedRoute>
          }
          />

          <Route exact path="/params" element={
            <ProtectedRoute>
              <Params />
            </ProtectedRoute>
          }
          />

          <Route exact path="/profil" element={
            <ProtectedRoute>
              <MyProfil />
            </ProtectedRoute>
          }
          />

          <Route exact path="/statistics" element={
            <ProtectedRoute>
              <MyStats />
            </ProtectedRoute>
          }
          />


          <Route exact path="/statistics/:parameter" element={
            <ProtectedRoute>
              <StatsWrapper />
            </ProtectedRoute>
          }
          />

          <Route exact path="/partners-management" element={
            <ProtectedRoute>
              <PartnersDashboard />
            </ProtectedRoute>
          }
          />

          <Route exact path="/new-partner" element={
            <ProtectedRoute>
              <NewPartner />
            </ProtectedRoute>
          }
          />

          <Route exact path="/remove-partner" element={
            <ProtectedRoute>
              <RemovePartner />
            </ProtectedRoute>
          }
          />

          <Route exact path="/register-partner-account" element={
            <ProtectedRoute>
              <Register />
            </ProtectedRoute>
          }
          />

          <Route exact path="/admin-vote" element={
            <ProtectedRoute>
              <AdminVote />
            </ProtectedRoute>
          }
          />

          <Route exact path="/promotions-management" element={
            <ProtectedRoute>
              <PromotionsDashboard />
            </ProtectedRoute>
          }
          />

          <Route exact path="/promotions-management/categories" element={
            <ProtectedRoute>
              <PromotionCategories />
            </ProtectedRoute>
          }
          />

          <Route exact path="/promotions-management/assign-partners" element={
            <ProtectedRoute>
              <PromotionsAssignPartners />
            </ProtectedRoute>
          }
          />

          <Route exact path="/promotions-management/assign-partners-text" element={
            <ProtectedRoute>
              <PromotionsAssignPartnersText />
            </ProtectedRoute>
          }
          />

          <Route path="*" element={<Navigate to="/" />} />

        </Routes>
      </UserAuthContextProvider>
    </div>
  );
}

export default App;
