import "./Analytics.css";
import React from "react";
import {
  RadialBarChart,
  RadialBar,
  ResponsiveContainer,
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const Analytics = () => {
  const data = [
    {
      name: "18-24",
      uv: 31.47,
      pv: 2400,
      fill: "#8884d8",
    },
    {
      name: "25-29",
      uv: 26.69,
      pv: 4567,
      fill: "#83a6ed",
    },
    {
      name: "30-34",
      uv: 15.69,
      pv: 1398,
      fill: "#8dd1e1",
    },
    {
      name: "35-39",
      uv: 8.22,
      pv: 9800,
      fill: "#82ca9d",
    },
    {
      name: "40-49",
      uv: 8.63,
      pv: 3908,
      fill: "#a4de6c",
    },
    {
      name: "50+",
      uv: 2.63,
      pv: 4800,
      fill: "#d0ed57",
    },
    {
      name: "inconnu",
      uv: 6.67,
      pv: 4800,
      fill: "#ffc658",
    },
  ];
  const style = {
    top: "50%",
    right: 0,
    transform: "translate(0, -50%)",
    lineHeight: "24px",
  };
  const data01 = [
    { x: 1, y: 190 },
    { x: 30, y: 230 },
    { x: 45, y: 270 },
    { x: 50, y: 305 },
    { x: 70, y: 340 },
    { x: 120, y: 450 },
    { x: 200, y: 650 },
    { x: 365, y: 927 },
  ];
  const data02 = [
    { x: 1, y: 20 },
    { x: 50, y: 30 },
    { x: 75, y: 65 },
    { x: 100, y: 90 },
    { x: 120, y: 122 },
    { x: 200, y: 156},
    { x: 365, y: 190 },
  ];
  return (
    <div className="analytics-container">
      <h1 className="analytics-title">Données analytiques</h1>
      <div className="analytics-card">
        <p className="analytics-card-text">Nombre de like</p>
        <ResponsiveContainer width="100%" height="100%">
          <ScatterChart
            width={500}
            height={400}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20,
            }}
          >
            <CartesianGrid />
            <XAxis type="number" dataKey="x" name="Temps écoulé" unit=" jours" />
            <YAxis type="number" dataKey="y" name="Total" unit=" like" />
            <ZAxis type="number" range={[100]} />
            <Tooltip cursor={{ strokeDasharray: "3 3" }} />
            <Legend />
            <Scatter
              name="Année 2022"
              data={data01}
              fill="#8884d8"
              line
              shape="cross"
            />
            <Scatter
              name="Année 2021"
              data={data02}
              fill="#82ca9d"
              line
              shape="diamond"
            />
          </ScatterChart>
        </ResponsiveContainer>
      </div>
      <div className="analytics-card">
        <p className="analytics-card-text">Nombre d'accès au site</p>
        <ResponsiveContainer width="100%" height="100%">
          <RadialBarChart
            cx="50%"
            cy="50%"
            innerRadius="1%"
            outerRadius="90%"
            barSize={100}
            data={data}
          >
            <RadialBar
              minAngle={15}
              label={{ position: "insideStart", fill: "#fff",fontSize:10 }}
              background
              clockWise
              dataKey="uv"
            />
            <Legend
              iconSize={10}
              layout="vertical"
              verticalAlign="middle"
              wrapperStyle={style}
            />
          </RadialBarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default Analytics;
