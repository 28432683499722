/*eslint-disable */
import "./CreateOffer.css";
import "react-activity/dist/library.css";
import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useUserAuth } from "../../context/UserAuthContext";
import { v4 } from "uuid";
import { storage, database, auth } from "../../firebase";
import { collection, doc, updateDoc, setDoc, Timestamp, getDocs, increment } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { HiOutlinePlus } from "react-icons/hi";
import { AiFillCloseCircle } from "react-icons/ai";
import { IoClose, IoCheckmark, IoSearchOutline } from "react-icons/io5";
import { ImArrowDown } from "react-icons/im";
import { MdInfoOutline } from "react-icons/md";
import axios from "axios"
import Modal from "@mui/material/Modal";
import { ClickAwayListener } from '@mui/base';
import Box from "@mui/material/Box";
import { Dots } from "react-activity";
import { Helmet } from "react-helmet";
import Nav from "../Nav"

const CreateOffer = () => {

  const regexUrl = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&\/=]*)$/

  const navigate = useNavigate();
  const { logOut, user, updateUser } = useUserAuth();
  const [showProfilPanel, setShowProfilPanel] = useState(false);
  const [showBubbleInfos, setShowBubbleInfos] = useState({ isVisible: false, type: "" });
  const [infoIconPosition, setInfoIconPosition] = useState({ x: 0, y: 0 });
  const [description, setDescription] = useState("");
  const [description2, setDescription2] = useState("");
  const [title, setTitle] = useState("");
  const [type, setType] = useState("")
  const [file, setFile] = useState(null);
  const [file2, setFile2] = useState(null);
  const [file3, setFile3] = useState(null);
  const [file4, setFile4] = useState(null);
  const [date, setDate] = useState();
  const [link, setLink] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState(null);
  const [dateError, setDateError] = useState(null);
  const inputFileRef1 = useRef(null);
  const inputFileRef2 = useRef(null);
  const inputFileRef3 = useRef(null);
  const inputFileRef4 = useRef(null);
  const dimension = {
    width: window.innerWidth,
    height: window.innerHeight,
  };
  const [offerIsPublishing, setOfferIsPublishing] = useState(false)

  const handleIconClick = (event, name) => {
    setInfoIconPosition({ x: event.clientX, y: event.clientY });
    setShowBubbleInfos({ isVisible: true, type: name });
  };

  const handleClick = (ref) => {  // create a useRef of the file click
    ref.current.click();
  };

  const handleChange = (e) => { // Verify size of the image & mutate file state

    if (e.target.files[0].type !== "image/png" && e.target.files[0].type !== "image/jpeg" && e.target.files[0].type !== "image/webp") {
      alert("Vous devez sélectionner un fichier de type image (jpg/jpeg/png/webp)")
    }
    else {
      if (e.target.files[0].size >= 5000000) {
        setError("Le fichier est trop volumineux");
      }
      else {
        setError(null);
        if (e.currentTarget.name === "image") {
          let file = e.target.files[0];
          file.url = URL.createObjectURL(e.target.files[0]);
          setFile(file);
        }
        if (e.currentTarget.name === "image2") {
          let file = e.target.files[0];
          file.url = URL.createObjectURL(e.target.files[0]);
          setFile2(file);
        }
        if (e.currentTarget.name === "image3") {
          let file = e.target.files[0];
          file.url = URL.createObjectURL(e.target.files[0]);
          setFile3(file);
        }
        if (e.currentTarget.name === "image4") {
          let file = e.target.files[0];
          file.url = URL.createObjectURL(e.target.files[0]);
          setFile4(file);
        }
      }
    }
  };

  const handleClose = () => { // Close the modal profil
    setShowProfilPanel(false);
  };

  const sendNotification = async (users, title, body, data) => {
    try {
      const response = await axios.post('https://europe-west1-sharlock-36504.cloudfunctions.net/api/sendNotification', {
        users,
        title,
        body,
        data
      });
      console.log(response.data);
    } catch (error) {
      console.log('Error sending notification:', error);
      throw error;
    }
  };

  const fetchUsersThatHasExploredPartner = async () => {
    try {
      const usersRef = collection(database, 'users');
      const usersSnapshot = await getDocs(usersRef);

      const usersHasExploredPartner = [];

      await Promise.all(usersSnapshot.docs.map(async (userDoc) => {
        const userWebsitesRef = collection(database, 'users', userDoc.id, 'websites');
        const userWebsitesSnapshot = await getDocs(userWebsitesRef);

        userWebsitesSnapshot.forEach((websiteDoc) => {
          if (websiteDoc.id === user.uid && userDoc.data().pushToken !== "") {
            usersHasExploredPartner.push({ pushToken: userDoc.data().pushToken, id: userDoc.data().uid, notificationBadgeCount: userDoc.data().notificationBadgeCount + 1 });
          }
        });
      }));

      return usersHasExploredPartner;
    } catch (error) {
      console.error('Error fetching users:', error);
      return [];
    }
  };

  const onSubmitForm = async () => { // if the offer is valid and can be sent

    setOfferIsPublishing(true)  // Inform client that treatment is on process

    const uuid = v4();
    const userData = auth.currentUser;
    const itemRef = doc(database, "partners", userData.uid);
    const colRef = collection(itemRef, "offers");
    const itemDoc = doc(colRef, uuid);
    const imageRef1 = ref(storage, `${user.uid}/images/${uuid}/${user.uid}_picture1`);
    const imageRef2 = ref(storage, `${user.uid}/images/${uuid}/${user.uid}_picture2`);
    const imageRef3 = ref(storage, `${user.uid}/images/${uuid}/${user.uid}_picture3`);
    const imageRef4 = ref(storage, `${user.uid}/images/${uuid}/${user.uid}_picture4`);

    const now = Timestamp.now()

    // update partner props
    const partnerDocRef = doc(database, "partners", userData.uid);
    const updatedData = {
      exclusiveOffer: type === "Offre exclusive" ? { hasExclusivesOffers: true, mostRecent: now } : { hasExclusivesOffers: user.exclusiveOffer.hasExclusivesOffers, mostRecent: user.exclusiveOffer.mostRecent },
      regularNews: type === "Actualité" ? { hasRegularNews: true, mostRecent: now } : { hasRegularNews: user.regularNews.hasRegularNews, mostRecent: user.regularNews.mostRecent },
    };

    // update user data
    let newUser = { ...user };
    const promises = [];

    if (type === "Offre exclusive") {
      newUser.exclusiveOffer.mostRecent = now
      newUser.exclusiveOffer.hasExclusivesOffers = true
      promises.push(updateUser(newUser));
      await updateUser(newUser);
    }

    if (type === "Actualité") {
      newUser.regularNews.mostRecent = now
      newUser.regularNews.hasRegularNews = true
      promises.push(updateUser(newUser));
      await updateUser(newUser);
    }

    await Promise.all(promises);
    await updateDoc(partnerDocRef, updatedData);

    // create offer in db
    try {
      await setDoc(itemDoc, {
        date: date !== undefined ? date : "",
        description: description,
        description2: description2,
        name: title,
        url: link,
        picture: "",
        picture2: "",
        picture3: "",
        picture4: "",
        creationDate: now,
      });

    } catch (error) { }

    uploadBytes(imageRef1, file)
      .then(async () => {
        getDownloadURL(imageRef1)
          .then((url) => {
            try {
              updateDoc(itemDoc, {
                picture: url,
              });
              if (file2 === null && file3 === null && file4 === null) { // if there is only 1 file to upload
                if (user.uid !== undefined) {
                  setOfferIsPublishing(false)
                  navigate("/home");
                }
              }
              else {
                if (file2 !== null) {
                  uploadBytes(imageRef2, file2)
                    .then(() => {
                      getDownloadURL(imageRef2)
                        .then((url2) => {
                          try {
                            updateDoc(itemDoc, {
                              picture2: url2,
                            });
                            if (file3 === null && file4 === null) {
                              if (user.uid !== undefined) {
                                setOfferIsPublishing(false)
                                navigate("/home");
                              }

                            }
                          } catch (error) { }
                        })
                        .catch((error) => { });
                    })
                    .catch((error) => { });
                }
                if (file3 !== null) {
                  uploadBytes(imageRef3, file3)
                    .then(() => {
                      getDownloadURL(imageRef3)
                        .then((url3) => {
                          try {
                            updateDoc(itemDoc, {
                              picture3: url3,
                            });
                            if (file4 === null) {
                              if (user.uid !== undefined) {
                                setOfferIsPublishing(false)
                                navigate("/home");
                              }
                            }
                          } catch (error) { }
                        })
                        .catch((error) => { });
                    })
                    .catch((error) => { });
                }
                if (file4 !== null) {
                  uploadBytes(imageRef4, file4)
                    .then(() => {
                      getDownloadURL(imageRef4)
                        .then((url4) => {
                          try {
                            updateDoc(itemDoc, {
                              picture4: url4,
                            });
                            if (user.uid !== undefined) {
                              setOfferIsPublishing(false)
                              navigate("/home");
                            }
                          } catch (error) { }
                        })
                        .catch((error) => { });
                    })
                    .catch((error) => { });
                }
              }
            } catch (error) { }
          })
          .catch((error) => { });

        // Notification
        const users = await fetchUsersThatHasExploredPartner()
        const title = `${user.name} vient de publier une Sharlock-com’s`
        const body = `Une ${type} de ${user.name} est disponible dans votre espace Sharlock-com’s.`
        const data = { screen: "sharlock://sharlock-com's" }
        await sendNotification(users, title, body, data)

        users.forEach((element) => {
          console.log(element.id)
          const userRef = doc(database, 'users', element.id);
          updateDoc(userRef, { notificationBadgeCount: element.notificationBadgeCount });
        });
      })
      .catch((error) => { });
  };

  const transformDate = (date) => { // modify format of date
    setDate(new Date(date));
  };

  const handleLogout = async () => { // logout user
    try {
      await logOut();
      navigate("/");
      setTimeout(() => {
        console.clear()
      }, 250);
    } catch (error) {
      alert("Une erreur est survenue lors de votre déconnexion");
    }
  };

  const openAccountModal = () => {
    setShowProfilPanel(true)
  }

  useEffect(() => {// Condition to verify that user can upload his offer 

    if (file !== null && title !== "" && link !== null && regexUrl.test(link) === true && type !== "") {
      if (date !== undefined) {
        if (isNaN(date.getTime())) {
          setDate(undefined)
          setDisabled(true);
        } else {
          setDisabled(false);
        }
      } else {
        setDisabled(false);
      }
    }
    else {
      setDisabled(true)
    }
  }, [file, title, link, date, type]);

  return (
    <>

      <Helmet>
        <title>Sharlockpro - Créer une nouvelle communication</title>
        <meta name="keywords" content="sharlockpro, offers, home, accueil, créer une offre, créer une communication, offres, Sharlockpro.fr" />
        <meta name="description" content="Créer une nouvelle communication que pourrons consulter les utilisateurs de l'application mobile" />
      </Helmet>

      <Modal
        open={showProfilPanel}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        hideBackdrop={dimension.width > 768 ? true : false}
      >
        <>
          <ClickAwayListener onClickAway={handleClose}>
            <Box className={user.uid === "p5aTQsaMqPdnL7ynK7v9dJSdgH83" ? "myOffers-box-admin" : "myOffers-box"}>
              <AiFillCloseCircle
                className="myOffers-modal-close"
                onClick={() => {
                  handleClose();
                }}
              />
              <div className="myOffers-modalContainer">
                <div className="myOffers-modal-profilCircle">
                  <p className="myOffers-modal-profiltext">
                    {user.name !== undefined ? user.name[0] : null}
                  </p>
                </div>
                <h2 className="myOffers-modal-title">
                  {user.name !== undefined ? user.name : null}
                </h2>
              </div>
              <div
                className="myOffers-modal-param"
                onClick={() => {
                  navigate("/params");
                }}
              >
                <p className="myOffers-modal-param-text">Paramètre du compte</p>
              </div>

              {user.uid === "p5aTQsaMqPdnL7ynK7v9dJSdgH83" &&
                <>
                  <div
                    className="myOffers-modal-param"
                    onClick={() => {
                      navigate("/partners-management");
                    }}
                  >
                    <p className="myOffers-modal-param-text">Gestion des partenaires</p>
                  </div>

                  <div
                    className="myOffers-modal-param"
                    onClick={() => {
                      navigate("/promotions-management");
                    }}
                  >
                    <p className="myOffers-modal-param-text">Gérer les promotions</p>
                  </div>
                </>
              }

              <div
                onClick={() => {
                  handleLogout();
                }}
                className="myOffers-modal-logoutBtn"
              >
                <p className="myOffers-modal-logoutText">Déconnexion</p>
              </div>
            </Box>
          </ClickAwayListener>
        </>
      </Modal>

      <Modal
        open={showBubbleInfos.isVisible}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        hideBackdrop={true}
      >
        <>
          <ClickAwayListener onClickAway={() => setShowBubbleInfos({ isVisible: false, type: "" })}>
            <Box className="createOffer-modal-bubble-informations" style={{ top: infoIconPosition.y, left: infoIconPosition.x + 30 }}>
              {showBubbleInfos.type === "Offre exclusive" ?
                <>
                  <p>Si la communication que vous souhaitez publier est une “Offre exclusive Sharlock” : cochez cette case.</p>
                  <p>Profitez d’une visibilité maximum en apparaissant sous la catégorie “Des offres exclusive” (première page de l’application) !</p>
                  <p>Toutes les marques ayant publié des “Offre exclusive Sharlock” apparaissent dans cette catégorie.</p>
                </>
                :
                <>
                  <p>Si la communication que vous souhaitez publier est une “Actualité” : cochez cette case.</p>
                  <p>Profitez d’une visibilité maximum en apparaissant sous la catégorie “Des actualités régulières” (première page de l’application) !</p>
                  <p>Les dernière marques ayant publié des “Actualités” apparaissent dans cette catégorie.</p>
                </>
              }
            </Box>
          </ClickAwayListener>
        </>
      </Modal>

      <Nav user={user} onPress={openAccountModal} />

      <div className="createOffer-newOffer">
        <h2 className="createOffer-mainTitle">Nouvelle communication</h2>
        <p className="createOffer-mainSubtitle">
          Remplissez les champs puis publiez votre communication. Vous pouvez vous aider
          de l'aperçu
        </p>
      </div>

      {!offerIsPublishing ?
        <div className="createOffer-wrapper">
          <div className="createOffer-content-wrapper">
            <div className="createOffer-modify-container">
              <>
                <label className="modify-form-label">
                  Type de communication<span className="asterisk">*</span>
                </label>
                <div className="createOffer-offerType-container">
                  <div className="createOffer-offerType-content">
                    <button
                      className="createOffer-offerType-btn"
                      style={{ backgroundColor: type === "Offre exclusive" ? "#2A7AF5" : "#E8E8E8" }}
                      onClick={() => type === "" || type === "Actualité" ? setType("Offre exclusive") : setType("")}
                    >
                      Offre exclusive

                    </button>
                    <MdInfoOutline className="createOffer-information-icon" onClick={(event) => handleIconClick(event, "Offre exclusive")} />

                  </div>

                  <div className="createOffer-offerType-content">
                    <button
                      className="createOffer-offerType-btn"
                      style={{ backgroundColor: type === "Actualité" ? "#2A7AF5" : "#E8E8E8" }}
                      onClick={() => type === "" || type === "Offre exclusive" ? setType("Actualité") : setType("")}
                    >
                      Actualité
                    </button>
                    <MdInfoOutline className="createOffer-information-icon" onClick={(event) => handleIconClick(event, "Actualité")} />
                  </div>

                </div>
                <form
                  className="createOffer-modify-form-container"
                  onSubmit={(e) => {
                    e.preventDefault();
                    onSubmitForm();
                  }}
                >
                  <div className="createOffer-modify-form-input-wrap">
                    <label className="modify-form-label">
                      Titre<span className="asterisk">*</span>
                    </label>
                    <input
                      className="modify-form-input"
                      type="text"
                      name="title"
                      value={title}
                      maxLength="50"
                      onChange={(e) => {
                        setTitle(e.currentTarget.value);
                      }}
                      required
                    />
                    <p className="modify-form-length">{title.length}/50</p>
                    <label className="modify-form-label">Description 1</label>
                    <textarea
                      className="modify-form-input-textarea"
                      name="description"
                      value={description}
                      maxLength="250"
                      onChange={(e) => {
                        setDescription(e.currentTarget.value);
                      }}
                    ></textarea>
                    <p className="modify-form-length">{description.length}/250</p>
                    <label className="modify-form-label">Description 2</label>
                    <textarea
                      className="modify-form-input-textarea"
                      name="description2"
                      value={description2}
                      maxLength="250"
                      onChange={(e) => {
                        setDescription2(e.currentTarget.value);
                      }}
                    ></textarea>
                    <p className="modify-form-length">
                      {description2.length}/250
                    </p>

                    <label className="modify-form-label">Date de fin</label>

                    <input
                      className={
                        date !== undefined && !dateError
                          ? "modify-form-input modify-date-input-has-value"
                          : dateError
                            ? "modify-date-error"
                            : "modify-form-input modify-date-input-placeholder"
                      }
                      type="date"
                      name="date"
                      onChange={(e) => {
                        transformDate(e.currentTarget.value);
                      }}
                    />
                    <label className="modify-form-label">
                      Lien vers l'annonce<span className="asterisk">*</span>
                    </label>
                    <input
                      className="modify-form-input"
                      type="text"
                      name="link"
                      value={link}
                      onChange={(e) => {
                        setLink(e.currentTarget.value);
                      }}
                      required
                    />
                    {link !== "" && regexUrl.test(link) === false ? (
                      <p className="error-msg">L'url saisi est invalide</p>
                    ) : null
                    }
                  </div>
                  <label className="modify-form-label-photo">
                    Photos<span className="asterisk">*</span>
                  </label>
                  <div className="modify-form-photos-container">
                    <div className="createOffer-modify-form-photos-row">
                      <div
                        className="modify-form-photo photos-1"
                        style={
                          file !== null
                            ? {
                              background: `url(${file.url})`,
                              backgroundPosition: "center",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "375px, 457px"
                            }
                            : null
                        }
                      >
                        <input
                          type="file"
                          name="image"
                          style={{ display: "none" }}
                          onChange={(event) => {
                            handleChange(event)
                          }}
                          onClick={(e) => {
                            e.target.value = null
                          }}
                          ref={inputFileRef1}
                          required
                          accept="image/png, image/gif, image/jpeg, .webp"
                        />
                        {file === null ? (
                          <>
                            <HiOutlinePlus
                              className="createOffer-modify-form-photo-file-icon"
                              onClick={() => {
                                handleClick(inputFileRef1);
                              }}
                            />
                            <p className="modify-form-photo-title">
                              Photo 1 (375x457)<span className="asterisk">*</span>
                            </p>
                          </>
                        ) : <div
                          className="modify-form-removePicture"
                          onClick={() => {
                            setFile(null);
                          }}
                        >
                          <IoClose size={20} />
                        </div>
                        }
                      </div>
                      <div
                        className="modify-form-photo photos-2"
                        style={
                          file2 !== null
                            ? {
                              background: `url(${file2.url})`,
                              backgroundPosition: "center",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "375px, 457px"
                            }
                            : null
                        }
                      >
                        <input
                          type="file"
                          name="image2"
                          style={{ display: "none" }}
                          onChange={(event) => {
                            handleChange(event)
                          }}
                          onClick={(e) => {
                            e.target.value = null
                          }}
                          ref={inputFileRef2}
                          accept="image/png, image/gif, image/jpeg, .webp"
                        />
                        {file2 === null ? (
                          <HiOutlinePlus
                            className="createOffer-modify-form-photo-file-icon"
                            onClick={() => {
                              handleClick(inputFileRef2);
                            }}
                          />
                        ) : null}
                        {file2 !== null ? (
                          <>
                            <div
                              className="modify-form-removePicture"
                              onClick={() => {
                                setFile2(null);
                              }}
                            >
                              <IoClose size={20} />
                            </div>
                          </>
                        ) : (
                          <p className="modify-form-photo-title">
                            Photo 2 (375x327)
                          </p>
                        )}
                      </div>
                      <div
                        className="modify-form-photo photos-3"
                        style={
                          file3 !== null
                            ? {
                              background: `url(${file3.url})`,
                              backgroundPosition: "center",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "375px, 457px"
                            }
                            : null
                        }
                      >
                        <input
                          type="file"
                          name="image3"
                          style={{ display: "none" }}
                          onChange={(event) => {
                            handleChange(event)
                          }}
                          onClick={(e) => {
                            e.target.value = null
                          }}
                          ref={inputFileRef3}
                          accept="image/png, image/gif, image/jpeg, .webp"
                        />
                        {file3 === null ? (
                          <HiOutlinePlus
                            className="createOffer-modify-form-photo-file-icon"
                            onClick={() => {
                              handleClick(inputFileRef3);
                            }}
                          />
                        ) : null}
                        {file3 !== null ? (
                          <>
                            <div
                              className="modify-form-removePicture"
                              onClick={() => {
                                setFile3(null);
                              }}
                            >
                              <IoClose size={20} />
                            </div>
                          </>
                        ) : (
                          <p className="modify-form-photo-title">
                            Photo 3 (375x327){" "}
                          </p>
                        )}
                      </div>
                      <div
                        className="modify-form-photo photos-4"
                        style={
                          file4 !== null
                            ? {
                              background: `url(${file4.url})`,
                              backgroundPosition: "center",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "375px, 457px"
                            }
                            : null
                        }
                      >
                        <input
                          type="file"
                          name="image4"
                          style={{ display: "none" }}
                          onChange={(event) => {
                            handleChange(event)
                          }}
                          onClick={(e) => {
                            e.target.value = null
                          }}
                          ref={inputFileRef4}
                          accept="image/png, image/gif, image/jpeg, .webp"
                        />
                        {file4 === null ? (
                          <HiOutlinePlus
                            className="createOffer-modify-form-photo-file-icon"
                            onClick={() => {
                              handleClick(inputFileRef4);
                            }}
                          />
                        ) : null}
                        {file4 !== null ? (
                          <>
                            <div
                              className="modify-form-removePicture"
                              onClick={() => {
                                setFile4(null);
                              }}
                            >
                              <IoClose size={20} />
                            </div>
                          </>
                        ) : (
                          <p className="modify-form-photo-title">
                            Photo 4 (375x327)
                          </p>
                        )}
                      </div>
                    </div>
                    {error !== null ? <p className="errorMsg">{error}</p> : null}
                    <div className="modify-form-submit-container">
                      {!disabled ? (
                        <input
                          className="modify-form-submit"
                          type="submit"
                          value="Publier la communication"
                          disabled={disabled}
                        />
                      ) : (
                        <input
                          className="modify-form-submit-disabled"
                          type="submit"
                          value="Publier la communication"
                          disabled={disabled}
                        />
                      )}
                    </div>
                  </div>
                </form>
              </>
            </div>

            <div className="createOffer-preview-iphone-container">
              <div className="preview-iphone">
                <p className="preview-iphone-clock">
                  {new Date().getHours() + ":"}
                  {new Date().getMinutes() < 10
                    ? "0" + new Date().getMinutes()
                    : new Date().getMinutes()}
                </p>
                <div className="preview-iphone-actions-wrapper">
                  <div className="preview-iphone-actions-btn-container">
                    <IoClose className="preview-iphone-actions-btn" color="red" />
                  </div>
                  <div className="preview-iphone-actions-btn-container">
                    <IoSearchOutline
                      className="preview-iphone-actions-btn"
                      color="#2A7AF5"
                    />
                  </div>
                  <div className="preview-iphone-actions-btn-container">
                    <IoCheckmark
                      className="preview-iphone-actions-btn"
                      color="#06DE42"
                    />
                  </div>
                </div>
                <div className="createOffer-preview-iphone-scrollable">
                  <div className="preview-iphone-scrollable-elements mt-10">
                    {file !== null ? (
                      <img
                        className="preview-iphone-scrollable-element-img"
                        src={file.url}
                        alt={file.name}
                      />
                    ) : (
                      <div
                        className="preview-iphone-scrollable-element-nopic"
                        style={{ background: "#F2DED0" }}
                      >
                        <p>Photo 1</p>
                      </div>
                    )}
                    <div className="preview-iphone-arraydown-btn-container">
                      <ImArrowDown
                        color="white"
                        className="preview-iphone-icon-arraydown"
                      />
                    </div>
                  </div>

                  <div className="preview-iphone-scrollable-elements">
                    <div className="row pt-20">
                      <img
                        src={user.logo}
                        alt={user.name}
                        className="preview-iphone-scrollable-element-logo"
                      />

                      <h3 className="preview-iphone-scrollable-element-partner">
                        {user.name}
                      </h3>
                    </div>
                    <p className="preview-iphone-scrollable-element-title">
                      {title !== "" ? title : "Titre"}
                    </p>
                    {description !== "" ? (
                      <p className="preview-iphone-scrollable-element-description">
                        {description}
                      </p>
                    ) : (
                      <div className="preview-iphone-scrollable-element-noDescription">
                        <p>Description 1</p>
                      </div>
                    )}

                    {file2 !== null ? (
                      <img
                        className="preview-iphone-scrollable-element-img2"
                        src={file2.url}
                        alt={file2.name}
                      />
                    ) : (
                      <div
                        className="preview-iphone-scrollable-element-nopic2"
                        style={{ background: "#F2EAB8" }}
                      >
                        <p>Photo 2</p>
                      </div>
                    )}
                    {description2 !== "" ? (
                      <p className="preview-iphone-scrollable-element-description">
                        {description2}
                      </p>
                    ) : (
                      <div className="preview-iphone-scrollable-element-noDescription">
                        <p>Description 2</p>
                      </div>
                    )}

                    {file3 !== null ? (
                      <img
                        className="preview-iphone-scrollable-element-img2"
                        src={file3.url}
                        alt={file3.name}
                      />
                    ) : (
                      <div
                        className="preview-iphone-scrollable-element-nopic2"
                        style={{ background: "#BEB8F2" }}
                      >
                        <p>Photo 3</p>
                      </div>
                    )}
                    {file4 !== null ? (
                      <img
                        className="preview-iphone-scrollable-element-img2"
                        src={file4.url}
                        alt={file4.name}
                      />
                    ) : (
                      <div
                        className="preview-iphone-scrollable-element-nopic2"
                        style={{ background: "#A9F2A0" }}
                      >
                        <p>Photo 4</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        :
        <div style={{ display: "flex", alignItems: "center", padding: 50 }}>
          <p style={{ fontFamily: "Figtree", fontSize: 18, marginRight: 10, marginTop: 5 }}>La communication est en cours de publication</p>
          <Dots color="#AA80F9" size={30} />
        </div>
      }

    </>
  );
};
export default CreateOffer;
