/*eslint-disable */
import './Admin.css'
import React, { useState } from 'react';
import Nav from "../../Nav"
import { useNavigate, useLocation } from "react-router-dom";
import { useUserAuth } from "../../../context/UserAuthContext";
import { AiFillCloseCircle } from "react-icons/ai";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { ClickAwayListener } from '@mui/base';
import axios from "axios"

const RemovePartner = () => {

    const { user, logOut } = useUserAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [partner, setPartner] = useState("")
    const params = location.state.partners;


    const [showProfilPanel, setShowProfilPanel] = useState(false);
    const dimension = {
        width: window.innerWidth,
        height: window.innerHeight,
    };

    const handleClose = () => { // close modal profil
        setShowProfilPanel(false);
    };

    const handleLogout = async () => { // logout user
        try {
            await logOut();
            navigate("/");
            setTimeout(() => {
                console.clear()
            }, 250);
        } catch (error) {
            alert("Une erreur est survenue lors de votre déconnexion");
        }
    };

    const openAccountModal = () => {
        setShowProfilPanel(true)
    }


    const handleSelectPartner = (partner) => {
        setPartner(partner);
    };

    const deletePartner = async () => {
        const response = await axios.post('https://europe-west1-sharlock-36504.cloudfunctions.net/deletePartner', { uid: partner.data.uid });
        console.log(response)
    }

    return (
        <>
            <Modal
                open={showProfilPanel}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                hideBackdrop={dimension.width > 768 ? true : false}
            >
                <>
                    <ClickAwayListener onClickAway={handleClose}>
                        <Box className={user.uid === "p5aTQsaMqPdnL7ynK7v9dJSdgH83" ? "myOffers-box-admin" : "myOffers-box"}>
                            <AiFillCloseCircle
                                className="myOffers-modal-close"
                                onClick={() => {
                                    handleClose();
                                }}
                            />
                            <div className="myOffers-modalContainer">
                                <div className="myOffers-modal-profilCircle">
                                    <p className="myOffers-modal-profiltext">
                                        {user.name !== undefined ? user.name[0] : null}
                                    </p>
                                </div>
                                <h2 className="myOffers-modal-title">
                                    {user.name !== undefined ? user.name : null}
                                </h2>
                            </div>
                            <div
                                className="myOffers-modal-param"
                                onClick={() => {
                                    navigate("/params");
                                }}
                            >
                                <p className="myOffers-modal-param-text">Paramètre du compte</p>
                            </div>

                            {user.uid === "p5aTQsaMqPdnL7ynK7v9dJSdgH83" &&
                                <>
                                    <div
                                        className="myOffers-modal-param"
                                        onClick={() => {
                                            navigate("/partners-management");
                                        }}
                                    >
                                        <p className="myOffers-modal-param-text">Gestion des partenaires</p>
                                    </div>

                                    <div
                                        className="myOffers-modal-param"
                                        onClick={() => {
                                            navigate("/promotions-management");
                                        }}
                                    >
                                        <p className="myOffers-modal-param-text">Gérer les promotions</p>
                                    </div>
                                </>
                            }

                            <div
                                onClick={() => {
                                    handleLogout();
                                }}
                                className="myOffers-modal-logoutBtn"
                            >
                                <p className="myOffers-modal-logoutText">Déconnexion</p>
                            </div>
                        </Box>
                    </ClickAwayListener>
                </>
            </Modal>

            <Nav user={user} onPress={openAccountModal} />

            {partner === "" ?
                <div className="selection-of-the-week-content" style={{ marginTop: 50, marginBottom: 50 }}>
                    <>
                        {params.length === 0 ?
                            <div className="selection-of-the-week-dots">
                                <Dots color="#2A7AF5" size={30} />
                            </div>
                            :
                            <div className="selection-of-the-week-partners-wrap">
                                {params.sort((a, b) =>
                                    a.data.name.localeCompare(b.data.name)
                                ).map((partner) => (
                                    console.log(partner),
                                    <div key={partner.id}>
                                        <img src={partner.data.logo} alt={`${partner.data.name} logo`} className="selection-of-the-week-partners-logo" />
                                        <p>{partner.data.name}</p>
                                        <button className="selection-of-the-week-remove-partners" onClick={() => handleSelectPartner(partner)}>
                                            Sélectionner
                                        </button>
                                    </div>
                                ))}
                            </div>
                        }
                    </>
                </div>

                :
                <div className="selection-of-the-week-content" style={{ marginTop: 100, marginBottom: 50 }}>

                    <div className="promotion">
                        <h3>Suppression du partenaire {partner.data.name}</h3>

                        <div className="promotion-existing-category">
                            <button onClick={deletePartner}>Confirmer la suppresion de {partner.data.name}</button>
                        </div>

                    </div>

                </div>
            }

        </>
    );

}

export default RemovePartner;
