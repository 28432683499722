/*eslint-disable */
import React, { useEffect, useState } from 'react';
import Nav from "../../Nav"
import { useNavigate } from "react-router-dom";
import { database } from "../../../firebase";
import { collection, query, getDocs } from "firebase/firestore";
import { useUserAuth } from "../../../context/UserAuthContext";
import { AiFillCloseCircle } from "react-icons/ai";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { ClickAwayListener } from '@mui/base';

const PartnersDashboard = () => {

    const { user, logOut } = useUserAuth();
    const navigate = useNavigate();
    const [partners, setPartners] = useState([]);
    const [showProfilPanel, setShowProfilPanel] = useState(false);
    const dimension = {
        width: window.innerWidth,
        height: window.innerHeight,
    };

    const handleClose = () => { // close modal profil
        setShowProfilPanel(false);
    };

    const handleLogout = async () => { // logout user
        try {
            await logOut();
            navigate("/");
            setTimeout(() => {
                console.clear()
            }, 250);
        } catch (error) {
            alert("Une erreur est survenue lors de votre déconnexion");
        }
    };

    const openAccountModal = () => {
        setShowProfilPanel(true)
    }

    // Load all partners promotions
    useEffect(() => {
        if (partners.length > 0) {
            return; // Data has already been fetched, no need to fetch again
        }

        const fetchData = async () => {
            try {
                const q = query(collection(database, 'partners'));
                const querySnapshot = await getDocs(q);
                const partnerList = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }));
                setPartners(partnerList);
            } catch (error) {
                // Handle any errors here
                console.error('Error fetching partners:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <>
            <Modal
                open={showProfilPanel}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                hideBackdrop={dimension.width > 768 ? true : false}
            >
                <>
                    <ClickAwayListener onClickAway={handleClose}>
                        <Box className={user.uid === "p5aTQsaMqPdnL7ynK7v9dJSdgH83" ? "myOffers-box-admin" : "myOffers-box"}>
                            <AiFillCloseCircle
                                className="myOffers-modal-close"
                                onClick={() => {
                                    handleClose();
                                }}
                            />
                            <div className="myOffers-modalContainer">
                                <div className="myOffers-modal-profilCircle">
                                    <p className="myOffers-modal-profiltext">
                                        {user.name !== undefined ? user.name[0] : null}
                                    </p>
                                </div>
                                <h2 className="myOffers-modal-title">
                                    {user.name !== undefined ? user.name : null}
                                </h2>
                            </div>
                            <div
                                className="myOffers-modal-param"
                                onClick={() => {
                                    navigate("/params");
                                }}
                            >
                                <p className="myOffers-modal-param-text">Paramètre du compte</p>
                            </div>

                            {user.uid === "p5aTQsaMqPdnL7ynK7v9dJSdgH83" &&
                                <>
                                    <div
                                        className="myOffers-modal-param"
                                        onClick={() => {
                                            navigate("/partners-management");
                                        }}
                                    >
                                        <p className="myOffers-modal-param-text">Gestion des partenaires</p>
                                    </div>

                                    <div
                                        className="myOffers-modal-param"
                                        onClick={() => {
                                            navigate("/promotions-management");
                                        }}
                                    >
                                        <p className="myOffers-modal-param-text">Gérer les promotions</p>
                                    </div>
                                </>
                            }

                            <div
                                onClick={() => {
                                    handleLogout();
                                }}
                                className="myOffers-modal-logoutBtn"
                            >
                                <p className="myOffers-modal-logoutText">Déconnexion</p>
                            </div>
                        </Box>
                    </ClickAwayListener>
                </>
            </Modal>

            <Nav user={user} onPress={openAccountModal} />

            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                <div
                    onClick={() => navigate("/register-partner-account")}
                    style={{ position: "relative", width: "30%", height: "50vh", margin: "0 20px", borderRadius: "10px", overflow: "hidden", boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)", cursor: "pointer" }}
                >
                    <img src="https://images.unsplash.com/photo-1549923746-c502d488b3ea?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fFBhcnRlbmFyaWF0c3xlbnwwfHwwfHx8MA%3D%3D" alt="" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                    <h2 style={{ width: "100%", textAlign: "center", fontFamily: "Geomatrix", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", color: "white", fontSize: "1.9rem", textShadow: "2px 2px 5px rgba(0, 0, 0, 0.5)" }}>Créer un partenaire</h2>
                </div>

                <div
                    onClick={() =>
                        navigate("/remove-partner", {
                            state: {
                                partners: partners,
                            }
                        }
                        )}
                    style={{ position: "relative", width: "30%", height: "50vh", margin: "0 20px", borderRadius: "10px", overflow: "hidden", boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)", cursor: "pointer" }}
                >
                    <img src="https://images.unsplash.com/photo-1575918049440-f757f5c83c8f?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjN8fHBvdWJlbGxlfGVufDB8fDB8fHww" alt="" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                    <h2 style={{ width: "100%", textAlign: "center", fontFamily: "Geomatrix", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", color: "white", fontSize: "1.9rem", textShadow: "2px 2px 5px rgba(0, 0, 0, 0.5)" }}>Supprimer un partenaire</h2>
                </div>


            </div>
        </>
    );
}

export default PartnersDashboard;
