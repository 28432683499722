import React, { useEffect, useState } from 'react';
import './Vote.css'
import Nav from "../Nav"
import { useNavigate } from "react-router-dom";
import { AiFillCloseCircle } from "react-icons/ai";
import { Icon } from '@iconify/react';
import { useUserAuth } from "../../context/UserAuthContext";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { getWeek } from 'date-fns';
import { ClickAwayListener } from '@mui/base';
import { database } from "../../firebase";
import { doc, collection, updateDoc, getDoc, getDocs } from "firebase/firestore";
import { Dots } from "react-activity";
import "react-activity/dist/library.css";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

/* COMPONENTS */
import CrispChat from "../CrispChat";

/* IMG*/
import chillImage from "../../assets/img/chill.png"

const VoteInfoPage = () => {
    const { user, logOut, updateUser } = useUserAuth();
    const navigate = useNavigate();

    const [participateVote, setParticipateVote] = useState(false);
    const [showParticipationMessage, setShowParticipationMessage] = useState(false)
    const [showRules, setShowRules] = useState(false)
    const [stats, setStats] = useState("")
    const [previousCampaigns, setPreviousCampaigns] = useState([])
    const [selectionDates, setSelectionDates] = useState("")
    const [notSelected, setNotSelected] = useState(false)
    const [rewardTitle, setRewardTitle] = useState('');
    const [process, setProcess] = useState(false)
    const [rewardDescription, setRewardDescription] = useState('');
    const [promotionCode, setPromotionCode] = useState('');
    const [showProfilPanel, setShowProfilPanel] = useState(false);
    const [editRewards, setEditRewards] = useState(false)
    const [description, setDescription] = useState("");
    const [title, setTitle] = useState("");
    const [expirationDate, setExpirationDate] = useState("");
    const [code, setCode] = useState("")

    const dimension = {
        width: window.innerWidth,
        height: window.innerHeight,
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setProcess(true)

        // Save reward data in DB
        const rewardData = {
            acceptVoteSystem: true,
            rewardOffer: {
                title: rewardTitle,
                text: rewardDescription,
                promoCode: promotionCode,
                expirationDate: expirationDate,
                picture: ""
            }
        };
        let newUser = { ...user }
        newUser.rewards = rewardData

        try {
            const partnerDocRef = doc(database, 'partners', user.uid);
            await updateDoc(partnerDocRef, { rewards: rewardData });
            updateUser(newUser)
            setProcess(false)
            displayConfirmationMessage()
        } catch (error) {
            alert("Une erreur est survenue lors de l'enregistrement de votre participation au vote");
        }

    };

    const handleClose = () => { // close modal profil
        setShowProfilPanel(false);
    };

    const displayConfirmationMessage = () => {
        setShowParticipationMessage(true)
        setTimeout(() => {
            setShowParticipationMessage(false)
        }, 2500);
    }

    const handleLogout = async () => { // logout user
        try {
            await logOut();
            navigate("/");
            setTimeout(() => {
                console.clear()
            }, 250);
        } catch (error) {
            alert("Une erreur est survenue lors de votre déconnexion");
        }
    };

    const openAccountModal = () => {
        setShowProfilPanel(true)
    }

    const getMondayDateForWeek = (weekNumber, year) => {
        const date = new Date(year, 0, 1);
        const day = date.getDay();
        const diff = (day <= 1 ? 1 - day : 8 - day); // Adjusting the difference to Monday
        const mondayDate = new Date(date.setDate(date.getDate() + (weekNumber - 1) * 7 + diff));
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        const mondayDateString = mondayDate.toLocaleDateString('fr-FR', options);

        return mondayDateString;
    };

    const getPreviousDateForWeek = (item) => {
        // Parse the date components from the selectionDate
        const [day, month, year] = item.split("/").map(Number);

        // Create a new Date object based on the given date
        const date = new Date(year, month - 1, day);

        // Subtract 7 days
        date.setDate(date.getDate() - 7);

        // Extract the updated day, month, and year
        const updatedDay = date.getDate();
        const updatedMonth = (date.getMonth() + 1).toString().padStart(2, '0');
        const updatedYear = date.getFullYear();

        // Format the updated date string
        return `${updatedDay}/${updatedMonth}/${updatedYear}`
    }

    const fetchStatsOfVote = async (week, year) => {
        const docWeekName = `selection_week_${week}_${year}`;

        const voteDocRef = doc(database, "votes", docWeekName);
        const voteDocSnap = await getDoc(voteDocRef);

        if (voteDocSnap.exists()) {
            const voteDocData = voteDocSnap.data();
            const selectedPartners = voteDocData.selectedPartners;
            const totalParticipation = voteDocData.totalVotes;

            await Promise.all(
                selectedPartners.map(async (element) => {
                    const subDocRef = doc(
                        database,
                        "votes",
                        docWeekName,
                        element.id,
                        element.id
                    );
                    const subDocSnap = await getDoc(subDocRef);
                    if (subDocSnap.exists()) {
                        element.data.totalVotes = subDocSnap.data().totalVotes;
                    }
                })
            );
            selectedPartners.sort((a, b) => b.data.totalVotes - a.data.totalVotes)
            const index = selectedPartners.findIndex((partner) => partner.id === user.uid)

            setStats({ totalParticipation: totalParticipation, votes: selectedPartners[index].data.totalVotes, position: index })
            setProcess(false)
        }

    }

    const fetchPreviousCampaigns = async () => {
        const listCampaigns = [];

        const votesCollectionRef = collection(database, "partners", user.uid, "votes");
        const votesQuerySnapshot = await getDocs(votesCollectionRef);

        await Promise.all(
            votesQuerySnapshot.docs.map(async (element) => {
                const voteDocRef = doc(votesCollectionRef, element.id);
                const voteDocSnap = await getDoc(voteDocRef);

                if (voteDocSnap.exists()) {
                    listCampaigns.push(voteDocSnap.data());
                }
            })
        );

        listCampaigns.sort((a, b) => (b.timestamp - a.timestamp))
        setPreviousCampaigns(listCampaigns);
    };


    const fetchRandomImg = () => {
        const randomInteger = Math.floor(Math.random() * 4);
        switch (randomInteger) {
            case 0:
                return user.picture
            case 1:
                return user.picture1
            case 2:
                return user.picture2
            case 3:
                return user.picture3

            default: return user.picture
        }
    }


    const saveRewards = async (event) => {

        event.preventDefault();
        setProcess(true)

        // Save reward data in DB
        const rewardData = {
            acceptVoteSystem: true,
            rewardOffer: {
                title: title,
                text: description,
                promoCode: code,
                expirationDate: expirationDate,
                picture: ""
            }
        };
        let newUser = { ...user }
        newUser.rewards = rewardData

        try {
            const partnerDocRef = doc(database, 'partners', user.uid);
            await updateDoc(partnerDocRef, { rewards: rewardData });
            const selectionDocRef = doc(database, 'votes', `selection_week_${user.votePreselection.weekNumber}_${user.votePreselection.year}`);
            const selectionSnapshot = await getDoc(selectionDocRef);
            if (selectionSnapshot.exists()) {
                const selectedPartners = selectionSnapshot.data().selectedPartners;

                // Find the index of the partner with matching user.uid
                const index = selectedPartners.findIndex(partner => partner.id === user.uid);

                if (index !== -1) {
                    // Create a new array with the updated partner's data
                    const updatedPartners = [...selectedPartners];
                    updatedPartners[index] = {
                        ...updatedPartners[index],
                        data: {
                            ...updatedPartners[index].data,
                            rewards: rewardData
                        }
                    };

                    // Update the Firestore document with the updated array
                    await updateDoc(selectionDocRef, { selectedPartners: updatedPartners });
                } else {
                    console.log('Partner not found in the selected partners array.');
                }
            } else {
                console.log(`Document 'selection_week_${user.votePreselection.weekNumber}_${user.votePreselection.year}' does not exist.`);
            }

            updateUser(newUser)
            setProcess(false)
            displayConfirmationMessage()
            setEditRewards(false)

        } catch (error) {
            alert("Une erreur est survenue lors de l'enregistrement de votre participation au vote");
        }


    }

    const handleDateChange = (date) => {
        setExpirationDate(date);
    };

    useEffect(() => {

        if (user.rewards.acceptVoteSystem === false) {
            if (user.votePreselection.isSelected === false) {
                setShowRules(true);
                setNotSelected(true)
            }
            if (user.votePreselection.isSelected === true) {
                //Using the function to get the Monday date of specific week number & previous week
                const selectionDate = getMondayDateForWeek(user.votePreselection.weekNumber, user.votePreselection.year);
                const limitDate = getPreviousDateForWeek(selectionDate)

                setSelectionDates({ limitDate: limitDate, selectionDate: selectionDate })
                setParticipateVote(true);
            }

        }

        if (user.rewards.acceptVoteSystem === true) {
            setProcess(true)
            if (user.votePreselection.isSelected === false) {
                fetchPreviousCampaigns()
                setShowRules(true);
                setNotSelected(true)
                setProcess(false)
            }

            if (user.votePreselection.isSelected === true) {
                //Using the function to get the Monday date of specific week number & previous week
                const selectionDate = getMondayDateForWeek(user.votePreselection.weekNumber, user.votePreselection.year);
                const limitDate = getPreviousDateForWeek(selectionDate)

                // Check if we are in the current week of selection vote
                const currentDate = new Date();
                const currentWeekNumber = getWeek(currentDate);
                const currentYear = currentDate.getFullYear()

                if (currentWeekNumber == user.votePreselection.weekNumber) {
                    // In this case we fetch current position, votes count and total participation
                    fetchStatsOfVote(currentWeekNumber, currentYear)
                }
                if (currentWeekNumber != user.votePreselection.weekNumber) {
                    setProcess(false)
                }

                setSelectionDates({ limitDate: limitDate, selectionDate: selectionDate })
                setParticipateVote(true);
            }
        }
    }, []);

    return (
        <>
            <CrispChat user={user} />

            {/* Profil */}
            <Modal
                open={showProfilPanel}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                hideBackdrop={dimension.width > 768 ? true : false}
            >
                <>
                    <ClickAwayListener onClickAway={handleClose}>
                        <Box className={user.uid === "p5aTQsaMqPdnL7ynK7v9dJSdgH83" ? "myOffers-box-admin" : "myOffers-box"}>
                            <AiFillCloseCircle
                                className="myOffers-modal-close"
                                onClick={() => {
                                    handleClose();
                                }}
                            />
                            <div className="myOffers-modalContainer">
                                <div className="myOffers-modal-profilCircle">
                                    <p className="myOffers-modal-profiltext">
                                        {user.name !== undefined ? user.name[0] : null}
                                    </p>
                                </div>
                                <h2 className="myOffers-modal-title">
                                    {user.name !== undefined ? user.name : null}
                                </h2>
                            </div>
                            <div
                                className="myOffers-modal-param"
                                onClick={() => {
                                    navigate("/params");
                                }}
                            >
                                <p className="myOffers-modal-param-text">Paramètre du compte</p>
                            </div>

                            {user.uid === "p5aTQsaMqPdnL7ynK7v9dJSdgH83" &&
                                <>
                                    <div
                                        className="myOffers-modal-param"
                                        onClick={() => {
                                            navigate("/partners-management");
                                        }}
                                    >
                                        <p className="myOffers-modal-param-text">Gestion des partenaires</p>
                                    </div>
    
                                    <div
                                        className="myOffers-modal-param"
                                        onClick={() => {
                                            navigate("/promotions-management");
                                        }}
                                    >
                                        <p className="myOffers-modal-param-text">Gérer les promotions</p>
                                    </div>
                                </>
                            }

                            <div
                                onClick={() => {
                                    handleLogout();
                                }}
                                className="myOffers-modal-logoutBtn"
                            >
                                <p className="myOffers-modal-logoutText">Déconnexion</p>
                            </div>
                        </Box>
                    </ClickAwayListener>
                </>
            </Modal>

            {/* Participation Message*/}
            <Modal
                open={showParticipationMessage}
                onClose={() => setShowParticipationMessage(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <>
                    <ClickAwayListener onClickAway={() => setShowParticipationMessage(false)}>
                        <Box className="vote-modal-box-participation-message">
                            <p>Votre choix a bien été enregistré</p>
                        </Box>
                    </ClickAwayListener>
                </>
            </Modal>

            {/* Not selected */}
            <Modal
                open={notSelected}
                onClose={() => setNotSelected(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <>
                    <ClickAwayListener onClickAway={() => setNotSelected(false)}>
                        <Box className="vote-modal-box-not-selected">
                            <img src={chillImage} alt="chill" className="vote-modal-not-selected-img" />
                            <p className="vote-modal-not-selected-text">Vous n’avez pas été sélectionné pour le vote. Rendez-vous la semaine prochaine.</p>
                            <div className="vote-modal-not-selected-row">
                                <button className="vote-btn2"
                                    onClick={() => {
                                        window.$crisp.push(['do', 'chat:open']);
                                        window.$crisp.push(['do', 'message:send', ['text', 'Bonjour l’équipe Sharlock, nous souhaitons participer à une semaine de vote prochainement.']]);
                                        setNotSelected(false)
                                    }}
                                >
                                    Faire une demande de Participation
                                </button>

                                <button className="vote-btn"
                                    onClick={() => {
                                        setShowRules(true)
                                        setNotSelected(false)
                                    }}
                                >
                                    Voir les règles du vote
                                </button>
                            </div>
                        </Box>
                    </ClickAwayListener>
                </>
            </Modal>

            <Nav user={user} onPress={openAccountModal} />

            <div className="vote-header">
                <h1 className="vote-header-title">Mes votes</h1>
                <p className="vote-header-subtitle">
                    Tout ce que vous devez savoir sur les votes
                </p>
            </div>


            <div className="vote-rules">
                {showRules ?
                    <>
                        <h2>Règles du système de vote</h2>
                        <ul>
                            <li>Chaque semaine, cinq PARTENAIRES sont mis en avant pour participer au “Vote”.</li>
                            <li>Chaque utilisateur peut voter pour le PARTENAIRE de son choix</li>
                            <li>Tous les utilisateurs qui ont voté pour un PARTENAIRE gagnent le CODE PROMOTIONNEL associé à ce PARTENAIRE.</li>
                            <li>Chaque utilisateur a connaissance du montant du CODE PROMOTIONNEL au moment du vote.</li>
                            <li>Chaque utilisateur est invité à partager son vote avec son entourage.</li>
                            <li>Le but de cette fonctionnalité est de vous apporter de la visibilité et de générer des ventes sur votre site web.</li>
                            <li>Partagez votre participation au “Vote” sur vos réseaux/newsletters pour accumuler plus de vote, plus de ventes et (re)engager votre communauté.</li>
                            <li>Les PARTENAIRES qui finissent dans le top 3 seront mis en avant une semaine de plus sur l’application dans la catégorie “Le top 3 de la semaine dernière”.</li>
                        </ul>
                        <p className="vote-rules-glhf">Merci d’avance pour votre participation et bonne chance !</p>

                        <button className="vote-btn" style={{ marginTop: 30, marginBottom: 50 }}
                            onClick={() => {
                                window.$crisp.push(['do', 'chat:open'])
                                window.$crisp.push(['do', 'message:send', ['text', 'Bonjour l’équipe Sharlock, nous souhaitons participer à une semaine de vote prochainement.']]);
                            }}
                        >Faire une demande de participation
                        </button>

                    </>
                    : null}

                {user.rewards.acceptVoteSystem === true && user.votePreselection.isSelected === false ?
                    <>
                        {previousCampaigns.length > 0 && !process ?
                            <>
                                <h2>Mes anciennes campagnes</h2>

                                <div className="vote-previous-campaigns-wrap">
                                    {previousCampaigns.map((campaign, index) => (
                                        <div key={index} className="vote-previous-campaigns"
                                            style={{
                                                background: `url(${fetchRandomImg()})`,
                                                backgroundSize: "cover"
                                            }}>
                                            <div className="vote-previous-campaigns-content">
                                                <h3>Campagne du {campaign.date}</h3>
                                                <div className="vote-previous-campaigns-row">
                                                    <Icon icon="solar:medal-ribbon-star-bold" className="vote-previous-campaigns-icon" />
                                                    <p className="vote-previous-campaigns-text-bold">{campaign.position === 1 ? "1ère" : campaign.position + "ème"} place</p>
                                                </div>
                                                <div className="vote-previous-campaigns-row2">
                                                    <Icon icon="fluent:thumb-like-20-filled" color="#F5B12A" className="vote-previous-campaigns-icon" />
                                                    <div className="vote-previous-campaigns-col">
                                                        <p className="vote-previous-campaigns-text-bold">{campaign.votes} votes</p>
                                                        <p className="vote-previous-campaigns-text">
                                                            <span className="vote-previous-campaigns-span">
                                                                {Math.round((campaign.votes / campaign.totalParticipation) * 100)}%</span> des votes sur {campaign.totalParticipation} participants
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                            </>
                            : process ?
                                <Dots color="#2A7AF5" size={30} />
                                : null
                        }
                    </>
                    :
                    null
                }

                {participateVote ?
                    <>
                        {user.rewards.acceptVoteSystem === false && participateVote ?
                            <div style={{ paddingBottom: 90 }}>
                                <h2 className="vote-participation-title">Félicitations, vous avez été sélectionné pour les votes de la semaine du {selectionDates.selectionDate} !</h2>
                                <p className="vote-participation-warning">Vous devez absolument remplir les informations sur la récompense avant le {selectionDates.limitDate}</p>
                                <div className="vote-info-page">
                                    <form className="reward-form" onSubmit={handleSubmit}>
                                        <h3>Informations sur la récompense</h3>
                                        <div className="reward-form-container">
                                            <div className="reward-form-left-col">
                                                <div className="form-group">
                                                    <label className="form-label">Titre du code promo</label>
                                                    <input className="form-input" type="text" value={rewardTitle} placeholder="-20% sur tout le site" onChange={(e) => setRewardTitle(e.target.value)} maxLength={30} />
                                                    <p className="modify-form-length">{rewardTitle.length}/30</p>
                                                </div>

                                                <div className="form-group">
                                                    <label className="form-label">Description du code promo</label>
                                                    <textarea className="form-textarea" value={rewardDescription} placeholder="Profitez de -20% sur tout le site jusqu'au 15/12/2023" onChange={(e) => setRewardDescription(e.target.value)} maxLength={70} />
                                                    <p className="modify-form-length">{rewardDescription.length}/70</p>
                                                </div>

                                                <div className="form-group">
                                                    <label className="form-label">Code promo</label>
                                                    <input className="form-input" type="text" placeholder="SHARLOCK20" value={promotionCode} onChange={(e) => setPromotionCode(e.target.value)} />
                                                </div>

                                                <div className="form-group">
                                                    <label className="form-label">Date de fin de validité du code promo</label>
                                                    <DatePicker
                                                        selected={expirationDate}
                                                        onChange={handleDateChange}
                                                        dateFormat="dd/MM/yyyy"
                                                        minDate={new Date()} // Only allow selecting dates from today onwards
                                                        placeholderText="Date de fin"
                                                        className="form-input"
                                                    />
                                                </div>
                                            </div>


                                        </div>

                                        {!process && rewardTitle !== "" && rewardDescription !== "" && expirationDate !== "" && promotionCode !== "" ?
                                            <button className="submit-button" type="submit">Enregistrer la récompense</button>
                                            : process ?

                                                <div className="submit-dots">
                                                    <Dots color="white" size={30} />
                                                </div>

                                                :
                                                <button disabled={true} className="submit-button-disable">Enregistrer la récompense</button>
                                        }

                                    </form>
                                </div>
                                <div className="vote-stats">
                                    <h3>Les statistiques de mon vote</h3>
                                    <div className="vote-stat-content">
                                        <div className="vote-stats-card">
                                            <p className="vote-stats-card-title">Classement provisoire</p>
                                            <p className="vote-stats-card-number">-</p>
                                        </div>

                                        <div className="vote-stats-card">
                                            <p className="vote-stats-card-title">Nombre de vote</p>
                                            <p className="vote-stats-card-number">-/- <span>participants</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            : user.rewards.acceptVoteSystem === true && participateVote && !editRewards ?

                                <>
                                    <h2 className="vote-participation-title">Félicitations, vous avez été sélectionné pour les votes de la semaine du {selectionDates.selectionDate} !</h2>

                                    <div className="vote-info-page">
                                        <h3>Informations sur la récompense</h3>
                                        <Icon icon="icon-park-solid:check-one" color="#27E88B" className="vote-info-icon-check" />
                                        <div className="vote-info-reward-card">
                                            <div className="vote-info-reward-rightcol">
                                                <p className="vote-info-reward-title">{user.rewards.rewardOffer.title}</p>
                                                <p className="vote-info-reward-text">{user.rewards.rewardOffer.text}</p>
                                                <p className="vote-info-reward-text">{user.rewards.rewardOffer.promoCode}</p>
                                                {/* <p className="vote-info-reward-text">{new Date(user.rewards.rewardOffer.expirationDate.seconds * 1000).toLocaleDateString()}</p> */}
                                            </div>
                                        </div>
                                        <button className="vote-info-reward-edit-button" onClick={() => setEditRewards(true)}>Modifier</button>
                                    </div>

                                    <div className="vote-stats">
                                        <h3>Les statistiques de mon vote</h3>
                                        <div className="vote-stat-content">
                                            <div className="vote-stats-card">
                                                <p className="vote-stats-card-title">Classement provisoire</p>
                                                {stats !== "" ?
                                                    <p className="vote-stats-card-number">{stats.position + 1 === 1 ? "1er" : stats.position + 1 + "ème"}</p>
                                                    : process ?
                                                        <Dots color="black" size={20} className="vote-stats-card-number" />
                                                        :
                                                        <p className="vote-stats-card-number">-</p>
                                                }
                                            </div>

                                            <div className="vote-stats-card">
                                                <p className="vote-stats-card-title">Nombre de votes</p>
                                                {stats !== "" && !process ?
                                                    <p className="vote-stats-card-number">{stats.votes}/{stats.totalParticipation}<span> participants</span></p>
                                                    : process ?
                                                        <Dots color="black" size={20} className="vote-stats-card-number" />
                                                        : <p className="vote-stats-card-number">-/-<span> participants</span></p>
                                                }
                                            </div>
                                        </div>

                                        <div className="vote-stat-content">
                                            <div className="vote-stats-card">
                                                <p className="vote-stats-card-title">Nombre de visites</p>
                                                <p className="vote-stats-card-number">{user.votePreselection.visits}</p>
                                            </div>

                                        </div>
                                    </div>
                                </>

                                : user.rewards.acceptVoteSystem === true && participateVote && editRewards ?
                                    <>
                                        <h2 className="vote-participation-title">Félicitations, vous avez été sélectionné pour les votes de la semaine du {selectionDates.selectionDate} !</h2>

                                        <div className="vote-info-page">
                                            <h3>Informations sur la récompense</h3>

                                            <div className="vote-info-reward-card">
                                                <div className="vote-info-reward-rightcol">

                                                    <div className="vote-info-reward-div-content">
                                                        <input className="vote-info-reward-input-title" type="text" placeholder="Titre" value={title} onChange={(e) => setTitle(e.target.value)} maxLength={30} />
                                                        <span className="vote-field-length">{title.length}/30</span>
                                                    </div>

                                                    <div className="vote-info-reward-div-content">
                                                        <textarea type="text" placeholder="Description" className="vote-info-reward-input-text" value={description} onChange={(e) => setDescription(e.target.value)} maxLength={70}></textarea>
                                                        <span className="vote-field-length">{description.length}/70</span>
                                                    </div>

                                                    <div className="vote-info-reward-div-content">
                                                        <input className="vote-info-reward-input-title" type="text" placeholder="Code promo" value={code} onChange={(e) => setCode(e.target.value)} />
                                                    </div>

                                                    <div className="vote-info-reward-div-content">
                                                        <DatePicker
                                                            selected={expirationDate}
                                                            onChange={handleDateChange}
                                                            dateFormat="dd/MM/yyyy"
                                                            minDate={new Date()} // Only allow selecting dates from today onwards
                                                            placeholderText="Date de fin"
                                                            className="vote-info-reward-input-title"
                                                        />
                                                    </div>


                                                </div>
                                                {title !== "" && description !== "" && code !== "" && expirationDate !== "" ?
                                                    <button className="vote-info-reward-edit-button" onClick={saveRewards}>Enregistrer</button>
                                                    :
                                                    <button className="vote-info-reward-edit-button-disable">Enregistrer</button>
                                                }
                                            </div>

                                        </div>

                                        <div className="vote-stats">
                                            <h3>Les statistiques de mon vote</h3>
                                            <div className="vote-stat-content">
                                                <div className="vote-stats-card">
                                                    <p className="vote-stats-card-title">Classement provisoire</p>
                                                    {stats !== "" ?
                                                        <p className="vote-stats-card-number">{stats.position + 1 === 1 ? "1er" : stats.position + 1 + "ème"}</p>
                                                        : process ?
                                                            <Dots color="black" size={20} className="vote-stats-card-number" />
                                                            :
                                                            <p className="vote-stats-card-number">-</p>
                                                    }
                                                </div>

                                                <div className="vote-stats-card">
                                                    <p className="vote-stats-card-title">Nombre de votes</p>
                                                    {stats !== "" && !process ?
                                                        <p className="vote-stats-card-number">{stats.votes}/{stats.totalParticipation}<span> participants</span></p>
                                                        : process ?
                                                            <Dots color="black" size={20} className="vote-stats-card-number" />
                                                            : <p className="vote-stats-card-number">-/-<span> participants</span></p>
                                                    }
                                                </div>
                                            </div>

                                            <div className="vote-stat-content">
                                                <div className="vote-stats-card">
                                                    <p className="vote-stats-card-title">Nombre de visites</p>
                                                    <p className="vote-stats-card-number">{user.votePreselection.visits}</p>
                                                </div>

                                            </div>
                                        </div>
                                    </>
                                    :
                                    null

                        }
                    </>

                    :
                    null}
            </div>

        </>
    );
};

export default VoteInfoPage;
