/*eslint-disable */
import "./Modify.css";
import React, { useRef, useEffect, useState } from "react";
import { HiOutlinePlus } from "react-icons/hi";
import { AiFillCloseCircle, AiOutlineWarning } from "react-icons/ai";
import { ImArrowDown } from "react-icons/im";
import { IoClose, IoCheckmark, IoSearchOutline } from "react-icons/io5";
import { useUserAuth } from "../../context/UserAuthContext";
import { storage, database, auth } from "../../firebase";
import { collection, doc, updateDoc, deleteDoc, getDocs } from "firebase/firestore";
import { ref, uploadBytes, getStorage, deleteObject, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Dots } from "react-activity";
import "react-activity/dist/library.css";
import { Helmet } from "react-helmet";

const Modify = (items) => {
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const [isUploading, setIsUploading] = useState(false)
  const [isDeleting, setisDeleting] = useState(false)
  const [item, setItem] = useState("");
  const [visibleDeleteOffer, setVisibleDeleteOffer] = useState(false);
  const [description, setDescription] = useState(items.item.data.description);
  const [description2, setDescription2] = useState(items.item.data.description2);
  const [title, setTitle] = useState(items.item.data.name);
  const [file, setFile] = useState(null);
  const [file2, setFile2] = useState(null);
  const [file3, setFile3] = useState(null);
  const [file4, setFile4] = useState(null);
  const [date, setDate] = useState();
  const [calendarDate, setCalendarDate] = useState()
  const [link, setLink] = useState(items.item.data.url);
  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState(null);
  const [urlError, setUrlError] = useState(null);
  const [dateError, setDateError] = useState(null);
  const inputFileRef1 = useRef(null);
  const inputFileRef2 = useRef(null);
  const inputFileRef3 = useRef(null);
  const inputFileRef4 = useRef(null);
  const { user } = useUserAuth();
  const MAX_FILE_SIZE = 5000000;

  const handleClick = (ref) => {  // create a useRef of the file click
    ref.current.click();
  };

  const transformDate = (date) => { // modify format of date
    setDate(new Date(date));
  };

  const handleChange = (e) => {
    const { name, files } = e.currentTarget;

    const allowedTypes = ["image/png", "image/jpeg", "image/webp"];
    const { type, size } = files[0];

    if (!allowedTypes.includes(type)) {
      alert("Vous devez sélectionner un fichier de type image (jpg/jpeg/png/webp)");
      return;
    }

    if (size >= MAX_FILE_SIZE) {
      setError("Le fichier est trop volumineux");
      return;
    }

    setError(null);

    const file = files[0];
    file.url = URL.createObjectURL(files[0]);

    switch (name) {
      case "image":
        setFile(file);
        break;
      case "image2":
        setFile2(file);
        break;
      case "image3":
        setFile3(file);
        break;
      case "image4":
        setFile4(file);
        break;
      default:
        break;
    }
  };

  const onSubmitForm = async () => { // if the offer is valid and can be sent

    setIsUploading(true)
    let itemBis = { ...item }
    const userData = auth.currentUser;
    const itemRef = doc(database, "partners", userData.uid);
    const colRef = collection(itemRef, "offers");
    const itemDoc = doc(colRef, item.item.id);
    const imageRef1 = ref(storage, `${user.uid}/images/${item.item.id}/${user.uid}_picture1`);
    const imageRef2 = ref(storage, `${user.uid}/images/${item.item.id}/${user.uid}_picture2`);
    const imageRef3 = ref(storage, `${user.uid}/images/${item.item.id}/${user.uid}_picture3`);
    const imageRef4 = ref(storage, `${user.uid}/images/${item.item.id}/${user.uid}_picture4`);

    try {
      await updateDoc(itemDoc, {
        date: date !== undefined ? date : item.item.data.date,
        description:
          description !== item.item.data.description ? description : item.item.data.description,
        description2:
          description2 !== item.item.data.description2 ? description2 : item.item.data.description2,
        name: title !== item.item.data.name ? title : item.item.data.name,
        url: link !== item.item.data.url ? link : item.item.data.url,
        picture: file === null && item.item.data.picture !== "" ? item.item.data.picture : "",
        picture2: file2 === null && item.item.data.picture2 !== "" ? item.item.data.picture2 : "",
        picture3: file3 === null && item.item.data.picture3 !== "" ? item.item.data.picture3 : "",
        picture4: file4 === null && item.item.data.picture4 !== "" ? item.item.data.picture4 : "",
      });

      itemBis.item.data.name = title
      itemBis.item.data.description = description
      itemBis.item.data.description2 = description2
      itemBis.item.data.url = link

    } catch (error) { }
    if (file === null && file2 === null && file3 === null && file4 === null) {
      setItem(itemBis)
      setIsUploading(false)
      setTimeout(() => {
        alert("La communication a été modifié")
      }, 250);

    }
    if (file !== null) {

      uploadBytes(imageRef1, file)
        .then(() => {
          getDownloadURL(imageRef1)
            .then((url) => {
              try {
                updateDoc(itemDoc, {
                  picture: url,
                });
                if (file2 === null && file3 === null && file4 === null) {
                  itemBis.item.data.picture = url
                  setItem(itemBis)
                  setIsUploading(false)
                  setTimeout(() => {
                    alert("La communication a été modifié")
                  }, 250);
                }
              } catch (error) { }
            })
            .catch((error) => { });
        })
        .catch((error) => { });
    }
    if (file2 !== null) {
      uploadBytes(imageRef2, file2)
        .then(() => {
          getDownloadURL(
            ref(imageRef2)
          )
            .then((url2) => {
              try {
                updateDoc(itemDoc, {
                  picture2: url2,
                });
                if (file3 === null && file4 === null) {
                  itemBis.item.data.picture2 = url2
                  setItem(itemBis)
                  setIsUploading(false)
                  setTimeout(() => {
                    alert("La communication a été modifié")
                  }, 250);
                }
              } catch (error) { }
            })
            .catch((error) => { });
        })
        .catch((error) => { });
    }
    if (file3 !== null) {
      uploadBytes(imageRef3, file3)
        .then(() => {
          getDownloadURL(
            ref(imageRef3)
          )
            .then((url3) => {
              try {
                updateDoc(itemDoc, {
                  picture3: url3,
                });
                if (file4 === null) {
                  itemBis.item.data.picture3 = url3
                  setItem(itemBis)
                  setIsUploading(false)
                  setTimeout(() => {
                    alert("La communication a été modifié")
                  }, 250);
                }
              } catch (error) { }
            })
            .catch((error) => { });
        })
        .catch((error) => { });
    }
    if (file4 !== null) {
      uploadBytes(imageRef4, file4)
        .then(() => {
          getDownloadURL(imageRef4)
            .then((url4) => {
              try {
                updateDoc(itemDoc, {
                  picture4: url4,
                });
                itemBis.item.data.picture4 = url4
                setItem(itemBis)
                setIsUploading(false)
                setTimeout(() => {
                  alert("La communication a été modifié")
                }, 250);
              } catch (error) { }
            })
            .catch((error) => { });
        })
        .catch((error) => { });
    }

  };

  const handleClose = () => { // Close the modal profil
    setVisibleDeleteOffer(false);
  };

  const deleteOffer = () => { // Remove the offer from DB
    setisDeleting(true)
    const storage = getStorage();
    const arr = []
    arr.push(item.item.data.picture, item.item.data.picture2, item.item.data.picture3, item.item.data.picture4)
    arr.map(async (picture, index) => {

      if (picture !== "") {
        // Delete the files
        try {
          await deleteObject(ref(storage, `${user.uid}/images/${item.item.id}/${user.uid}_picture${index + 1}`))
        } catch (error) {
          //alert("Une erreur s'est produite lors de la suppresion des images de la communication")
        }
      }

      if (index === arr.length - 1) {
        // Once finished

        const collectionRef = collection(database, "users");
        getDocs(collectionRef)
          .then((snapshot) => {
            let usersList = []
            snapshot.docs.forEach((doc) => {
              usersList.push({ ...doc.data() })
            })
            usersList.forEach((userOfList) => {
              const itemReference = doc(database, "users", userOfList.uid);
              const colReference = collection(itemReference, "savedOffers");
              getDocs(colReference)
                .then((querySnapshot) => {
                  querySnapshot.docs.forEach((savedOffer) => {
                    if (savedOffer.data().id === item.item.id) {
                      const itemDoc = doc(colReference, item.item.id);
                      deleteDoc(itemDoc)
                        .then(() => {
                          // item deleted
                        })
                        .catch((error) => { });
                    }
                  })
                })
              const itemRef = doc(database, "partners", user.uid);
              const colRef = collection(itemRef, "offers");
              const itemDoc = doc(colRef, item.item.id);

              deleteDoc(itemDoc)
                .then(() => {
                  setVisibleDeleteOffer(false);
                  navigate("/home");
                })
                .catch((error) => { alert("Une erreur s'est produite lors de la suppresion de votre communication") });
            })
          })

      };
    }
    )
  }

  useEffect(() => {
    let mounted = true;
    item === "" && setItem(items)
    setLoaded(true)

    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    let mounted = true
    if (loaded) {
      const regexUrl = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&\/=]*)$/
      if (
        title === item.item.data.name &&
        description === item.item.data.description &&
        description2 === item.item.data.description2 &&
        date === undefined &&
        link === item.item.data.url &&
        file === null &&
        file2 === null &&
        file3 === null &&
        file4 === null
      ) {
        setDisabled(true);
      } else if (title === "" || link === "" || item.item.data.picture === "" && file === null) {
        setDisabled(true);
      }
      else {
        setDisabled(false);
        if (link !== "") {
          if (regexUrl.test(link) === false) {
            setUrlError("L'url saisie est invalide");
            setDisabled(true);
          } else {
            setUrlError(null);
            setDisabled(false);
          }
        }

        if (date !== undefined) {
          if (isNaN(date.getTime())) {
            setDateError(true);
            setDisabled(true);
          } else {
            setDateError(false);
            setDisabled(false);
          }
        }
      }
    }
    return () => {
      mounted = false;
    };
  }, [
    file,
    title,
    link,
    file2,
    file3,
    file4,
    description,
    description2,
    link,
    date,
    item,
  ]);

  return (
    loaded && (
      <div className="modify-wrapper">

        <Helmet>
          <title>Sharlockpro - Modifier la communication</title>
          <meta name="keywords" content="sharlockpro, offers, modifier, communication, offres, Sharlockpro.fr" />
          <meta name="description" content="Modifier une communication sur sharlockpro" />
        </Helmet>

        <div className="preview-header-container">
          {item.item !== undefined ? (
            <h1 className="modify-header-title">
              Modifier la communication : {item.item.data.name} du{" "}
              {new Date(
                item.item.data.creationDate.seconds * 1000
              ).toLocaleDateString()}
            </h1>
          ) : null}
          <button
            onClick={() => {
              setVisibleDeleteOffer(true);
            }}
            className="modify-delete-offer"
          >
            Supprimer la communication
          </button>
        </div>

        <Modal
          open={visibleDeleteOffer}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="modifyModal-box" style={{ top: window.innerHeight / 2 - 172.5 }}>
            <AiFillCloseCircle
              className="myOffers-modal-close"
              onClick={() => {
                handleClose();
              }}
            />
            <div className="modify-deleteOffer-container">
              <h3 className="modify-deleteOffer-title">
                Supprimer la communication {item.item.data.name}
              </h3>
              <p className="modify-deleteOffer-text">
                Êtes-vous sûr de vouloir supprimer la communication {item.item.data.name}{" "}
                ? Elle n’apparaitra plus dans l’espace promotionnelle des
                utilisateurs sharlock et il ne pourront plus en profiter.
              </p>
              <div className="modify-deleteOffer-btnContainer">
                <button
                  className="modify-deleteOffer-btn-cancel"
                  onClick={() => {
                    setVisibleDeleteOffer(false);
                  }}
                >
                  Annuler
                </button>
                {!isDeleting ?
                  <button
                    onClick={() => {
                      deleteOffer();
                    }}
                    className="modify-deleteOffer-btn-delete"
                  >
                    Supprimer
                  </button>

                  :

                  <button
                    disabled
                    className="modify-deleteOffer-btn-delete"
                  >
                    <Dots color="white" size={30} />
                  </button>
                }

              </div>
            </div>
          </Box>
        </Modal>

        <div className="modify-sub-wrapper">
          <div className="modify-container">
            {item !== undefined ? (
              <>
                <form className="modify-form-container">
                  <div className="modify-form-input-wrap">
                    <label className="modify-form-label">
                      Titre : <span className="asterisk">*</span>
                    </label>
                    <input
                      className="modify-form-input"
                      type="text"
                      name="title"
                      value={title}
                      maxLength="50"
                      onChange={(e) => {
                        setTitle(e.currentTarget.value);
                      }}
                      required
                    />
                    <p className="modify-form-length">{title.length}/50</p>

                    <label className="modify-form-label">Description 1 :</label>
                    <textarea
                      className="modify-form-input-textarea"
                      name="description"
                      value={description}
                      maxLength="250"
                      onChange={(e) => {
                        setDescription(e.currentTarget.value);
                      }}
                      required
                    ></textarea>
                    <p className="modify-form-length">
                      {description.length}/250
                    </p>

                    <label className="modify-form-label">Description 2 :</label>
                    <textarea
                      className="modify-form-input-textarea"
                      name="description2"
                      value={description2}
                      maxLength="250"
                      onChange={(e) => {
                        setDescription2(e.currentTarget.value);
                      }}
                    ></textarea>
                    <p className="modify-form-length">
                      {description2.length}/250
                    </p>

                    <label className="modify-form-label">Date de fin :</label>
                    <input
                      className={
                        date !== undefined && !dateError
                          ? "modify-form-input modify-date-input-has-value"
                          : dateError
                            ? "modify-date-error"
                            : "modify-form-input modify-date-input-placeholder"
                      }
                      type="date"
                      name="date"
                      min={new Date(item.item.data.date.seconds * 1000).toLocaleDateString().substring(6, 11) + "-" + new Date(item.item.data.date.seconds * 1000).toLocaleDateString().substring(3, 5) + "-" + new Date(item.item.data.date.seconds * 1000).toLocaleDateString().substring(0, 2)}
                      onChange={(e) => {
                        setCalendarDate(e.currentTarget.value)
                        transformDate(e.currentTarget.value);
                      }}
                      value={
                        date === undefined ? new Date(item.item.data.date.seconds * 1000).toLocaleDateString().substring(6, 11) + "-" + new Date(item.item.data.date.seconds * 1000).toLocaleDateString().substring(3, 5) + "-" + new Date(item.item.data.date.seconds * 1000).toLocaleDateString().substring(0, 2)
                          : calendarDate
                      }

                    />

                    <label className="modify-form-label">
                      Lien vers l'annonce : <span className="asterisk">*</span>
                    </label>
                    <input
                      className="modify-form-input"
                      type="text"
                      name="link"
                      value={link}
                      onChange={(e) => {
                        setLink(e.currentTarget.value);
                      }}
                    />
                    {urlError !== null ? (
                      <p className="errorMsg">{urlError}</p>
                    ) : null}
                  </div>
                  <label className="modify-form-label-photo">
                    Photos : <span className="asterisk">*</span>
                  </label>
                  <div className="modify-form-photos-container">
                    <div className="modify-form-photos-row">
                      <div
                        className="modify-form-photo photos-1"
                        style={
                          file !== null
                            ? {
                              background: `url(${file.url})`,
                              backgroundPosition: "center",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "375px,327px"
                            }
                            : item.item.data.picture !== ""
                              ? {
                                background: `url(${item.item.data.picture})`,
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "375px,327px"
                              }
                              : null
                        }
                      >
                        <input
                          type="file"
                          name="image"
                          style={{ display: "none" }}
                          onChange={handleChange}
                          ref={inputFileRef1}
                          required
                          accept="image/png, image/gif, image/jpeg, .webp"
                        />
                        {file === null && item.item.data.picture === "" ? (
                          <HiOutlinePlus
                            className="modify-form-photo-file-icon"
                            onClick={() => {
                              handleClick(inputFileRef1);
                            }}
                          />
                        ) : null}

                        {file !== null ? (
                          <>
                            <div
                              className="modify-form-removePicture"
                              onClick={() => {
                                setFile(null);
                              }}
                            >
                              <IoClose size={20} />
                            </div>
                          </>
                        ) : item.item.data.picture !== "" ? (
                          <>
                            <div
                              className="modify-form-removePicture"
                              onClick={() => {
                                const temp = { ...item };
                                temp.item.data.picture = "";
                                setItem(temp);
                              }}
                            >
                              <IoClose size={20} />
                            </div>
                          </>
                        ) : (
                          <p className="modify-form-photo-title">
                            Photo 1 (375x457)<span className="asterisk">*</span>
                          </p>
                        )}
                      </div>
                      <div
                        className="modify-form-photo photos-2"
                        style={
                          file2 !== null
                            ? {
                              background: `url(${file2.url})`,
                              backgroundPosition: "center",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "375px,327px"
                            }
                            : item.item.data.picture2 !== ""
                              ? {
                                background: `url(${item.item.data.picture2})`,
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "375px,327px"
                              }
                              : null
                        }
                      >
                        <input
                          type="file"
                          name="image2"
                          style={{ display: "none" }}
                          onChange={handleChange}
                          ref={inputFileRef2}
                          accept="image/png, image/gif, image/jpeg, .webp"
                        />
                        {file2 === null && item.item.data.picture2 === "" ? (
                          <HiOutlinePlus
                            className="modify-form-photo-file-icon"
                            onClick={() => {
                              handleClick(inputFileRef2);
                            }}
                          />
                        ) : null}
                        {file2 !== null ? (
                          <>
                            <div
                              className="modify-form-removePicture"
                              onClick={() => {
                                setFile2(null);
                              }}
                            >
                              <IoClose size={20} />
                            </div>
                          </>
                        ) : item.item.data.picture2 !== "" ? (
                          <>
                            <div
                              className="modify-form-removePicture"
                              onClick={() => {
                                const temp = { ...item };
                                temp.item.data.picture2 = "";
                                setItem(temp);
                              }}
                            >
                              <IoClose size={20} />
                            </div>
                          </>
                        ) : (
                          <p className="modify-form-photo-title">
                            Photo 2 (375x327)
                          </p>
                        )}
                      </div>
                      <div
                        className="modify-form-photo photos-3"
                        style={
                          file3 !== null
                            ? {
                              background: `url(${file3.url})`,
                              backgroundPosition: "center",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "375px,327px"
                            }
                            : item.item.data.picture3 !== ""
                              ? {
                                background: `url(${item.item.data.picture3})`,
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "375px,327px"
                              }
                              : null
                        }
                      >
                        <input
                          type="file"
                          name="image3"
                          style={{ display: "none" }}
                          onChange={handleChange}
                          ref={inputFileRef3}
                          accept="image/png, image/gif, image/jpeg, .webp"
                        />
                        {file3 === null && item.item.data.picture3 === "" ? (
                          <HiOutlinePlus
                            className="modify-form-photo-file-icon"
                            onClick={() => {
                              handleClick(inputFileRef3);
                            }}
                          />
                        ) : null}

                        {file3 !== null ? (
                          <>
                            <div
                              className="modify-form-removePicture"
                              onClick={() => {
                                setFile3(null);
                              }}
                            >
                              <IoClose size={20} />
                            </div>
                          </>
                        ) : item.item.data.picture3 !== "" ? (
                          <>
                            <div
                              className="modify-form-removePicture"
                              onClick={() => {
                                const temp = { ...item };
                                temp.item.data.picture3 = "";
                                setItem(temp);
                              }}
                            >
                              <IoClose size={20} />
                            </div>
                          </>
                        ) : (
                          <p className="modify-form-photo-title">
                            Photo 3 (375x327)
                          </p>
                        )}
                      </div>
                      <div
                        className="modify-form-photo photos-4"
                        style={
                          file4 !== null
                            ? {
                              background: `url(${file4.url})`,
                              backgroundPosition: "center",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "375px,327px"
                            }
                            : item.item.data.picture4 !== ""
                              ? {
                                background: `url(${item.item.data.picture4})`,
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "375px,327px"
                              }
                              : null
                        }
                      >
                        <input
                          type="file"
                          name="image4"
                          style={{ display: "none" }}
                          onChange={handleChange}
                          ref={inputFileRef4}
                          accept="image/png, image/gif, image/jpeg, .webp"
                        />
                        {file4 === null && item.item.data.picture4 === "" ? (
                          <HiOutlinePlus
                            className="modify-form-photo-file-icon"
                            onClick={() => {
                              handleClick(inputFileRef4);
                            }}
                          />
                        ) : null}

                        {file4 !== null ? (
                          <>
                            <div
                              className="modify-form-removePicture"
                              onClick={() => {
                                setFile4(null);
                              }}
                            >
                              <IoClose size={20} />
                            </div>
                          </>
                        ) : item.item.data.picture4 !== "" ? (
                          <>
                            <div
                              className="modify-form-removePicture"
                              onClick={() => {
                                const temp = { ...item };
                                temp.item.data.picture4 = "";
                                setItem(temp);
                              }}
                            >
                              <IoClose size={20} />
                            </div>
                          </>
                        ) : (
                          <p className="modify-form-photo-title">
                            Photo 4 (375x327)
                          </p>
                        )}
                      </div>
                    </div>
                    {error !== null ? (
                      <p className="errorMsg">{error}</p>
                    ) : null}
                    <div className="modify-form-submit-container">
                      {!disabled && !isUploading ? (
                        <input
                          className="modify-form-submit"
                          type="submit"
                          value="Modifier la communication"
                          onClick={(e) => {
                            e.preventDefault();
                            onSubmitForm();
                          }}
                        />
                      ) : disabled && !isUploading ? (
                        <button
                          type="submit"
                          className="modify-form-submit-disabled"
                        >
                          Modifier la communication
                        </button>
                      ) :
                        <button
                          type="submit"
                          className="modify-form-submit"
                          disabled
                        >
                          <Dots color="#AA80F9" size={30} />
                        </button>
                      }
                    </div>
                  </div>
                </form>
              </>
            ) : null}
          </div>

          <div className="modify-preview-container">
            <div className="preview-iphone-containerbis">
              <div className="preview-iphone">
                <p className="preview-iphone-clock">
                  {new Date().getHours() + ":"}
                  {new Date().getMinutes() < 10
                    ? "0" + new Date().getMinutes()
                    : new Date().getMinutes()}
                </p>
                {item.item.data.date.seconds * 1000 > new Date().getTime() || item.item.data.date.seconds * 1000 + 86400000 > new Date().getTime() ?
                  <div className="preview-iphone-offer-status">
                    <AiOutlineWarning color="white" style={{ marginRight: 5 }} />
                    <p className="preview-iphone-offer-status-text">
                      Prend fin le {new Date(item.item.data.date.seconds * 1000).toLocaleDateString().substring(0, 5)}
                    </p>
                  </div>
                  :
                  item.item.data.date === "" ?
                    <></>
                    :
                    <div className="preview-iphone-offer-status-expired">
                      <p className="preview-iphone-offer-status-text">
                        Expiré
                      </p>
                    </div>
                }
                <div className="preview-iphone-actions-wrapper">
                  <div className="preview-iphone-actions-btn-container">
                    <IoClose
                      className="preview-iphone-actions-btn"
                      color="red"
                    />
                  </div>
                  <div className="preview-iphone-actions-btn-container">
                    <IoSearchOutline
                      className="preview-iphone-actions-btn"
                      color="#2A7AF5"
                    />
                  </div>
                  <div className="preview-iphone-actions-btn-container">
                    <IoCheckmark
                      className="preview-iphone-actions-btn"
                      color="#06DE42"
                    />
                  </div>
                </div>
                <div className="preview-iphone-scrollable">
                  <div className="preview-iphone-scrollable-elements mt-10">
                    {file !== null ? (
                      <img
                        className="preview-iphone-scrollable-element-img"
                        src={file.url}
                        alt={file.name}
                      />
                    ) : item.item.data.picture !== "" ? (
                      <img
                        className="preview-iphone-scrollable-element-img"
                        src={item.item.data.picture}
                        alt={item.item.data.name}
                      />
                    ) : (
                      <div
                        className="preview-iphone-scrollable-element-nopic"
                        style={{ background: "#F2DED0" }}
                      >
                        <p>Photo 1</p>
                      </div>
                    )}
                    <div className="preview-iphone-arraydown-btn-container">
                      <ImArrowDown
                        color="white"
                        className="preview-iphone-icon-arraydown"
                      />
                    </div>
                  </div>

                  <div className="preview-iphone-scrollable-elements">
                    <div className="row pt-20">
                      <img
                        src={user.logo}
                        alt={user.name}
                        className="preview-iphone-scrollable-element-logo"
                      />

                      <h3 className="preview-iphone-scrollable-element-partner">
                        {user.name}
                      </h3>
                    </div>
                    {title !== "" ? (
                      <p className="preview-iphone-scrollable-element-title">
                        {title}
                      </p>
                    ) : (
                      <p className="preview-iphone-scrollable-element-title">
                        {item.item.data.name}
                      </p>
                    )}
                    {description !== "" ? (
                      <p className="preview-iphone-scrollable-element-description">
                        {description}
                      </p>
                    ) : (
                      <p className="preview-iphone-scrollable-element-description">
                        {item.item.data.description}
                      </p>
                    )}
                    {file2 !== null ? (
                      <img
                        className="preview-iphone-scrollable-element-img2"
                        src={file2.url}
                        alt={file2.name}
                      />
                    ) : item.item.data.picture2 !== "" ? (
                      <img
                        className="preview-iphone-scrollable-element-img2"
                        src={item.item.data.picture2}
                        alt=""
                      />
                    ) : (
                      <div
                        className="preview-iphone-scrollable-element-nopic2"
                        style={{ background: "#F2EAB8" }}
                      >
                        <p>Photo 2</p>
                      </div>
                    )}
                    {description2 !== "" ? (
                      <p className="preview-iphone-scrollable-element-description">
                        {description2}
                      </p>
                    ) : item.item.data.description2 !== "" ? (
                      <p className="preview-iphone-scrollable-element-description">
                        {item.item.data.description2}
                      </p>
                    ) : null}
                    {file3 !== null ? (
                      <img
                        className="preview-iphone-scrollable-element-img2"
                        src={file3.url}
                        alt={file3.name}
                      />
                    ) : item.item.data.picture3 !== "" ? (
                      <img
                        className="preview-iphone-scrollable-element-img2"
                        src={item.item.data.picture3}
                        alt=""
                      />
                    ) : (
                      <div
                        className="preview-iphone-scrollable-element-nopic2"
                        style={{ background: "#BEB8F2" }}
                      >
                        <p>Photo 3</p>
                      </div>
                    )}
                    {file4 !== null ? (
                      <img
                        className="preview-iphone-scrollable-element-img2"
                        src={file4.url}
                        alt={file4.name}
                      />
                    ) : item.item.data.picture4 !== "" ? (
                      <img
                        className="preview-iphone-scrollable-element-img2"
                        src={item.item.data.picture4}
                        alt=""
                      />
                    ) : (
                      <div
                        className="preview-iphone-scrollable-element-nopic2"
                        style={{ background: "#A9F2A0" }}
                      >
                        <p>Photo 4</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default Modify;
