import React, { useRef, useEffect, useState } from "react";
import { useUserAuth } from "../../../context/UserAuthContext";
import { database } from "../../../firebase";
import { doc, updateDoc } from "firebase/firestore";
import { useNavigate, useLocation } from "react-router-dom";
import { ref, uploadBytesResumable, getDownloadURL, getStorage } from "firebase/storage";
import { HiOutlinePlus } from "react-icons/hi";
import { IoClose } from "react-icons/io5";
import Compressor from 'compressorjs';
import { v4 } from "uuid";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Spinner } from "react-activity";
import "react-activity/dist/library.css";

const NewPartner = () => {
    const location = useLocation();
    const params = location.state.user;

    const navigate = useNavigate();
    const { user } = useUserAuth();
    const isFirstRender = useRef(true);

    const [inProcess, setInProcess] = useState(false)
    const [cardCategory, setCardCategory] = useState("")
    const [cardTitle, setCardTitle] = useState("")

    const [name, setName] = useState("")
    const [slogan, setSlogan] = useState("")
    const [website, setWebsite] = useState("")
    const [commitment, setCommitment] = useState("")
    const [description, setDescription] = useState("")
    const [mission, setMission] = useState("")
    const [history, setHistory] = useState("")
    const [promotionPercent, setPromotionPercent] = useState("")
    const [promotionDescription, setPromotionDescription] = useState("")
    const [promotionCode, setPromotionCode] = useState("")
    const [promotionExpirationDate, setPromotionExpirationDate] = useState("")
    const [promotionLink, setPromotionLink] = useState("")
    const [promotionPopupText, setPromotionPopupText] = useState("")

    const [logo, setLogo] = useState(null)
    const [backgroundPicture, setBackgroundPicture] = useState(null)
    const [file, setFile] = useState(null);
    const [file2, setFile2] = useState(null);
    const [file3, setFile3] = useState(null);

    const inputLogoRef = useRef(null);
    const inputBackgroundPictureRef = useRef(null)
    const inputFileRef1 = useRef(null);
    const inputFileRef2 = useRef(null);
    const inputFileRef3 = useRef(null);

    const [selectedLabels, setSelectedLabels] = useState([]);
    const [isChecked, setIsChecked] = useState({
        mode:
        {
            men: false,
            women: false,
            underwear: false,
            underwearmen: false,
            swimsuit: false,
            pyjamas: false,
            sock: false,
        },
        beauty:
        {
            skincare: false,
            hygiene: false,
            fragrance: false,
            makeup: false,
            manicure: false

        },
        house:
        {
            decoration: false,
            household: false,
            candle: false,
            incense: false,
        },
        accessory:
        {
            bag: false,
            bumbag: false,
            jewellery: false,
            scrunchie: false
        },
        kids:
        {
            binky: false,
            nappy: false
        },
        sneakers:
        {
            checked: false
        },
        sport:
        {
            checked: false
        },
        game:
        {
            checked: false
        },
        drinks:
        {
            tea: false
        }
    })

    const labelOptions = [
        'Made in France',
        'Made in Europe',
        'Bio',
        'Éthique',
        'Vegan',
        'Recyclé',
        'Zéro déchets',
        'Faible en CO2',
        '100 % Naturel',
        'Upcycling',
        'Artisanal',
        'Zéro plastique',
        'Circuit court',
        'Précommande',
        'Co-création',
        'Durable',
        'Recyclable',
        'Produits naturels',
        'Packaging recyclable',
        'Produits solides',
        'Biodégradable',
        'Cruelty free',
        'Rechargeable',
        'Engagements associatifs'
    ];

    const toggleLabel = (label) => {
        if (selectedLabels.includes(label)) {
            setSelectedLabels(selectedLabels.filter(item => item !== label));
        } else {
            setSelectedLabels([...selectedLabels, label]);
        }
    };

    const assignImageUrlToUserData = (category, url, userData) => {
        switch (category) {
            case "logo":
                return { ...userData, logo: url };
            case "banner":
                return { ...userData, banner: url };
            case "picture1":
                return { ...userData, picture1: url };
            case "picture2":
                return { ...userData, picture2: url };
            case "picture3":
                return { ...userData, picture3: url };
            default:
                return userData;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setInProcess(true)
        const uuid = v4()

        try {
            const categories = await filterCheckedCategories();
            const itemRef = doc(database, "partners", params.uid);
            const photos = await uploadMultiplePhotos();

            let userData = {
                logo: "",
                banner: "",
                picture1: "",
                picture2: "",
                picture3: "",
            };

            photos.forEach((element) => {
                userData = assignImageUrlToUserData(element.category, element.url, userData);
            });

            const data = {
                categories: categories,
                card: {
                    category: cardCategory,
                    detail: "",
                    title: cardTitle,
                },
                email: params.email,
                description: description,
                slogan: slogan,
                name: name,
                website: website,
                mission: mission,
                history: history,
                commitment: commitment,
                logo: userData.logo,
                picture: userData.banner,
                picture1: userData.picture1,
                picture2: userData.picture2,
                picture3: userData.picture3,
                promotion: {
                    categories: [],
                    creationDate: new Date(),
                    percent: promotionPercent,
                    description: promotionDescription,
                    promoCode: promotionCode,
                    expirationDate: promotionExpirationDate,
                    id: uuid,
                    title: `${promotionPercent}% de réduction`,
                    affiliateLink: promotionLink,
                    popupText: promotionPopupText
                },
                products: {
                    background: "",
                    link: "",
                    images: []
                },
                uid: params.uid,
                likes: [],
                labels: selectedLabels,
                verified: true
            };

            await updateDoc(itemRef, data);
            alert("Le compte partenaire est prêt à être utilisé.")
            navigate("/profil");
        } catch (error) {
            console.error(error);
            // Show a user-friendly error message on the page
            alert("Désolé, nous n'avons pas pu traiter votre demande. Veuillez vérifier les informations que vous avez fournies et réessayer.")
        }
    };

    const handleClick = (ref) => {  // create a useRef of the file click
        ref.current.click();
    };

    const handleCompressedFile = (file, category, storageName, setFile) => {
        new Compressor(file, {
            quality: 0.6,
            success: (compressedResult) => {
                let newFile = compressedResult
                newFile.url = URL.createObjectURL(compressedResult)
                newFile.category = category
                newFile.storageName = storageName
                setFile(newFile)
            },
        })
    }

    const handleChange = (e) => {
        const { name, files } = e.currentTarget
        const file = files[0]

        if (!file) {
            return
        }

        if (!["image/png", "image/jpeg", "image/webp"].includes(file.type)) {
            alert("Vous devez sélectionner un fichier de type image (jpg/jpeg/png/webp)")
            return
        }

        if (file.size >= 5000000) {
            alert("Le fichier est trop volumineux");
            return
        }

        switch (name) {
            case "image":
                handleCompressedFile(file, "picture1", `${user.uid}_picture1`, setFile)
                break
            case "image2":
                handleCompressedFile(file, "picture2", `${user.uid}_picture2`, setFile2)
                break
            case "image3":
                handleCompressedFile(file, "picture3", `${user.uid}_picture3`, setFile3)
                break
            case "backgroundPicture":
                handleCompressedFile(file, "banner", `${user.uid}_banner`, setBackgroundPicture)
                break
            case "logo":
                handleCompressedFile(file, "logo", `${user.uid}_logo`, setLogo)
                break
            default:
                break
        }
    }

    const handleCheckboxChange = (item) => { // mutate checkbox hooks
        if (!isFirstRender.current) {

            const tempChecked = { ...isChecked }
            switch (item) {
                case "men":
                    tempChecked.mode.men = true
                    setIsChecked(tempChecked)
                    break;

                case "!men":
                    tempChecked.mode.men = false
                    setIsChecked(tempChecked)
                    break;

                case "women":
                    tempChecked.mode.women = true
                    setIsChecked(tempChecked)
                    break;

                case "!women":
                    tempChecked.mode.women = false
                    setIsChecked(tempChecked)
                    break;

                case "underwear":
                    tempChecked.mode.underwear = true
                    setIsChecked(tempChecked)
                    break;

                case "!underwear":
                    tempChecked.mode.underwear = false
                    setIsChecked(tempChecked)
                    break;

                case "underwearmen":
                    tempChecked.mode.underwearmen = true
                    setIsChecked(tempChecked)
                    break;

                case "!underwearmen":
                    tempChecked.mode.underwearmen = false
                    setIsChecked(tempChecked)
                    break;

                case "swimsuit":
                    tempChecked.mode.swimsuit = true
                    setIsChecked(tempChecked)
                    break;

                case "!swimsuit":
                    tempChecked.mode.swimsuit = false
                    setIsChecked(tempChecked)
                    break;

                case "pyjamas":
                    tempChecked.mode.pyjamas = true
                    setIsChecked(tempChecked)
                    break;

                case "!pyjamas":
                    tempChecked.mode.pyjamas = false
                    setIsChecked(tempChecked)
                    break;

                case "sock":
                    tempChecked.mode.sock = true
                    setIsChecked(tempChecked)
                    break;

                case "!sock":
                    tempChecked.mode.sock = false
                    setIsChecked(tempChecked)
                    break;

                case "skincare":
                    tempChecked.beauty.skincare = true
                    setIsChecked(tempChecked)
                    break;

                case "!skincare":
                    tempChecked.beauty.skincare = false
                    setIsChecked(tempChecked)
                    break;

                case "hygiene":
                    tempChecked.beauty.hygiene = true
                    setIsChecked(tempChecked)
                    break;

                case "!hygiene":
                    tempChecked.beauty.hygiene = false
                    setIsChecked(tempChecked)
                    break;

                case "fragrance":
                    tempChecked.beauty.fragrance = true
                    setIsChecked(tempChecked)
                    break;

                case "!fragrance":
                    tempChecked.beauty.fragrance = false
                    setIsChecked(tempChecked)
                    break;

                case "makeup":
                    tempChecked.beauty.makeup = true
                    setIsChecked(tempChecked)
                    break;

                case "!makeup":
                    tempChecked.beauty.makeup = false
                    setIsChecked(tempChecked)
                    break;

                case "manicure":
                    tempChecked.beauty.manicure = true
                    setIsChecked(tempChecked)
                    break;

                case "!manicure":
                    tempChecked.beauty.manicure = false
                    setIsChecked(tempChecked)
                    break;

                case "decoration":
                    tempChecked.house.decoration = true
                    setIsChecked(tempChecked)
                    break;

                case "!decoration":
                    tempChecked.house.decoration = false
                    setIsChecked(tempChecked)
                    break;

                case "household":
                    tempChecked.house.household = true
                    setIsChecked(tempChecked)
                    break;

                case "!household":
                    tempChecked.house.household = false
                    setIsChecked(tempChecked)
                    break;

                case "candle":
                    tempChecked.house.candle = true
                    setIsChecked(tempChecked)
                    break;

                case "!candle":
                    tempChecked.house.candle = false
                    setIsChecked(tempChecked)
                    break;

                case "incense":
                    tempChecked.house.incense = true
                    setIsChecked(tempChecked)
                    break;

                case "!incense":
                    tempChecked.house.incense = false
                    setIsChecked(tempChecked)
                    break;

                case "jewellery":
                    tempChecked.accessory.jewellery = true
                    setIsChecked(tempChecked)
                    break;

                case "!jewellery":
                    tempChecked.accessory.jewellery = false
                    setIsChecked(tempChecked)
                    break;

                case "scrunchie":
                    tempChecked.accessory.scrunchie = true
                    setIsChecked(tempChecked)
                    break;

                case "!scrunchie":
                    tempChecked.accessory.scrunchie = false
                    setIsChecked(tempChecked)
                    break;

                case "bag":
                    tempChecked.accessory.bag = true
                    setIsChecked(tempChecked)
                    break;

                case "!bag":
                    tempChecked.accessory.bag = false
                    setIsChecked(tempChecked)
                    break;

                case "bumbag":
                    tempChecked.accessory.bumbag = true
                    setIsChecked(tempChecked)
                    break;

                case "!bumbag":
                    tempChecked.accessory.bumbag = false
                    setIsChecked(tempChecked)
                    break;
                case "binky":
                    tempChecked.kids.binky = true
                    setIsChecked(tempChecked)
                    break;

                case "!binky":
                    tempChecked.kids.binky = false
                    setIsChecked(tempChecked)
                    break;

                case "nappy":
                    tempChecked.kids.nappy = true
                    setIsChecked(tempChecked)
                    break;

                case "!nappy":
                    tempChecked.kids.nappy = false
                    setIsChecked(tempChecked)
                    break;

                case "sport":
                    tempChecked.sport.checked = true
                    setIsChecked(tempChecked)
                    break;

                case "!sport":
                    tempChecked.sport.checked = false
                    setIsChecked(tempChecked)
                    break;

                case "sneakers":
                    tempChecked.sneakers.checked = true
                    setIsChecked(tempChecked)
                    break;

                case "!sneakers":
                    tempChecked.sneakers.checked = false
                    setIsChecked(tempChecked)
                    break;

                case "game":
                    tempChecked.game.checked = true
                    setIsChecked(tempChecked)
                    break;

                case "!game":
                    tempChecked.game.checked = false
                    setIsChecked(tempChecked)
                    break;

                case "tea":
                    tempChecked.drinks.tea = true
                    setIsChecked(tempChecked)
                    break;

                case "!tea":
                    tempChecked.drinks.tea = false
                    setIsChecked(tempChecked)
                    break;

                default:
                    break;
            }

        }
    }

    const uploadMultiplePhotos = async () => {
        let photos = []
        const files = []
        const storage = getStorage()
        files.push(logo, backgroundPicture, file, file2, file3)

        for (let i = 0; i < files.length; i++) {
            const element = files[i];

            if (element !== null) {
                const storageRef = ref(storage, `${params.uid}/images/${element.storageName}`);
                const snapshot = await uploadBytesResumable(storageRef, element)
                const downloadURL = await getDownloadURL(snapshot.ref)
                const item = {
                    url: downloadURL,
                    category: element.category,
                }
                photos.push(item)
            }
        }
        return photos
    }

    const filterCheckedCategories = async () => {
        let categories = { ...isChecked };

        const filterCategory = (category) => {
            for (let prop in categories[category]) {
                if (categories[category][prop] === false) {
                    delete categories[category][prop];
                }
            }

            if (Object.keys(categories[category]).length === 0) {
                delete categories[category];
            }
        };

        ['mode', 'beauty', 'house', 'accessory', 'kids', 'drinks'].forEach((category) =>
            filterCategory(category)
        );

        const deleteCategory = (category) => {
            if (categories[category] && categories[category].checked === false) {
                delete categories[category];
            } else if (categories[category] && categories[category].checked === true) {
                delete categories[category].checked;
            }
        };

        deleteCategory('sneakers');
        deleteCategory('sport');
        deleteCategory('game');

        return categories;
    };

    useEffect(() => {
        isFirstRender.current = false;
    }, []);

    return (
        <>
            <h1 className="register-title">Création des informations du nouveau partenaire</h1>

            <div className="container">
                <form onSubmit={handleSubmit}>

                    <h2 className="register-subtitle">Carte sur l'appli mobile:</h2>

                    <div className="field-form">
                        <input
                            className="input-form"
                            type="text"
                            placeholder="Catégorie"
                            value={cardCategory}
                            onChange={(e) => setCardCategory(e.target.value)}
                        />
                    </div>

                    <div className="field-form">
                        <input
                            className="input-form"
                            type="text"
                            placeholder="Titre"
                            value={cardTitle}
                            onChange={(e) => setCardTitle(e.target.value)}
                        />
                    </div>

                    <h2 className="register-subtitle">Catégories</h2>

                    <div className="register-categories-wrapper">

                        {/* MODE */}
                        <div className="register-categories-partner">
                            <h3>Mode</h3>
                            <div className="register-categories-partner-row">
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={isChecked.mode.men}
                                        onChange={() => {
                                            if (isChecked.mode.men) {
                                                handleCheckboxChange("!men")
                                            }
                                            else {
                                                handleCheckboxChange("men")
                                            }

                                        }}
                                    />
                                </label>
                                <span className="register-subcategories-text">Homme</span>
                            </div>

                            <div className="register-categories-partner-row">
                                <label>
                                    <input type="checkbox"
                                        checked={isChecked.mode.women}
                                        onChange={() => {
                                            if (isChecked.mode.women) {
                                                handleCheckboxChange("!women")
                                            }
                                            else {
                                                handleCheckboxChange("women")
                                            }

                                        }}
                                    />
                                </label>
                                <span className="register-subcategories-text">Femme</span>
                            </div>

                            <div className="register-categories-partner-row">
                                <label>
                                    <input type="checkbox"
                                        checked={isChecked.mode.underwear}
                                        onChange={() => {
                                            if (isChecked.mode.underwear) {
                                                handleCheckboxChange("!underwear")
                                            }
                                            else {
                                                handleCheckboxChange("underwear")
                                            }
                                        }} />
                                </label>
                                <span className="register-subcategories-text">Lingerie</span>
                            </div>

                            <div className="register-categories-partner-row">
                                <label>
                                    <input type="checkbox"
                                        checked={isChecked.mode.underwearmen}
                                        onChange={() => {
                                            if (isChecked.mode.underwearmen) {
                                                handleCheckboxChange("!underwearmen")
                                            }
                                            else {
                                                handleCheckboxChange("underwearmen")
                                            }
                                        }} />
                                </label>
                                <span className="register-subcategories-text">Sous-vêtements homme</span>
                            </div>

                            <div className="register-categories-partner-row">
                                <label>
                                    <input type="checkbox"
                                        checked={isChecked.mode.swimsuit}
                                        onChange={() => {
                                            if (isChecked.mode.swimsuit) {
                                                handleCheckboxChange("!swimsuit")
                                            }
                                            else {
                                                handleCheckboxChange("swimsuit")
                                            }
                                        }}
                                    />
                                </label>
                                <span className="register-subcategories-text">Maillot de bain</span>
                            </div>

                            <div className="register-categories-partner-row">
                                <label>
                                    <input type="checkbox"
                                        checked={isChecked.mode.pyjamas}
                                        onChange={() => {
                                            if (isChecked.mode.pyjamas) {
                                                handleCheckboxChange("!pyjamas")
                                            }
                                            else {
                                                handleCheckboxChange("pyjamas")
                                            }
                                        }}
                                    />
                                </label>
                                <span className="register-subcategories-text">Pyjamas Kimonos</span>
                            </div>

                            <div className="register-categories-partner-row">
                                <label>
                                    <input type="checkbox"
                                        checked={isChecked.mode.sock}
                                        onChange={() => {
                                            if (isChecked.mode.sock) {
                                                handleCheckboxChange("!sock")
                                            }
                                            else {
                                                handleCheckboxChange("sock")
                                            }
                                        }}
                                    />
                                </label>
                                <span className="register-subcategories-text">Chaussettes</span>
                            </div>

                        </div>

                        {/* BEAUTY */}
                        <div className="register-categories-partner">
                            <h3>Beauté</h3>
                            <div className="register-categories-partner-row">
                                <label>
                                    <input type="checkbox"
                                        checked={isChecked.beauty.skincare}
                                        onChange={() => {
                                            if (isChecked.beauty.skincare) {
                                                handleCheckboxChange("!skincare")
                                            }
                                            else {
                                                handleCheckboxChange("skincare")
                                            }
                                        }}
                                    />
                                </label>
                                <span className="register-subcategories-text">Soin peau visage</span>
                            </div>

                            <div className="register-categories-partner-row">
                                <label>
                                    <input type="checkbox"
                                        onChange={() => {
                                            if (isChecked.beauty.hygiene) {
                                                handleCheckboxChange("!hygiene")
                                            }
                                            else {
                                                handleCheckboxChange("hygiene")
                                            }
                                        }}
                                    />
                                </label>
                                <span className="register-subcategories-text">Hygiène</span>
                            </div>

                            <div className="register-categories-partner-row">
                                <label>
                                    <input type="checkbox"
                                        onChange={() => {
                                            if (isChecked.beauty.fragrance) {
                                                handleCheckboxChange("!fragrance")
                                            }
                                            else {
                                                handleCheckboxChange("fragrance")
                                            }
                                        }}
                                    />
                                </label>
                                <span className="register-subcategories-text">Parfum</span>
                            </div>

                            <div className="register-categories-partner-row">
                                <label>
                                    <input type="checkbox"
                                        onChange={() => {
                                            if (isChecked.beauty.makeup) {
                                                handleCheckboxChange("!makeup")
                                            }
                                            else {
                                                handleCheckboxChange("makeup")
                                            }
                                        }}
                                    />
                                </label>
                                <span className="register-subcategories-text">Maquillage</span>
                            </div>

                            <div className="register-categories-partner-row">
                                <label>
                                    <input type="checkbox"
                                        onChange={() => {
                                            if (isChecked.beauty.manicure) {
                                                handleCheckboxChange("!manicure")
                                            }
                                            else {
                                                handleCheckboxChange("manicure")
                                            }
                                        }}
                                    />
                                </label>
                                <span className="register-subcategories-text">Manucure</span>
                            </div>

                        </div>

                        {/* HOUSE */}
                        <div className="register-categories-partner">
                            <h3>Maison</h3>
                            <div className="register-categories-partner-row">
                                <label>
                                    <input type="checkbox"
                                        checked={isChecked.house.decoration}
                                        onChange={() => {
                                            if (isChecked.house.decoration) {
                                                handleCheckboxChange("!decoration")
                                            }
                                            else {
                                                handleCheckboxChange("decoration")
                                            }
                                        }}
                                    />
                                </label>
                                <span className="register-subcategories-text">Déco</span>
                            </div>

                            <div className="register-categories-partner-row">
                                <label>
                                    <input type="checkbox"
                                        checked={isChecked.house.household}
                                        onChange={() => {
                                            if (isChecked.house.household) {
                                                handleCheckboxChange("!household")
                                            }
                                            else {
                                                handleCheckboxChange("household")
                                            }
                                        }}
                                    />
                                </label>
                                <span className="register-subcategories-text">Entretien</span>
                            </div>

                            <div className="register-categories-partner-row">
                                <label>
                                    <input type="checkbox"
                                        checked={isChecked.house.candle}
                                        onChange={() => {
                                            if (isChecked.house.candle) {
                                                handleCheckboxChange("!candle")
                                            }
                                            else {
                                                handleCheckboxChange("candle")
                                            }
                                        }}
                                    />
                                </label>
                                <span className="register-subcategories-text">Bougies</span>
                            </div>

                            <div className="register-categories-partner-row">
                                <label>
                                    <input type="checkbox"
                                        checked={isChecked.house.incense}
                                        onChange={() => {
                                            if (isChecked.house.incense) {
                                                handleCheckboxChange("!incense")
                                            }
                                            else {
                                                handleCheckboxChange("incense")
                                            }
                                        }}
                                    />
                                </label>
                                <span className="register-subcategories-text">Encens</span>
                            </div>

                        </div>

                        {/* ACCESSORY */}
                        <div className="register-categories-partner">
                            <h3>Accessoire</h3>

                            <div className="register-categories-partner-row">
                                <label>
                                    <input type="checkbox"
                                        checked={isChecked.accessory.jewellery}
                                        onChange={() => {
                                            if (isChecked.accessory.jewellery) {
                                                handleCheckboxChange("!jewellery")
                                            }
                                            else {
                                                handleCheckboxChange("jewellery")
                                            }
                                        }}
                                    />
                                </label>
                                <span className="register-subcategories-text">Bijoux</span>
                            </div>

                            <div className="register-categories-partner-row">
                                <label>
                                    <input type="checkbox"
                                        checked={isChecked.accessory.bag}
                                        onChange={() => {
                                            if (isChecked.accessory.bag) {
                                                handleCheckboxChange("!bag")
                                            }
                                            else {
                                                handleCheckboxChange("bag")
                                            }
                                        }}
                                    />
                                </label>
                                <span className="register-subcategories-text">Maroquinerie</span>
                            </div>

                            <div className="register-categories-partner-row">
                                <label>
                                    <input type="checkbox"
                                        checked={isChecked.accessory.bumbag}
                                        onChange={() => {
                                            if (isChecked.accessory.bumbag) {
                                                handleCheckboxChange("!bumbag")
                                            }
                                            else {
                                                handleCheckboxChange("bumbag")
                                            }
                                        }}
                                    />
                                </label>
                                <span className="register-subcategories-text">Bananes Pochettes Trousses</span>
                            </div>


                            <div className="register-categories-partner-row">
                                <label>
                                    <input type="checkbox"
                                        checked={isChecked.accessory.scrunchie}
                                        onChange={() => {
                                            if (isChecked.accessory.scrunchie) {
                                                handleCheckboxChange("!scrunchie")
                                            }
                                            else {
                                                handleCheckboxChange("scrunchie")
                                            }
                                        }}
                                    />
                                </label>
                                <span className="register-subcategories-text">Chouchou</span>
                            </div>

                        </div>

                        {/* KIDS */}
                        <div className="register-categories-partner">
                            <h3>Enfants</h3>
                            <div className="register-categories-partner-row">
                                <label>
                                    <input type="checkbox"
                                        checked={isChecked.kids.binky}
                                        onChange={() => {
                                            if (isChecked.kids.binky) {
                                                handleCheckboxChange("!binky")
                                            }
                                            else {
                                                handleCheckboxChange("binky")
                                            }
                                        }}
                                    />
                                </label>
                                <span className="register-subcategories-text">Doudou</span>
                            </div>

                            <div className="register-categories-partner-row">
                                <label>
                                    <input type="checkbox"
                                        checked={isChecked.kids.nappy}
                                        onChange={() => {
                                            if (isChecked.kids.nappy) {
                                                handleCheckboxChange("!nappy")
                                            }
                                            else {
                                                handleCheckboxChange("nappy")
                                            }
                                        }}
                                    />
                                </label>
                                <span className="register-subcategories-text">Couches</span>
                            </div>

                        </div>

                        {/* SPORTS */}
                        <div className="register-categories-partner">
                            <h3>Sport</h3>

                            <div className="register-categories-partner-row">
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={isChecked.sport.checked}
                                        onChange={() => {
                                            if (isChecked.sport.checked) {
                                                handleCheckboxChange("!sport")
                                            }
                                            else {
                                                handleCheckboxChange("sport")
                                            }

                                        }}
                                    />
                                </label>
                            </div>

                        </div>

                        {/* SNEAKERS*/}
                        <div className="register-categories-partner">
                            <h3>Sneakers</h3>

                            <div className="register-categories-partner-row">
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={isChecked.sneakers.checked}
                                        onChange={() => {
                                            if (isChecked.sneakers.checked) {
                                                handleCheckboxChange("!sneakers")
                                            }
                                            else {
                                                handleCheckboxChange("sneakers")
                                            }

                                        }}
                                    />
                                </label>
                            </div>

                        </div>

                        {/* GAME*/}
                        <div className="register-categories-partner">
                            <h3>Ludique</h3>

                            <div className="register-categories-partner-row">
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={isChecked.game.checked}
                                        onChange={() => {
                                            if (isChecked.game.checked) {
                                                handleCheckboxChange("!game")
                                            }
                                            else {
                                                handleCheckboxChange("game")
                                            }

                                        }}
                                    />
                                </label>
                            </div>

                        </div>

                        {/* DRINK*/}
                        <div className="register-categories-partner">
                            <h3>Boisson</h3>
                            <div className="register-categories-partner-row">
                                <label>
                                    <input type="checkbox"
                                        checked={isChecked.drinks.tea}
                                        onChange={() => {
                                            if (isChecked.drinks.tea) {
                                                handleCheckboxChange("!tea")
                                            }
                                            else {
                                                handleCheckboxChange("tea")
                                            }
                                        }}
                                    />
                                </label>
                                <span className="register-subcategories-text">Thé et infusion</span>
                            </div>

                        </div>

                    </div>

                    <h2 className="register-subtitle">Labels</h2>
                    <div className="register-categories-wrapper">
                        <div className="register-label-row">
                            {labelOptions.map((label, index) => (
                                <div
                                    key={index}
                                    className={`register-label ${selectedLabels.includes(label) ? 'selected' : ''}`}
                                    onClick={() => toggleLabel(label)}
                                >
                                    {label}
                                </div>
                            ))}
                        </div>
                    </div>

                    <h2 className="register-subtitle">Informations profil</h2>

                    <div className="field-form">
                        <input
                            className="input-form"
                            type="text"
                            placeholder="Nom"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>

                    <div className="field-form">
                        <input
                            className="input-form"
                            type="text"
                            placeholder="Slogan"
                            value={slogan}
                            onChange={(e) => setSlogan(e.target.value)}
                        />
                    </div>

                    <div className="field-form">
                        <input
                            className="input-form"
                            type="text"
                            placeholder="Site internet"
                            value={website}
                            onChange={(e) => setWebsite(e.target.value)}
                        />
                    </div>

                    <div className="field-form">
                        <textarea
                            maxLength="400"
                            className="textarea-form"
                            placeholder="Description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                        <p className="register-form-input-length">{description.length}/400</p>
                    </div>

                    <div className="field-form">
                        <textarea
                            maxLength="400"
                            className="textarea-form"
                            placeholder="Engagements"
                            value={commitment}
                            onChange={(e) => setCommitment(e.target.value)}
                        />
                        <p className="register-form-input-length">{commitment.length}/400</p>
                    </div>

                    <div className="field-form">
                        <textarea
                            maxLength="400"
                            className="textarea-form"
                            placeholder="Mission"
                            value={mission}
                            onChange={(e) => setMission(e.target.value)}
                        />
                        <p className="register-form-input-length">{mission.length}/400</p>
                    </div>

                    <div className="field-form">
                        <textarea
                            maxLength="400"
                            className="textarea-form"
                            placeholder="Histoire"
                            value={history}
                            onChange={(e) => setHistory(e.target.value)}
                        />
                        <p className="register-form-input-length">{history.length}/400</p>
                    </div>

                    <div className="field-form">
                        <input
                            className="input-form"
                            type="number"
                            step=".01"
                            placeholder="Réduction en %"
                            value={promotionPercent}
                            onChange={(e) => setPromotionPercent(e.target.value)}
                        />
                    </div>

                    <div className="field-form">
                        <textarea
                            maxLength={70}
                            className="textarea-form"
                            placeholder="Description du code promo"
                            value={promotionDescription}
                            onChange={(e) => setPromotionDescription(e.target.value)}
                        />
                        <p className="register-form-input-length">{promotionDescription.length}/70</p>
                    </div>


                    <div className="field-form">
                        <input
                            className="input-form"
                            type="text"
                            placeholder="Code promotion"
                            value={promotionCode}
                            onChange={(e) => setPromotionCode(e.target.value)}
                        />
                    </div>

                    <div className="field-form">
                        <DatePicker
                            selected={promotionExpirationDate}
                            onChange={(date) => setPromotionExpirationDate(date)}
                            dateFormat="dd/MM/yyyy"
                            minDate={new Date()} // Only allow selecting dates from today onwards
                            placeholderText="Date d'expiration du code"
                            className="input-form"
                        />
                    </div>

                    <div className="field-form">
                        <input
                            className="input-form"
                            type="text"
                            placeholder="Lien d'affiliation"
                            value={promotionLink}
                            onChange={(e) => setPromotionLink(e.target.value)}
                        />
                    </div>

                    <div className="field-form">
                        <input
                            className="input-form"
                            type="text"
                            placeholder="Texte de description du popup"
                            value={promotionPopupText}
                            onChange={(e) => setPromotionPopupText(e.target.value)}
                        />
                    </div>


                    <h2 className="register-subtitle">Images</h2>

                    <div className="myProfil-logo-div">
                        {logo === null ?
                            <>
                                <HiOutlinePlus
                                    className="myProfil-plus-icon"
                                    color="white"
                                    size={35}
                                    onClick={() => {
                                        handleClick(inputLogoRef);
                                    }}
                                />
                                <div style={{ backgroundColor: "gray" }} className="myProfil-form-logo"><p style={{ fontSize: 14 }}>Logo</p></div>
                                <input
                                    type="file"
                                    name="logo"
                                    style={{ display: "none" }}
                                    onChange={(event) => {
                                        handleChange(event)
                                    }}
                                    onClick={(e) => {
                                        e.target.value = null
                                    }}
                                    ref={inputLogoRef}
                                    accept="image/png, image/gif, image/jpeg, .webp"
                                />

                            </>
                            :
                            <>
                                <img src={logo.url} alt="logo" className="myProfil-form-logo" />
                                <div
                                    className="modify-form-removePicture"
                                    onClick={() => {
                                        setLogo(null);
                                    }}
                                >
                                    <IoClose size={20} />
                                </div>

                            </>
                        }
                    </div>

                    <div className="myProfil-form-cover-picture-row">
                        <div className="myProfil-cover-picture-div">
                            {backgroundPicture === null ?
                                <>
                                    <HiOutlinePlus
                                        className="myProfil-plus-icon"
                                        color="white"
                                        size={35}
                                        onClick={() => {
                                            handleClick(inputBackgroundPictureRef);
                                        }}
                                    />
                                    <div style={{ backgroundColor: "gray" }} className="myProfil-form-cover-picture"><p style={{ fontSize: 14 }}>Bannière</p></div>
                                    <input
                                        type="file"
                                        name="backgroundPicture"
                                        style={{ display: "none" }}
                                        onChange={(event) => {
                                            handleChange(event)
                                        }}
                                        onClick={(e) => {
                                            e.target.value = null
                                        }}
                                        ref={inputBackgroundPictureRef}
                                        accept="image/png, image/gif, image/jpeg, .webp"
                                    />
                                </>
                                :
                                <>
                                    <img src={backgroundPicture.url} alt={user.name + " arrière plan"} className="myProfil-form-cover-picture" />
                                    <div
                                        className="modify-form-removePicture"
                                        onClick={() => {
                                            setBackgroundPicture(null);
                                        }}
                                    >
                                        <IoClose size={20} />
                                    </div>
                                </>
                            }

                        </div>

                    </div>

                    <div className="myProfil-modify-form-photos">
                        <div
                            className="myProfil-modify-photo modify-photos-1"
                            style={
                                file !== null
                                    ? {
                                        backgroundImage: `url(${file.url})`,
                                        backgroundPosition: "center",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "255px, 198px"
                                    } : null
                            }
                        >
                            <input
                                type="file"
                                name="image"
                                style={{ display: "none" }}
                                onChange={(event) => {
                                    handleChange(event)
                                }}
                                onClick={(e) => {
                                    e.target.value = null
                                }}
                                ref={inputFileRef1}
                                accept="image/png, image/gif, image/jpeg, .webp"
                            />
                            <p style={{ position: "absolute", top: 0, fontSize: 14 }}>Image 1</p>
                            {file === null ? (
                                <HiOutlinePlus
                                    className="createOffer-modify-form-photo-file-icon"
                                    onClick={() => {
                                        handleClick(inputFileRef1);
                                    }}
                                />
                            ) : <>
                                <div
                                    className="modify-form-removePicture"
                                    onClick={() => {
                                        setFile(null);
                                    }}
                                >
                                    <IoClose size={20} />
                                </div>
                            </>
                            }

                        </div>

                        <div
                            className="myProfil-modify-photo modify-photos-2"
                            style={
                                file2 !== null
                                    ? {
                                        backgroundImage: `url(${file2.url})`,
                                        backgroundPosition: "center",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "255px, 198px"
                                    }
                                    : null
                            }
                        >
                            <input
                                type="file"
                                name="image2"
                                style={{ display: "none" }}
                                onChange={(event) => {
                                    handleChange(event)
                                }}
                                onClick={(e) => {
                                    e.target.value = null
                                }}
                                ref={inputFileRef2}
                                accept="image/png, image/gif, image/jpeg, .webp"
                            />
                            <p style={{ position: "absolute", top: 0, fontSize: 14 }}>Image 2</p>

                            {file2 === null ? (
                                <HiOutlinePlus
                                    className="createOffer-modify-form-photo-file-icon"
                                    onClick={() => {
                                        handleClick(inputFileRef2);
                                    }}
                                />
                            ) : <>
                                <div
                                    className="modify-form-removePicture"
                                    onClick={() => {
                                        setFile2(null);
                                    }}
                                >
                                    <IoClose size={20} />
                                </div>
                            </>
                            }

                        </div>

                        <div
                            className="myProfil-modify-photo modify-photos-3"
                            style={
                                file3 !== null
                                    ? {
                                        backgroundImage: `url(${file3.url})`,
                                        backgroundPosition: "center",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "255px, 198px"
                                    }
                                    : null
                            }
                        >
                            <input
                                type="file"
                                name="image3"
                                style={{ display: "none" }}
                                onChange={(event) => {
                                    handleChange(event)
                                }}
                                onClick={(e) => {
                                    e.target.value = null
                                }}
                                ref={inputFileRef3}
                                accept="image/png, image/gif, image/jpeg, .webp"
                            />
                            <p style={{ position: "absolute", top: 0, fontSize: 14 }}>Image 3</p>

                            {file3 === null ? (
                                <HiOutlinePlus
                                    className="createOffer-modify-form-photo-file-icon"
                                    onClick={() => {
                                        handleClick(inputFileRef3);
                                    }}
                                />
                            ) : <>
                                <div
                                    className="modify-form-removePicture"
                                    onClick={() => {
                                        setFile3(null);
                                    }}
                                >
                                    <IoClose size={20} />
                                </div>
                            </>
                            }
                        </div>
                    </div>
                    {inProcess ?
                        <div className="register-submit-btn">
                            <Spinner color="#FFF" size={22} />
                        </div>
                        :
                        <button className="register-submit-btn" type="submit">Envoyer</button>
                    }
                </form>
            </div>
        </>
    );
};

export default NewPartner;
