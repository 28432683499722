import React, { useEffect, useState } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { Spinner } from "react-activity";
import "react-activity/dist/library.css";
import 'react-circular-progressbar/dist/styles.css';

const ProgressBarComponent = (item) => {


    const [overallOpenRatePourcentage, setOverallOpenRatePourcentage] = useState();
    const [websiteAccessRatePourcentage, setWebsiteAccessRatePourcentage] = useState()
    const [loaded, setLoaded] = useState(false)

    const calculateRate = () => {

        setWebsiteAccessRatePourcentage(((item.user.visitsCount / item.audienceNumber) * 100).toFixed(0))

        if (item.offerPublished === 0) {
            setOverallOpenRatePourcentage(0)
        }
        else {
            setOverallOpenRatePourcentage(((item.user.tapCardCount / (item.audienceNumber * item.offerPublished)) * 100).toFixed(0))
        }

        setLoaded(true)
    }

    useEffect(() => {
        if (item.audienceNumber !== undefined && item.offerPublished !== undefined) {
            calculateRate()
        }
    }, [item.audienceNumber]);

    return (
        <div className="progress-bar-container">
            <div className="progress-bar-card">
                <h2 className="chart-title">Taux d’ouverture global des communications</h2>
                <div className="circular-progress-bar">
                    {loaded ?
                        <CircularProgressbar value={overallOpenRatePourcentage} text={`${overallOpenRatePourcentage} %`}
                            styles={{
                                path: {
                                    stroke: "#2A7AF5",
                                },
                                trail: {
                                    stroke: "#D9D9D9"
                                },
                                text: {
                                    fill: "black",
                                    fontFamily: 'Figtree',
                                    fontStyle: 'normal',
                                    fontWeight: 700,
                                    fontSize: '22px',
                                },
                            }}
                        />
                        :
                        <Spinner size={120} color="#2A7AF5" />
                    }

                </div>
            </div>

            <div className="progress-bar-card">
                <h2 className="chart-title">Taux d’accès au site</h2>
                <div className="circular-progress-bar">
                    {loaded ?
                        <CircularProgressbar value={websiteAccessRatePourcentage} text={`${websiteAccessRatePourcentage} %`}
                            styles={{
                                path: {
                                    stroke: "#2A7AF5",
                                },
                                trail: {
                                    stroke: "#D9D9D9"
                                },
                                text: {
                                    fill: "black",
                                    fontFamily: 'Figtree',
                                    fontStyle: 'normal',
                                    fontWeight: 700,
                                    fontSize: '22px',
                                },
                            }}
                        />
                        :
                        <Spinner size={120} color="#2A7AF5" />
                    }
                </div>
            </div>
        </div>
    );
}

export default ProgressBarComponent;
