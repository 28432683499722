import React, { useState, useEffect } from "react";
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import { database } from "../../../firebase";
import { collection, getDocs } from "firebase/firestore";
import { Dots } from "react-activity";
import "react-activity/dist/library.css";

const Trafic = (item) => {
    const [totalUsers, setTotalUsers] = useState()
    const [loaded, setLoaded] = useState(false)

    const acessToSiteData = [
        { day: 'Jour 1', number: 40000 },
        { day: 'Jour 2', number: 15000 },
        { day: 'Jour 3', number: 18000 },
        { day: 'Jour 4', number: 10000 },
        { day: 'Jour 5', number: 40000 },
        { day: 'Jour 6', number: 14000 },
    ];

    const newUsersData = [
        { day: 'Jour 1', number: 100 },
        { day: 'Jour 2', number: 50 },
        { day: 'Jour 3', number: 60 },
        { day: 'Jour 4', number: 55 },
        { day: 'Jour 5', number: 120 },
        { day: 'Jour 6', number: 90 },
    ];

    const getUsersCount = async () => {
        const usersRef = collection(database, "users");
        const querySnapshot = await getDocs(usersRef);
        const count = querySnapshot.size;
        setTotalUsers(count)
    }

    useEffect(() => {

        const fetchData = async () => {
            try {
                await Promise.all([getUsersCount()]);
                setLoaded(true);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();

    }, []);


    return (

        <div className="stats-wrapper">
            <h1 className="preview-header-title">Aperçu du trafic</h1>

            <div className="offers-overview-row">
                <div className="offers-overview-card2">
                    <p className="offers-overview-card-text">Nombre d'utilisateurs ayant un compte</p>
                    <p className="offers-overview-card-number">{totalUsers}</p>
                </div>

                <div className="offers-overview-card2">
                    <p className="offers-overview-card-text">Nombre d'accès au site total</p>
                    <p className="offers-overview-card-number">{item.user.visitsCount}</p>
                </div>

                <div className="offers-overview-card2">
                    <p className="offers-overview-card-text">Nombre d’accès au site par utilisateur</p>
                    {loaded ?
                        <p className="offers-overview-card-number">{(item.user.visitsCount / totalUsers).toFixed(2)}</p>
                        :
                        <Dots size={25} color="#2A7AF5" />
                    }
                </div>
            </div>

            <div className="linechart-container">
                <h2 className="chart-title">Accès au site au fil du temps</h2>

                <LineChart width={950} height={350} data={acessToSiteData}>
                    <XAxis dataKey="day" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="number" name="Accès au site" stroke="#00BEFF" />
                </LineChart>
            </div>

            <div className="linechart-container">
                <h2 className="chart-title">Nouveaux utilisateurs au fil du temps</h2>

                <LineChart width={950} height={350} data={newUsersData}>
                    <XAxis dataKey="day" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="number" name="Nouveaux utilisateurs" stroke="#4D34E8" />
                </LineChart>
            </div>

        </div>
    );
}

export default Trafic;
