/*eslint-disable */
import './Admin.css'
import React, { useState } from 'react';
import Nav from "../../Nav"
import { database } from "../../../firebase";
import { useNavigate, useLocation } from "react-router-dom";
import { arrayUnion, arrayRemove, updateDoc, collection, doc, getDoc } from "firebase/firestore";
import { AiFillCloseCircle } from "react-icons/ai";
import { useUserAuth } from "../../../context/UserAuthContext";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { ClickAwayListener } from '@mui/base';

const PromotionText = () => {

    const dimension = {
        width: window.innerWidth,
        height: window.innerHeight,
    };

    const { user, logOut } = useUserAuth();
    const navigate = useNavigate();
    const location = useLocation()

    const partners = location.state.partners;
    const [showProfilPanel, setShowProfilPanel] = useState(false);
    const [selectedPartners, setSelectedPartners] = useState("");
    const [inputValues, setInputValues] = useState({
        input1: '',
        input2: '',
        input3: '',
        input4: ''
    });

    // Fonction pour mettre à jour les valeurs des inputs
    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // Copiez l'objet inputValues existant
        const updatedInputValues = { ...inputValues };

        // Mettez à jour la valeur de l'input correspondant dans l'objet
        updatedInputValues[name] = value;

        // Mettez à jour l'ensemble des valeurs des inputs
        setInputValues(updatedInputValues);
    };


    const handleClose = () => { // close modal profil
        setShowProfilPanel(false);
    };

    const handleLogout = async () => { // logout user
        try {
            await logOut();
            navigate("/");
            setTimeout(() => {
                console.clear()
            }, 250);
        } catch (error) {
            alert("Une erreur est survenue lors de votre déconnexion");
        }
    };

    const openAccountModal = () => {
        setShowProfilPanel(true)
    }

    const handleSelectPartner = (partner) => {
        setSelectedPartners(partner);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const documentRef = doc(database, 'partners', selectedPartners.data.uid);

            const textArray = [
                inputValues.input1,
                inputValues.input2,
                inputValues.input3,
            ]

            const updateArray = {
                'promotion.whyShopAtText': textArray,
            };

            const updatePopup ={
                'promotion.popupText': inputValues.input4,
            }

            await updateDoc(documentRef, updateArray);
            await updateDoc(documentRef, updatePopup);

            alert(`${selectedPartners.data.name} a été mis à jour !`);

        } catch (error) {
            // Gérez les erreurs ici
            console.error('Erreur lors de la mise à jour des catégories :', error);
        }
    };


    return (

        <>
            <Modal
                open={showProfilPanel}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                hideBackdrop={dimension.width > 768 ? true : false}
            >
                <>
                    <ClickAwayListener onClickAway={handleClose}>
                        <Box className={user.uid === "p5aTQsaMqPdnL7ynK7v9dJSdgH83" ? "myOffers-box-admin" : "myOffers-box"}>
                            <AiFillCloseCircle
                                className="myOffers-modal-close"
                                onClick={() => {
                                    handleClose();
                                }}
                            />
                            <div className="myOffers-modalContainer">
                                <div className="myOffers-modal-profilCircle">
                                    <p className="myOffers-modal-profiltext">
                                        {user.name !== undefined ? user.name[0] : null}
                                    </p>
                                </div>
                                <h2 className="myOffers-modal-title">
                                    {user.name !== undefined ? user.name : null}
                                </h2>
                            </div>
                            <div
                                className="myOffers-modal-param"
                                onClick={() => {
                                    navigate("/params");
                                }}
                            >
                                <p className="myOffers-modal-param-text">Paramètre du compte</p>
                            </div>

                            {user.uid === "p5aTQsaMqPdnL7ynK7v9dJSdgH83" &&
                                <>
                                    <div
                                        className="myOffers-modal-param"
                                        onClick={() => {
                                            navigate("/partners-management");
                                        }}
                                    >
                                        <p className="myOffers-modal-param-text">Gestion des partenaires</p>
                                    </div>

                                    <div
                                        className="myOffers-modal-param"
                                        onClick={() => {
                                            navigate("/promotions-management");
                                        }}
                                    >
                                        <p className="myOffers-modal-param-text">Gérer les promotions</p>
                                    </div>
                                </>
                            }

                            <div
                                onClick={() => {
                                    handleLogout();
                                }}
                                className="myOffers-modal-logoutBtn"
                            >
                                <p className="myOffers-modal-logoutText">Déconnexion</p>
                            </div>
                        </Box>
                    </ClickAwayListener>
                </>
            </Modal>

            <Nav user={user} onPress={openAccountModal} />

            <div className="selection-of-the-week-header">
                <h1 className="selection-of-the-week-title">Affectation des textes</h1>
            </div>

            <h2 style={{ fontFamily: "Geomatrix", paddingLeft: 15, paddingBottom: 15, }}>{selectedPartners !== "" ? selectedPartners.data.name : "Tous les partenaires"}</h2>

            {selectedPartners === "" ?
                <div className="selection-of-the-week-content">
                    <>
                        {partners.length === 0 ?
                            <div className="selection-of-the-week-dots">
                                <Dots color="#2A7AF5" size={30} />
                            </div>
                            :
                            <div className="selection-of-the-week-partners-wrap">
                                {partners.sort((a, b) =>
                                    a.data.name.localeCompare(b.data.name)
                                ).map((partner) => (
                                    <div key={partner.id}>
                                        <img src={partner.data.logo} alt={`${partner.data.name} logo`} className="selection-of-the-week-partners-logo" />
                                        <p>{partner.data.name}</p>
                                        <button className="selection-of-the-week-remove-partners" onClick={() => handleSelectPartner(partner)}>
                                            Sélectionner
                                        </button>
                                    </div>
                                ))}
                            </div>
                        }
                    </>
                </div>
                :
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                    <div className="promotion">
                        <h3>Textes par odre d'apparition </h3>

                        <form onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", marginTop: 20 }}>

                            <input
                                className="modify-form-input"
                                placeholder="Texte n°1"
                                type="text"
                                name="input1"
                                value={inputValues.input1}
                                onChange={handleInputChange}

                            />

                            <input
                                className="modify-form-input"
                                type="text"
                                placeholder="Texte n°2"
                                name="input2"
                                value={inputValues.input2}
                                onChange={handleInputChange}

                            />

                            <input
                                className="modify-form-input"
                                placeholder="Texte n°3"
                                type="text"
                                name="input3"
                                value={inputValues.input3}
                                onChange={handleInputChange}

                            />

                            <input
                                className="modify-form-input"
                                type="text"
                                placeholder="Texte popup"
                                name="input4"
                                value={inputValues.input4}
                                onChange={handleInputChange}

                            />

                            <button type="submit" className="promotion-submit-button">
                                Enregistrer
                            </button>

                        </form>

                    </div>

                    <div>
                        <h3>Exemple :</h3>
                        <img src="https://beeimg.com/images/i29752955853.png" style={{ width: 345, height: 470 }} />
                    </div>
                </div>

            }
        </>
    );
}

export default PromotionText;
