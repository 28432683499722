/*eslint-disable */
import "./MyProfil.css";
import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HiOutlinePlus, HiPencilAlt } from "react-icons/hi";
import { AiFillCloseCircle, AiFillEye } from "react-icons/ai";
import { IoClose } from "react-icons/io5";
import { BsChevronLeft } from "react-icons/bs";
import { Icon } from '@iconify/react';
import { useUserAuth } from "../../context/UserAuthContext";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { ClickAwayListener } from '@mui/base';
import { Dots } from "react-activity";
import "react-activity/dist/library.css";
import { database, auth } from "../../firebase";
import { doc, updateDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL, getStorage } from "firebase/storage";
import { Helmet } from "react-helmet";


import Nav from "../Nav"
import Faq from "react-faq-component";
import ImageCarousel from '../ImageCarousel';

const MyProfil = () => {
    const { user, logOut, updateUser } = useUserAuth();
    const navigate = useNavigate();
    const [showProfilPanel, setShowProfilPanel] = useState(false);

    const [logo, setLogo] = useState(null)
    const [backgroundPicture, setBackgroundPicture] = useState(null)
    const [name, setName] = useState(user.name);
    const [slogan, setSlogan] = useState(user.slogan);
    const [website, setWebsite] = useState(user.website);
    const [description, setDescription] = useState(user.description);
    const [history, setHistory] = useState(user.history);
    const [mission, setMission] = useState(user.mission);
    const [commitment, setCommitment] = useState(user.commitment);
    const [file, setFile] = useState(null);
    const [file2, setFile2] = useState(null);
    const [file3, setFile3] = useState(null);
    const [modifyProfil, setModifyProfil] = useState(false);
    const [selectedLabels, setSelectedLabels] = useState(user.labels);
    const inputFileRef1 = useRef(null);
    const inputFileRef2 = useRef(null);
    const inputFileRef3 = useRef(null);
    const inputLogoRef = useRef(null);
    const inputBackgroundPictureRef = useRef(null)
    const [loaded, setLoaded] = useState(false)
    const [isSubmiting, setIsSubmiting] = useState(false)
    const FaqData = {
        rows: [
            {
                title: "La marque",
                content: description !== user.description ? description : user.description
            },
            {
                title: "Engagements",
                content: commitment !== user.commitment ? commitment : user.commitment
            },
            {
                title: "Mission",
                content: mission !== user.mission ? mission : user.mission
            },
            {
                title: "Histoire",
                content: history !== user.history ? history : user.history
            },
        ],
    };
    const initialLabels = [
        'Made in France',
        'Made in Europe',
        'Bio',
        'Éthique',
        'Vegan',
        'Recyclé',
        'Zéro déchets',
        'Faible en CO2',
        '100 % Naturel',
        'Upcycling',
        'Artisanal',
        'Zéro plastique',
        'Circuit court',
        'Précommande',
        'Co-création',
        'Durable',
        'Recyclable',
        'Produits naturels',
        'Packaging recyclable',
        'Produits solides',
        'Biodégradable',
        'Cruelty free',
        'Rechargeable',
        'Engagements associatifs'
    ];

    const CarouselImages = [
        backgroundPicture === null ? user.picture : backgroundPicture.url,
        file === null ? user.picture1 : file.url,
        file2 === null ? user.picture2 : file2.url,
        file3 === null ? user.picture3 : file3.url,
    ];

    const regexUrl = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&\/=]*)$/

    const dimension = {
        width: window.innerWidth,
        height: window.innerHeight,
    };

    const toggleLabel = (label) => {
        if (selectedLabels.includes(label)) {
            setSelectedLabels(selectedLabels.filter(item => item !== label));

        } else {
            setSelectedLabels([...selectedLabels, label]);
        }
    };

    const handleClose = () => { // close modal profil
        setShowProfilPanel(false);
    };

    const handleLogout = async () => { // logout user
        try {
            await logOut();
            navigate("/");
            setTimeout(() => {
                console.clear()
            }, 250);
        } catch (error) {
            alert("Une erreur est survenue lors de votre déconnexion");
        }
    };

    const handleClick = (ref) => {  // create a useRef of the file click
        ref.current.click();
    };

    const handleChange = (e) => { // Verify size of the image & mutate file state
        if (e.target.files[0].type !== "image/png" && e.target.files[0].type !== "image/jpeg" && e.target.files[0].type !== "image/webp") {
            alert("Vous devez sélectionner un fichier de type image (jpg/jpeg/png/webp)")
        }

        else {

            if (e.target.files[0].size >= 5000000) {
                alert("Le fichier est trop volumineux");
            }

            else {
                if (e.currentTarget.name === "image") {

                    let file = e.target.files[0];
                    file.url = URL.createObjectURL(e.target.files[0]);
                    file.category = "picture1"
                    file.storageName = `${user.uid}_picture1`
                    setFile(file);
                }
                if (e.currentTarget.name === "image2") {
                    let file = e.target.files[0];
                    file.url = URL.createObjectURL(e.target.files[0]);
                    file.category = "picture2"
                    file.storageName = `${user.uid}_picture2`
                    setFile2(file);
                }
                if (e.currentTarget.name === "image3") {
                    let file = e.target.files[0];
                    file.url = URL.createObjectURL(e.target.files[0]);
                    file.category = "picture3"
                    file.storageName = `${user.uid}_picture3`
                    setFile3(file);
                }
                if (e.currentTarget.name === "backgroundPicture") {
                    let file = e.target.files[0];
                    file.url = URL.createObjectURL(e.target.files[0]);
                    file.category = "banner"
                    file.storageName = `${user.uid}_banner`
                    setBackgroundPicture(file);
                }

                if (e.currentTarget.name === "logo") {
                    let file = e.target.files[0];
                    file.url = URL.createObjectURL(e.target.files[0]);
                    file.category = "logo"
                    file.storageName = `${user.uid}_logo`
                    setLogo(file);
                }
            }
        }
    };

    const UploadMultiplePhotos = async () => {
        let photos = []
        const files = []
        const storage = getStorage()
        files.push(logo, backgroundPicture, file, file2, file3)

        for (let i = 0; i < files.length; i++) {
            const element = files[i];

            if (element !== null) {
                const storageRef = ref(storage, `${user.uid}/images/${element.storageName}`);
                const snapshot = await uploadBytesResumable(storageRef, element)
                const downloadURL = await getDownloadURL(snapshot.ref)
                const item = {
                    url: downloadURL,
                    category: element.category,
                }
                photos.push(item)
            }
        }
        return photos
    }

    const onSubmitForm = async () => {
        setIsSubmiting(true)
        const userData = auth.currentUser;
        const itemRef = doc(database, "partners", userData.uid);
        if (logo === null && backgroundPicture === null && file === null && file2 === null && file3 === null) {

            try {
                updateDoc(itemRef, {
                    description: description !== user.description ? description : user.description,
                    slogan: slogan !== user.slogan ? slogan : user.slogan,
                    name: name !== user.name ? name : user.name,
                    website: website !== user.website ? website : user.website,
                    mission: mission !== user.mission ? mission : user.mission,
                    history: history !== user.history ? history : user.history,
                    commitment: commitment !== user.commitment ? commitment : user.commitment,
                    labels: selectedLabels
                });
                const data = {
                    description: description,
                    slogan: slogan,
                    name: name,
                    website: website,
                    mission: mission,
                    history: history,
                    commitment: commitment,
                    labels: selectedLabels,
                    logo: user.logo,
                    picture: user.picture,
                    picture1: user.picture1,
                    picture2: user.picture2,
                    picture3: user.picture3
                }
                updateUser(data)
                setIsSubmiting(false)
                setTimeout(() => {
                    alert("Profil mis à jour");
                }, 250);
            } catch (error) {
                setIsSubmiting(false)
                setTimeout(() => {
                    alert("La modification du profil a échouée")
                }, 250);
            }
        }
        else { // Img has been edited (logo,background,picture 1-2-3)

            const photos = await UploadMultiplePhotos()
            const userData = {
                logo: "",
                banner: "",
                picture1: "",
                picture2: "",
                picture3: ""
            }

            let forEachExecuted = new Promise((resolve, reject) => {
                photos.forEach((element, index, array) => {

                    if (element.category === "logo") {
                        userData.logo = element.url
                    }
                    if (element.category === "banner") {
                        userData.banner = element.url
                    }
                    if (element.category === "picture1") {
                        userData.picture1 = element.url
                    }
                    if (element.category === "picture2") {
                        userData.picture2 = element.url
                    }
                    if (element.category === "picture3") {
                        userData.picture3 = element.url
                    }
                    if (index === array.length - 1) resolve();  // Loop is over
                })
            })

            forEachExecuted.then(() => {

                const data = {
                    description: description !== user.description ? description : user.description,
                    slogan: slogan !== user.slogan ? slogan : user.slogan,
                    name: name !== user.name ? name : user.name,
                    website: website !== user.website ? website : user.website,
                    mission: mission !== user.mission ? mission : user.mission,
                    history: history !== user.history ? history : user.history,
                    commitment: commitment !== user.commitment ? commitment : user.commitment,
                    labels: selectedLabels,
                    logo: userData.logo !== "" ? userData.logo : user.logo,
                    picture: userData.banner !== "" ? userData.banner : user.picture,
                    picture1: userData.picture1 !== "" ? userData.picture1 : user.picture1,
                    picture2: userData.picture2 !== "" ? userData.picture2 : user.picture2,
                    picture3: userData.picture3 !== "" ? userData.picture3 : user.picture3,
                }

                try {

                    updateUser(data)
                    updateDoc(itemRef, {
                        description: description !== user.description ? description : user.description,
                        slogan: slogan !== user.slogan ? slogan : user.slogan,
                        name: name !== user.name ? name : user.name,
                        website: website !== user.website ? website : user.website,
                        mission: mission !== user.mission ? mission : user.mission,
                        history: history !== user.history ? history : user.history,
                        commitment: commitment !== user.commitment ? commitment : user.commitment,
                        labels: selectedLabels,
                        logo: userData.logo !== "" ? userData.logo : user.logo,
                        picture: userData.banner !== "" ? userData.banner : user.picture,
                        picture1: userData.picture1 !== "" ? userData.picture1 : user.picture1,
                        picture2: userData.picture2 !== "" ? userData.picture2 : user.picture2,
                        picture3: userData.picture3 !== "" ? userData.picture3 : user.picture3,
                    });
                    setIsSubmiting(false)
                    setLogo(null)
                    setBackgroundPicture(null)
                    setFile(null)
                    setFile2(null)
                    setFile3(null)
                    setTimeout(() => {
                        alert("Profil mis à jour");
                    }, 250);


                } catch (error) {
                    setIsSubmiting(false)
                    setTimeout(() => {
                        alert("La modification du profil a échouée")
                    }, 250);

                }
            })

        }

    }

    const openAccountModal = () => {
        setShowProfilPanel(true)
    }

    useEffect(() => {
        if (auth.currentUser !== null) {  // Verify that AuthContext return user data
            setLoaded(true)
        }
    }, [auth.currentUser]);

    return (
        <>
            <Helmet>
                <title>Sharlockpro - Mon profil</title>
                <meta name="keywords" content="sharlockpro, profil, Sharlockpro.fr" />
                <meta name="description" content="Accéder à votre profil sur sharlockpro" />
            </Helmet>

            <Modal
                open={showProfilPanel}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                hideBackdrop={dimension.width > 768 ? true : false}
            >
                <>
                    <ClickAwayListener onClickAway={handleClose}>
                        <Box className={user.uid === "p5aTQsaMqPdnL7ynK7v9dJSdgH83" ? "myOffers-box-admin" : "myOffers-box"}>
                            <AiFillCloseCircle
                                className="myOffers-modal-close"
                                onClick={() => {
                                    handleClose();
                                }}
                            />
                            <div className="myOffers-modalContainer">
                                <div className="myOffers-modal-profilCircle">
                                    <p className="myOffers-modal-profiltext">
                                        {user.name !== undefined ? user.name[0] : null}
                                    </p>
                                </div>
                                <h2 className="myOffers-modal-title">
                                    {user.name !== undefined ? user.name : null}
                                </h2>
                            </div>
                            <div
                                className="myOffers-modal-param"
                                onClick={() => {
                                    navigate("/params");
                                }}
                            >
                                <p className="myOffers-modal-param-text">Paramètre du compte</p>
                            </div>

                            {user.uid === "p5aTQsaMqPdnL7ynK7v9dJSdgH83" &&
                                <>
                                    <div
                                        className="myOffers-modal-param"
                                        onClick={() => {
                                            navigate("/partners-management");
                                        }}
                                    >
                                        <p className="myOffers-modal-param-text">Gestion des partenaires</p>
                                    </div>

                                    <div
                                        className="myOffers-modal-param"
                                        onClick={() => {
                                            navigate("/promotions-management");
                                        }}
                                    >
                                        <p className="myOffers-modal-param-text">Gérer les promotions</p>
                                    </div>
                                </>
                            }

                            <div
                                onClick={() => {
                                    handleLogout();
                                }}
                                className="myOffers-modal-logoutBtn"
                            >
                                <p className="myOffers-modal-logoutText">Déconnexion</p>
                            </div>
                        </Box>
                    </ClickAwayListener>
                </>
            </Modal>

            <Nav user={user} onPress={openAccountModal} />

            <div className="myOffers-main">
                <div className="myOffers-main-row">
                    <div className="myOffers-main-col-1">
                        <h1 className="myOffers-mainTitle">Mon profil</h1>
                        <p className="myOffers-mainSubtitle">
                            Modifiez votre profil sur Sharlock
                        </p>
                    </div>

                    <div className="myProfil-modify-btn" onClick={() => setModifyProfil(!modifyProfil)}>
                        {modifyProfil ?
                            <AiFillEye color="white" size={20} />
                            :
                            <HiPencilAlt color="white" size={20} />
                        }
                        <p className="myProfil-modify-btn-text">{modifyProfil ? "Voir mon profil" : "Modifier mon profil"}</p>
                    </div>
                </div>

                {modifyProfil ?
                    /* MODIFICATION DU PROFIL */
                    <div className="myProfil-container-list">
                        <div className="myProfil-wrapper">
                            <div className="myProfil-content-wrapper ">
                                <div className="myProfil-col-1">
                                    <div className="myProfil-form">
                                        <div className="myProfil-form-first-section">
                                            <label className="myProfil-form-label">
                                                Logo
                                            </label>
                                            <div className="myProfil-logo-div">
                                                {logo === null ?
                                                    <>
                                                        <HiOutlinePlus
                                                            className="myProfil-plus-icon"
                                                            color="white"
                                                            size={35}
                                                            onClick={() => {
                                                                handleClick(inputLogoRef);
                                                            }}
                                                        />
                                                        <img src={user.logo} alt="logo" className="myProfil-form-logo" />
                                                        <input
                                                            type="file"
                                                            name="logo"
                                                            style={{ display: "none" }}
                                                            onChange={(event) => {
                                                                handleChange(event)
                                                            }}
                                                            onClick={(e) => {
                                                                e.target.value = null
                                                            }}
                                                            ref={inputLogoRef}
                                                            accept="image/png, image/gif, image/jpeg, .webp"
                                                        />
                                                    </>
                                                    :
                                                    <>
                                                        <img src={logo.url} alt="logo" className="myProfil-form-logo" />
                                                        <div
                                                            className="modify-form-removePicture"
                                                            onClick={() => {
                                                                setLogo(null);
                                                            }}
                                                        >
                                                            <IoClose size={20} />
                                                        </div>

                                                    </>
                                                }
                                            </div>

                                            <label className="myProfil-form-label">
                                                Photo de couverture
                                            </label>
                                            <div className="myProfil-form-cover-picture-row">
                                                <div className="myProfil-cover-picture-div">
                                                    {backgroundPicture === null ?
                                                        <>
                                                            <HiOutlinePlus
                                                                className="myProfil-plus-icon"
                                                                color="white"
                                                                size={35}
                                                                onClick={() => {
                                                                    handleClick(inputBackgroundPictureRef);
                                                                }}
                                                            />
                                                            <img src={user.picture} alt={user.name + " arrière plan"} className="myProfil-form-cover-picture" />
                                                            <input
                                                                type="file"
                                                                name="backgroundPicture"
                                                                style={{ display: "none" }}
                                                                onChange={(event) => {
                                                                    handleChange(event)
                                                                }}
                                                                onClick={(e) => {
                                                                    e.target.value = null
                                                                }}
                                                                ref={inputBackgroundPictureRef}
                                                                accept="image/png, image/gif, image/jpeg, .webp"
                                                            />
                                                        </>
                                                        :
                                                        <>
                                                            <img src={backgroundPicture.url} alt={user.name + " arrière plan"} className="myProfil-form-cover-picture" />
                                                            <div
                                                                className="modify-form-removePicture"
                                                                onClick={() => {
                                                                    setBackgroundPicture(null);
                                                                }}
                                                            >
                                                                <IoClose size={20} />
                                                            </div>
                                                        </>
                                                    }

                                                </div>

                                            </div>

                                            <label className="myProfil-form-label">
                                                Catégories
                                            </label>
                                            <div className="myProfil-labels-container">
                                                {initialLabels.map((label, index) => (
                                                    <div
                                                        key={index}
                                                        className={`myProfil-labels-btn ${selectedLabels.includes(label) ? 'selected' : ''}`}
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => toggleLabel(label)}
                                                    >
                                                        <span className="myProfil-labels-text">{label}</span>
                                                    </div>
                                                ))}
                                            </div>

                                            <label className="myProfil-form-label">Nom de marque</label>
                                            <input
                                                className="myProfil-form-input-editable"
                                                type="text"
                                                name="name"
                                                value={name}
                                                onChange={(e) => {
                                                    setName(e.currentTarget.value);
                                                }}
                                                maxLength="50"
                                            />
                                            <p className="myProfil-form-input-length">{name.length}/50</p>

                                            <label className="myProfil-form-label">Slogan</label>
                                            <input
                                                className="myProfil-form-input-editable"
                                                type="text"
                                                name="slogan"
                                                value={slogan}
                                                onChange={(e) => {
                                                    setSlogan(e.currentTarget.value);
                                                }}
                                                maxLength="50"
                                            />
                                            <p className="myProfil-form-input-length">{slogan.length}/50</p>

                                            <label className="myProfil-form-label">Site web</label>
                                            <input
                                                className={regexUrl.test(website) === false ? "myProfil-form-input-editable-error" : "myProfil-form-input-editable"}
                                                type="text"
                                                name="Site web"
                                                value={website}
                                                onChange={(e) => {
                                                    setWebsite(e.currentTarget.value);
                                                }}
                                            />
                                            {website !== "" && regexUrl.test(website) === false ? (
                                                <p className="myProfil-form-input-error">L'url saisi est invalide</p>
                                            ) : null
                                            }
                                            <h2 className="myProfil-form-title2">Description</h2>

                                            <label className="myProfil-form-label">La marque</label>
                                            <textarea
                                                className="myProfil-form-textarea-editable"
                                                name="description"
                                                value={description}
                                                onChange={(e) => {
                                                    setDescription(e.currentTarget.value);
                                                }}
                                                maxLength="400"
                                            ></textarea>
                                            <p className="myProfil-form-input-length">{description.length}/400</p>

                                            <label className="myProfil-form-label">Engagements</label>
                                            <textarea
                                                className="myProfil-form-textarea-editable"
                                                name="commitment"
                                                value={commitment}
                                                onChange={(e) => {
                                                    setCommitment(e.currentTarget.value);
                                                }}
                                                maxLength="400"
                                            ></textarea>
                                            <p className="myProfil-form-input-length">{commitment.length}/400</p>

                                            <label className="myProfil-form-label">Mission</label>
                                            <textarea
                                                className="myProfil-form-textarea-editable"
                                                name="mission"
                                                value={mission}
                                                onChange={(e) => {
                                                    setMission(e.currentTarget.value);
                                                }}
                                                maxLength="400"
                                            ></textarea>
                                            <p className="myProfil-form-input-length">{mission.length}/400</p>

                                            <label className="myProfil-form-label">Histoire</label>
                                            <textarea
                                                className="myProfil-form-textarea-editable"
                                                name="history"
                                                value={history}
                                                onChange={(e) => {
                                                    setHistory(e.currentTarget.value);
                                                }}
                                                maxLength="400"
                                            ></textarea>
                                            <p className="myProfil-form-input-length">{history.length}/400</p>

                                        </div>
                                    </div>
                                </div>

                                <div className="createOffer-preview-iphone-container">
                                    <div className="preview-iphone">
                                        <p className="preview-iphone-clock">
                                            {new Date().getHours() + ":"}
                                            {new Date().getMinutes() < 10
                                                ? "0" + new Date().getMinutes()
                                                : new Date().getMinutes()}
                                        </p>

                                        <div className="preview-iphone-actions-wrapper-profil">
                                            <div className="preview-iphone-actions-btn-goshop" onClick={() => { window.open(user.website, '_blank') }}>
                                                <Icon icon="fluent-mdl2:website" color="#FFF" style={{ fontSize: 25, marginRight: 10 }} />
                                                <p className="preview-iphone-actions-btn-goshop-text">Le site de {user.name}</p>
                                            </div>
                                        </div>

                                        <div className="preview-iphone-top-bar">
                                            <BsChevronLeft size={20} style={{ marginLeft: 5 }} />
                                            <p className="preview-iphone-partner-name">{name !== "" && name !== user.name ? name : user.name}</p>
                                            <div>
                                                <Icon icon="iconamoon:like-light" color="#1B1B29" style={{ fontSize: 25, marginRight: 10 }} />
                                                <Icon icon="ph:share-fat" color="#1B1B29" style={{ fontSize: 23, marginRight: 5 }} />
                                            </div>
                                        </div>


                                        <div className="preview-iphone-scrollable">

                                            <div className="preview-iphone-scrollable-elements">
                                                <ImageCarousel images={CarouselImages} />
                                            </div>

                                            <div className="preview-iphone-scrollable-elements pb-120">
                                                <div className="preview-iphone-profil-box-container">
                                                    <img
                                                        src={logo !== null ? logo.url : user.logo}
                                                        alt={user.name}
                                                        className="preview-iphone-profil-box-logo"
                                                    />
                                                    <div className="preview-iphone-profil-column">
                                                        <h3 className="preview-iphone-scrollable-profil-element-partner">{name !== "" ? name : user.name}</h3>
                                                        <p className="preview-iphone-scrollable-profil-element-partner-slogan">{slogan !== "" ? slogan : user.slogan}</p>
                                                    </div>
                                                </div>


                                                <div className="preview-iphone-scrollable-profil-labels-container">
                                                    {selectedLabels.map((label, index) => (
                                                        <div
                                                            key={index}
                                                            className={`myProfil-labels-btn`}
                                                            onClick={() => toggleLabel(label)}
                                                        >
                                                            <span className="myProfil-labels-text">{label}</span>
                                                        </div>
                                                    ))}
                                                </div>


                                                <div className="preview-iphone-scrollable-element-profil-description">
                                                    <Faq
                                                        data={FaqData}
                                                        styles={{
                                                            bgColor: '#FEF7EA',
                                                            rowContentPaddingLeft: '10px',
                                                            rowContentPaddingRight: '10px',
                                                            rowContentPaddingBottom: '15px',
                                                            rowContentTextSize: "14px"
                                                        }}

                                                    />
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    {file !== null || file2 !== null || file3 !== null || logo !== null || backgroundPicture !== null || slogan !== user.slogan || description !== user.description || name !== user.name || website !== user.website && regexUrl.test(website) == true || history !== user.history || mission !== user.mission || commitment !== user.commitment || !selectedLabels.every(label => user.labels.includes(label)) ||
                                        !user.labels.every(label => selectedLabels.includes(label)) && loaded ?
                                        <>
                                            {isSubmiting ?
                                                <div className="myProfil-modify-form-btn">
                                                    <Dots color="#AA80F9" size={30} />
                                                </div>
                                                :
                                                <div className="myProfil-modify-form-btn" onClick={() => onSubmitForm()}>
                                                    <p className="myProfil-modify-form-btn-text">Modifier mon profil</p>
                                                </div>
                                            }
                                        </>
                                        :
                                        <div className="myProfil-modify-form-btn-disabled">
                                            <p className="myProfil-modify-form-btn-text">Modifier mon profil</p>
                                        </div>
                                    }
                                </div>

                            </div>
                        </div>

                        <div className="myProfil-form-photos-container">
                            <label className="myProfil-form-label">
                                Photos
                            </label>
                            <div className="myProfil-modify-form-photos">
                                <div
                                    className="myProfil-modify-photo modify-photos-1"
                                    style={
                                        file !== null
                                            ? {
                                                backgroundImage: `url(${file.url})`,
                                                backgroundPosition: "center",
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "255px, 198px"
                                            } : {
                                                backgroundImage: `url(${user.picture1})`,
                                                backgroundPosition: "center",
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "255px, 198px"
                                            }
                                    }
                                >
                                    <input
                                        type="file"
                                        name="image"
                                        style={{ display: "none" }}
                                        onChange={(event) => {
                                            handleChange(event)
                                        }}
                                        onClick={(e) => {
                                            e.target.value = null
                                        }}
                                        ref={inputFileRef1}
                                        accept="image/png, image/gif, image/jpeg, .webp"
                                    />
                                    {file === null ? (
                                        <HiOutlinePlus
                                            className="createOffer-modify-form-photo-file-icon"
                                            onClick={() => {
                                                handleClick(inputFileRef1);
                                            }}
                                        />
                                    ) : <>
                                        <div
                                            className="modify-form-removePicture"
                                            onClick={() => {
                                                setFile(null);
                                            }}
                                        >
                                            <IoClose size={20} />
                                        </div>
                                    </>
                                    }

                                </div>

                                <div
                                    className="myProfil-modify-photo modify-photos-2"
                                    style={
                                        file2 !== null
                                            ? {
                                                backgroundImage: `url(${file2.url})`,
                                                backgroundPosition: "center",
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "255px, 198px"
                                            }
                                            : {
                                                backgroundImage: `url(${user.picture2})`,
                                                backgroundPosition: "center",
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "255px, 198px"
                                            }
                                    }
                                >
                                    <input
                                        type="file"
                                        name="image2"
                                        style={{ display: "none" }}
                                        onChange={(event) => {
                                            handleChange(event)
                                        }}
                                        onClick={(e) => {
                                            e.target.value = null
                                        }}
                                        ref={inputFileRef2}
                                        accept="image/png, image/gif, image/jpeg, .webp"
                                    />
                                    {file2 === null ? (
                                        <HiOutlinePlus
                                            className="createOffer-modify-form-photo-file-icon"
                                            onClick={() => {
                                                handleClick(inputFileRef2);
                                            }}
                                        />
                                    ) : <>
                                        <div
                                            className="modify-form-removePicture"
                                            onClick={() => {
                                                setFile2(null);
                                            }}
                                        >
                                            <IoClose size={20} />
                                        </div>
                                    </>
                                    }

                                </div>

                                <div
                                    className="myProfil-modify-photo modify-photos-3"
                                    style={
                                        file3 !== null
                                            ? {
                                                backgroundImage: `url(${file3.url})`,
                                                backgroundPosition: "center",
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "255px, 198px"
                                            }
                                            : {
                                                backgroundImage: `url(${user.picture3})`,
                                                backgroundPosition: "center",
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "255px, 198px"
                                            }
                                    }
                                >
                                    <input
                                        type="file"
                                        name="image3"
                                        style={{ display: "none" }}
                                        onChange={(event) => {
                                            handleChange(event)
                                        }}
                                        onClick={(e) => {
                                            e.target.value = null
                                        }}
                                        ref={inputFileRef3}
                                        accept="image/png, image/gif, image/jpeg, .webp"
                                    />
                                    {file3 === null ? (
                                        <HiOutlinePlus
                                            className="createOffer-modify-form-photo-file-icon"
                                            onClick={() => {
                                                handleClick(inputFileRef3);
                                            }}
                                        />
                                    ) : <>
                                        <div
                                            className="modify-form-removePicture"
                                            onClick={() => {
                                                setFile3(null);
                                            }}
                                        >
                                            <IoClose size={20} />
                                        </div>
                                    </>
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                    :
                    /* PROFIL READ ONLY */
                    <div className="myProfil-container-list">
                        <div className="myProfil-wrapper">
                            <div className="myProfil-content-wrapper">
                                <div className="myProfil-col-1">
                                    <div className="myProfil-form">
                                        <div className="myProfil-form-first-section">
                                            <label className="myProfil-form-label">
                                                Logo
                                            </label>
                                            <img src={user.logo} alt="logo" className="myProfil-form-logo" />

                                            <label className="myProfil-form-label">
                                                Photo de couverture
                                            </label>
                                            <div className="myProfil-form-cover-picture-row">
                                                <img src={user.picture} alt={user.name + "arriere plan"} className="myProfil-form-cover-picture" />
                                            </div>

                                            {user.labels.length > 0 &&
                                                <>
                                                    <label className="myProfil-form-label">
                                                        Catégories
                                                    </label>
                                                    <div className="myProfil-labels-container">
                                                        {user.labels.map((label, index) => (
                                                            <div key={index} className="myProfil-labels-btn">
                                                                <span className="myProfil-labels-text">{label}</span>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </>
                                            }

                                            <label className="myProfil-form-label">Nom de marque</label>
                                            <input
                                                className="myProfil-form-input"
                                                type="text"
                                                name="name"
                                                value={user.name}
                                                readOnly="readOnly"
                                            />

                                            <label className="myProfil-form-label">Slogan</label>
                                            <input
                                                className="myProfil-form-input"
                                                type="text"
                                                name="slogan"
                                                value={user.slogan}
                                                readOnly="readOnly"
                                            />

                                            <label className="myProfil-form-label">Site web</label>
                                            <input
                                                className="myProfil-form-input"
                                                type="text"
                                                name="Site web"
                                                value={user.website}
                                                readOnly="readOnly"
                                            />

                                            <h2 className="myProfil-form-title2">Description</h2>

                                            <label className="myProfil-form-label">La marque</label>
                                            <textarea
                                                className="myProfil-form-textarea"
                                                name="description"
                                                value={user.description}
                                                readOnly="readOnly"
                                            ></textarea>

                                            <label className="myProfil-form-label">Engagements</label>
                                            <textarea
                                                className="myProfil-form-textarea"
                                                name="commitment"
                                                value={user.commitment}
                                                readOnly="readOnly"
                                            ></textarea>

                                            <label className="myProfil-form-label">Mission</label>
                                            <textarea
                                                className="myProfil-form-textarea"
                                                name="mission"
                                                value={user.mission}
                                                readOnly="readOnly"
                                            ></textarea>

                                            <label className="myProfil-form-label">Histoire</label>
                                            <textarea
                                                className="myProfil-form-textarea"
                                                name="history"
                                                value={user.history}
                                                readOnly="readOnly"
                                            ></textarea>

                                        </div>
                                    </div>
                                </div>

                                <div className="createOffer-preview-iphone-container">
                                    <div className="preview-iphone">
                                        <p className="preview-iphone-clock">
                                            {new Date().getHours() + ":"}
                                            {new Date().getMinutes() < 10
                                                ? "0" + new Date().getMinutes()
                                                : new Date().getMinutes()}
                                        </p>

                                        <div className="preview-iphone-actions-wrapper-profil">
                                            <div className="preview-iphone-actions-btn-goshop" onClick={() => { window.open(user.website, '_blank') }}>
                                                <Icon icon="fluent-mdl2:website" color="#FFF" style={{ fontSize: 25, marginRight: 10 }} />
                                                <p className="preview-iphone-actions-btn-goshop-text">Le site de {user.name}</p>
                                            </div>
                                        </div>

                                        <div className="preview-iphone-top-bar">
                                            <BsChevronLeft size={20} style={{ marginLeft: 5 }} />
                                            <p className="preview-iphone-partner-name">{user.name}</p>
                                            <div>
                                                <Icon icon="iconamoon:like-light" color="#1B1B29" style={{ fontSize: 25, marginRight: 10 }} />
                                                <Icon icon="ph:share-fat" color="#1B1B29" style={{ fontSize: 23, marginRight: 5 }} />
                                            </div>
                                        </div>

                                        <div className="preview-iphone-scrollable-container">

                                            <div className="preview-iphone-scrollable pb-120">

                                                <div className="preview-iphone-scrollable-elements">
                                                    <ImageCarousel images={CarouselImages} />
                                                </div>

                                                <div className="preview-iphone-scrollable-elements">
                                                    <div className="preview-iphone-profil-box-container">
                                                        <img
                                                            src={logo !== null ? logo.url : user.logo}
                                                            alt={user.name}
                                                            className="preview-iphone-profil-box-logo"
                                                        />
                                                        <div className="preview-iphone-profil-column">
                                                            <h3 className="preview-iphone-scrollable-profil-element-partner">{name !== "" ? name : user.name}</h3>
                                                            <p className="preview-iphone-scrollable-profil-element-partner-slogan">{slogan !== "" ? slogan : user.slogan}</p>
                                                        </div>
                                                    </div>

                                                    <div className="preview-iphone-scrollable-profil-labels-container">
                                                        {user.labels.map((label, index) => (
                                                            <div
                                                                key={index}
                                                                className={'myProfil-labels-btn'}
                                                                onClick={() => toggleLabel(label)}
                                                            >
                                                                <span className="myProfil-labels-text">{label}</span>
                                                            </div>
                                                        ))}
                                                    </div>


                                                    <div className="preview-iphone-scrollable-element-profil-description">
                                                        <Faq
                                                            data={FaqData}
                                                            styles={{
                                                                bgColor: '#FEF7EA',
                                                                rowContentPaddingLeft: '10px',
                                                                rowContentPaddingRight: '10px',
                                                                rowContentPaddingBottom: '15px',
                                                                rowContentTextSize: "14px"
                                                            }}

                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <label className="myProfil-form-label">
                            Photos
                        </label>
                        <div className="myProfil-modify-form-photos">
                            <img src={user.picture1} className="myProfil-modify-photo" alt={user.name + " image n°1"} />
                            <img src={user.picture2} className="myProfil-modify-photo" alt={user.name + " image n°2"} />
                            <img src={user.picture3} className="myProfil-modify-photo" alt={user.name + " image n°3"} />
                        </div>

                    </div>
                }

            </div >
        </>
    );
}


export default MyProfil;
