import "./Delete.css";
import React from "react";

const Delete = (item) => {
  return (
    <div className="delete-container">
      <h1 className="delete-title">
        Supprimer l'offre : {item.item.data.name} du{" "}
        {new Date(item.item.data.date.seconds * 1000).toLocaleDateString()}
      </h1>
    </div>
  );
};

export default Delete;
