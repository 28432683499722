/*eslint-disable */
import './Admin.css'
import React, { useState } from 'react';
import Nav from "../../Nav"
import { database } from "../../../firebase";
import { useNavigate, useLocation } from "react-router-dom";
import { arrayUnion, arrayRemove, updateDoc, collection, doc, getDoc } from "firebase/firestore";
import { AiFillCloseCircle } from "react-icons/ai";
import { useUserAuth } from "../../../context/UserAuthContext";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { ClickAwayListener } from '@mui/base';

const PromotionCategories = () => {

    const dimension = {
        width: window.innerWidth,
        height: window.innerHeight,
    };

    const { user, logOut } = useUserAuth();
    const navigate = useNavigate();
    const location = useLocation()

    const partners = location.state.partners;
    const [showProfilPanel, setShowProfilPanel] = useState(false);
    const [selectedPartners, setSelectedPartners] = useState("");
    const [categories, setCategories] = useState([""]);

    const handleClose = () => { // close modal profil
        setShowProfilPanel(false);
    };

    const handleLogout = async () => { // logout user
        try {
            await logOut();
            navigate("/");
            setTimeout(() => {
                console.clear()
            }, 250);
        } catch (error) {
            alert("Une erreur est survenue lors de votre déconnexion");
        }
    };

    const openAccountModal = () => {
        setShowProfilPanel(true)
    }

    const handleSelectPartner = (partner) => {
        setSelectedPartners(partner);
        fetchAllCategoriesAvailable(partner);
    };

    const fetchAllCategoriesAvailable = async (partner) => {
        try {
            const promotionsCollectionRef = collection(database, 'promotions');
            const categoriesDocRef = doc(promotionsCollectionRef, 'categories');

            const docSnapshot = await getDoc(categoriesDocRef);
            if (docSnapshot.exists()) {
                // Le document "categories" existe
                const categoriesData = docSnapshot.data().list;

                // Si le partenaire ne possède aucune catégorie
                if (partner.data.promotion.categories.length === 0) {
                    setCategories(categoriesData)
                }

                else {
                    const categoriesToAdd = categoriesData.filter(item => !partner.data.promotion.categories.includes(item));
                    setCategories(categoriesToAdd);
                }


            } else {
                // Le document "categories" n'existe pas
                console.error('Le document "categories" n\'existe pas.');
            }
        } catch (error) {
            // Gérez les erreurs ici
            console.error('Erreur lors de la récupération des catégories :', error);
        }
    };

    const addToCategory = async (category) => {

        try {
            const PartnerDocRef = doc(database, 'partners', selectedPartners.id);
            await updateDoc(PartnerDocRef, {
                'promotion.categories': arrayUnion(category),
            });

            // Update categories on the partner
            const partner = { ...selectedPartners }
            partner.data.promotion.categories.push(category)
            setSelectedPartners(partner)

            //Update available categories to add on partner
            const newCategories = [...categories]
            const indexToRemove = newCategories.indexOf(category)
            if (indexToRemove !== -1) {
                newCategories.splice(indexToRemove, 1)
                setCategories(newCategories)
            }


        } catch (error) {
            console.log(error)
        }

    }

    const removeToCategory = async (category) => {

        try {
            const PartnerDocRef = doc(database, 'partners', selectedPartners.id);
            await updateDoc(PartnerDocRef, {
                'promotion.categories': arrayRemove(category),
            });

            // Update categories on the partner
            const partner = { ...selectedPartners }
            const indexToRemove = partner.data.promotion.categories.indexOf(category)
            if (indexToRemove !== -1) {
                partner.data.promotion.categories.splice(indexToRemove, 1)
                setSelectedPartners(partner)
            }

            //Update available categories to add on partner
            const newCategories = [...categories]
            newCategories.push(category)
            setCategories(newCategories)


        } catch (error) {
            console.log(error)
        }

    }

    return (

        <>
            <Modal
                open={showProfilPanel}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                hideBackdrop={dimension.width > 768 ? true : false}
            >
                <>
                    <ClickAwayListener onClickAway={handleClose}>
                        <Box className={user.uid === "p5aTQsaMqPdnL7ynK7v9dJSdgH83" ? "myOffers-box-admin" : "myOffers-box"}>
                            <AiFillCloseCircle
                                className="myOffers-modal-close"
                                onClick={() => {
                                    handleClose();
                                }}
                            />
                            <div className="myOffers-modalContainer">
                                <div className="myOffers-modal-profilCircle">
                                    <p className="myOffers-modal-profiltext">
                                        {user.name !== undefined ? user.name[0] : null}
                                    </p>
                                </div>
                                <h2 className="myOffers-modal-title">
                                    {user.name !== undefined ? user.name : null}
                                </h2>
                            </div>
                            <div
                                className="myOffers-modal-param"
                                onClick={() => {
                                    navigate("/params");
                                }}
                            >
                                <p className="myOffers-modal-param-text">Paramètre du compte</p>
                            </div>

                            {user.uid === "p5aTQsaMqPdnL7ynK7v9dJSdgH83" &&
                                <>
                                    <div
                                        className="myOffers-modal-param"
                                        onClick={() => {
                                            navigate("/partners-management");
                                        }}
                                    >
                                        <p className="myOffers-modal-param-text">Gestion des partenaires</p>
                                    </div>

                                    <div
                                        className="myOffers-modal-param"
                                        onClick={() => {
                                            navigate("/promotions-management");
                                        }}
                                    >
                                        <p className="myOffers-modal-param-text">Gérer les promotions</p>
                                    </div>
                                </>
                            }

                            <div
                                onClick={() => {
                                    handleLogout();
                                }}
                                className="myOffers-modal-logoutBtn"
                            >
                                <p className="myOffers-modal-logoutText">Déconnexion</p>
                            </div>
                        </Box>
                    </ClickAwayListener>
                </>
            </Modal>

            <Nav user={user} onPress={openAccountModal} />

            <div className="selection-of-the-week-header">
                <h1 className="selection-of-the-week-title">Affectation des catégories à une marque</h1>
            </div>

            <h2 style={{ fontFamily: "Geomatrix", paddingLeft: 15, paddingBottom: 15, }}>{selectedPartners !== "" ? selectedPartners.data.name : "Tous les partenaires"}</h2>

            {selectedPartners === "" ?
                <div className="selection-of-the-week-content">
                    <>
                        {partners.length === 0 ?
                            <div className="selection-of-the-week-dots">
                                <Dots color="#2A7AF5" size={30} />
                            </div>
                            :
                            <div className="selection-of-the-week-partners-wrap">
                                {partners.sort((a, b) =>
                                    a.data.name.localeCompare(b.data.name)
                                ).map((partner) => (
                                    <div key={partner.id}>
                                        <img src={partner.data.logo} alt={`${partner.data.name} logo`} className="selection-of-the-week-partners-logo" />
                                        <p>{partner.data.name}</p>
                                        <button className="selection-of-the-week-remove-partners" onClick={() => handleSelectPartner(partner)}>
                                            Sélectionner
                                        </button>
                                    </div>
                                ))}
                            </div>
                        }
                    </>
                </div>
                :
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                    <div className="promotion">
                        <h3>Catégorie(s) affectable(s) à {selectedPartners.data.name}</h3>
                        <div className="promotion-existing-category">
                            {categories.length > 0 ?
                                <>
                                    {categories.map((category, index) => (
                                        <div key={index}>
                                            <p>{category}</p>
                                            <button style={{ backgroundColor: "#20A983" }} onClick={() => addToCategory(category)}>Ajouter à la catégorie</button>
                                        </div>
                                    ))}
                                </>
                                :
                                <>
                                    <p>{selectedPartners.data.name} possède déjà toutes les catégories.</p>
                                    <div className="promotion-create-categories" onClick={() => navigate("/promotions-management/")}>
                                        <p>Cliquez ici pour créer des catégories</p>
                                    </div>
                                </>
                            }
                        </div>
                    </div>

                    <div className="promotion">
                        <h3>Catégorie(s) retirable(s) de {selectedPartners.data.name}</h3>
                        <div className="promotion-existing-category">
                            {selectedPartners.data.promotion.categories.length > 0 ?
                                <>
                                    {selectedPartners.data.promotion.categories.map((category, index) => (
                                        <div key={index}>
                                            <p>{category}</p>
                                            <button onClick={() => removeToCategory(category)}>Retirer de la catégorie</button>
                                        </div>
                                    ))}
                                </>
                                :
                                <>
                                    <p>{selectedPartners.data.name} ne possède aucune catégorie actuellement</p>
                                </>
                            }
                        </div>

                    </div>
                </div>

            }
        </>
    );
}

export default PromotionCategories;
