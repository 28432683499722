import "./OfferDetails.css";
import React, { useRef, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AiFillCloseCircle } from "react-icons/ai";
import { useUserAuth } from "../../context/UserAuthContext";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { ClickAwayListener } from '@mui/base';
import Analytics from "./Analytics";
import Preview from "./Preview";
import Modify from "./Modify";
import Delete from "./Delete";
import { Helmet } from "react-helmet";
import Nav from "../Nav"

const OfferDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, logOut } = useUserAuth();
  const [offerDetails, setOfferDetails] = useState();
  const [showProfilPanel, setShowProfilPanel] = useState(false);
  const [activeContent, setActiveContent] = useState("");
  const [loaded, setLoaded] = useState(false);
  const ref = useRef(null);
  const dimension = {
    width: window.innerWidth,
    height: window.innerHeight,
  };

  const handleClose = () => { // close modal profil
    setShowProfilPanel(false);
  };

  const handleLogout = async () => { // logout user
    try {
      await logOut();
      navigate("/");
      setTimeout(() => {
        console.clear()
      }, 250);
    } catch (error) {
      alert("Une erreur est survenue lors de votre déconnexion");
    }
  };

  const openAccountModal = () => {
    setShowProfilPanel(true)
  }

  useEffect(() => {
    if (location.state === null) {  // redirect home page if there is no details of offers
      navigate("/home");
    } else {
      setOfferDetails(location.state.item);
      setLoaded(true);
    }
  }, []);

  return loaded ? (
    <div ref={ref}>

      <Helmet>
        <title>Sharlockpro - Aperçu de la communication </title>
        <meta name="keywords" content="sharlockpro, offers, communication, aperçu, offres, Sharlockpro.fr" />
        <meta name="description" content="Visualiser le détail de la communication" />
      </Helmet>

      <Modal
        open={showProfilPanel}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        hideBackdrop={dimension.width > 768 ? true : false}
      >
        <>
          <ClickAwayListener onClickAway={handleClose}>
            <Box className={user.uid === "p5aTQsaMqPdnL7ynK7v9dJSdgH83" ? "myOffers-box-admin" : "myOffers-box"}>
              <AiFillCloseCircle
                className="myOffers-modal-close"
                onClick={() => {
                  handleClose();
                }}
              />
              <div className="myOffers-modalContainer">
                <div className="myOffers-modal-profilCircle">
                  <p className="myOffers-modal-profiltext">
                    {user.name !== undefined ? user.name[0] : null}
                  </p>
                </div>
                <h2 className="myOffers-modal-title">
                  {user.name !== undefined ? user.name : null}
                </h2>
              </div>
              <div
                className="myOffers-modal-param"
                onClick={() => {
                  navigate("/params");
                }}
              >
                <p className="myOffers-modal-param-text">Paramètre du compte</p>
              </div>

              {user.uid === "p5aTQsaMqPdnL7ynK7v9dJSdgH83" &&
                <>
                  <div
                    className="myOffers-modal-param"
                    onClick={() => {
                      navigate("/partners-management");
                    }}
                  >
                    <p className="myOffers-modal-param-text">Gestion des partenaires</p>
                  </div>

                  <div
                    className="myOffers-modal-param"
                    onClick={() => {
                      navigate("/promotions-management");
                    }}
                  >
                    <p className="myOffers-modal-param-text">Gérer les promotions</p>
                  </div>
                </>
              }

              <div
                onClick={() => {
                  handleLogout();
                }}
                className="myOffers-modal-logoutBtn"
              >
                <p className="myOffers-modal-logoutText">Déconnexion</p>
              </div>
            </Box>
          </ClickAwayListener>
        </>
      </Modal>

      <Nav user={user} onPress={openAccountModal} />

      {offerDetails !== undefined ? (
        <div className="offerDetails-main">
          <img
            src={offerDetails.data.picture}
            alt={offerDetails.data.name}
            className="offerDetails-main-img"
          />
          <h2 className="offerDetails-main-name">{offerDetails.data.name}</h2>
          <p className="offerDetails-main-date">
            Publié le :{" "}
            {new Date(
              offerDetails.data.creationDate.seconds * 1000
            ).toLocaleDateString()}
          </p>

          {offerDetails.data.date.seconds * 1000 > new Date().getTime() || offerDetails.data.date.seconds * 1000 + 86400000 > new Date().getTime() ?
            <div className="offerDetails-main-status-container">
              <p className="offerDetails-main-status-text"> En cours </p>
            </div>
            :
            <div className="offerDetails-main-status-expired-container">
              <p className="offerDetails-main-status-text"> Expiré</p>
            </div>
          }
          <div className="offerDetails-main-card">
            <p className="offerDetails-main-card-text">
              Pour voir l’aperçu de la communication, la modifier ou accéder à ces
              données analytiques{" "}
              <span className="offerDetails-main-card-span">
                veuillez vous connecter sur votre ordinateur.
              </span>
            </p>
          </div>
        </div>
      ) : null}

      <div className="offerDetails-left-panel" >
        <div
          className={
            activeContent === "Preview"
              ? "offerDetails-panel-actionFocus"
              : activeContent === ""
                ? "offerDetails-panel-actionFocus"
                : "offerDetails-panel-action"
          }
          onClick={() => {
            setActiveContent("Preview");
          }}
        >
          <p className="offerDetails-panel-navTitle">Aperçu</p>
        </div>
        <div
          className={
            activeContent === "Modify"
              ? "offerDetails-panel-actionFocus"
              : "offerDetails-panel-action"
          }
          onClick={() => {
            setActiveContent("Modify");
          }}
        >
          <p className="offerDetails-panel-navTitle">Modifier</p>
        </div>
        {/* <div
          className={
            activeContent === "Analytics"
              ? "offerDetails-panel-actionFocus"
              : "offerDetails-panel-action"
          }
          onClick={() => {
            setActiveContent("Analytics");
          }}
        >
          <p className="offerDetails-panel-navTitle">Données analytiques</p>
        </div> */}
      </div>
      <div className="offerDetails-right-panel-content" >
        {activeContent === "Analytics" ? (
          <Analytics />
        ) : activeContent === "Preview" ? (
          <Preview item={offerDetails} />
        ) : activeContent === "Modify" ? (
          <Modify item={offerDetails} />
        ) : activeContent === "Delete" ? (
          <Delete item={offerDetails} />
        ) : (
          <Preview item={offerDetails} />
        )}
      </div>
    </div>
  ) : null;
};

export default OfferDetails;
