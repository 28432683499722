import "./MyOffers.css";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiFillCloseCircle } from "react-icons/ai";
import { useUserAuth } from "../../context/UserAuthContext";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { ClickAwayListener } from '@mui/base';
import { Helmet } from "react-helmet";
import Nav from "../Nav"

const MyStats = () => {
  const { user, logOut } = useUserAuth();
  const navigate = useNavigate();
  const [showProfilPanel, setShowProfilPanel] = useState(false);
  const dimension = {
    width: window.innerWidth,
    height: window.innerHeight,
  };
  const handleClose = () => { // close modal profil
    setShowProfilPanel(false);
  };

  const handleLogout = async () => { // logout user
    try {
      await logOut();
      navigate("/");
      setTimeout(() => {
        console.clear()
      }, 250);
    } catch (error) {
      alert("Une erreur est survenue lors de votre déconnexion");
    }
  };

  const openAccountModal = () => {
    setShowProfilPanel(true)
  }

  return (
    <>
      <Helmet>
        <title>Sharlockpro - Mes statistiques</title>
        <meta name="keywords" content="sharlockpro, statistiques, Sharlockpro.fr" />
        <meta name="description" content="Consulter les statistiques de vos communications, votre audience, le trafic et bien d'autres possibilités." />
      </Helmet>

      <Modal
        open={showProfilPanel}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        hideBackdrop={dimension.width > 768 ? true : false}
      >
        <>
          <ClickAwayListener onClickAway={handleClose}>
            <Box className={user.uid === "p5aTQsaMqPdnL7ynK7v9dJSdgH83" ? "myOffers-box-admin" : "myOffers-box"}>
              <AiFillCloseCircle
                className="myOffers-modal-close"
                onClick={() => {
                  handleClose();
                }}
              />
              <div className="myOffers-modalContainer">
                <div className="myOffers-modal-profilCircle">
                  <p className="myOffers-modal-profiltext">
                    {user.name !== undefined ? user.name[0] : null}
                  </p>
                </div>
                <h2 className="myOffers-modal-title">
                  {user.name !== undefined ? user.name : null}
                </h2>
              </div>
              <div
                className="myOffers-modal-param"
                onClick={() => {
                  navigate("/params");
                }}
              >
                <p className="myOffers-modal-param-text">Paramètre du compte</p>
              </div>

              {user.uid === "p5aTQsaMqPdnL7ynK7v9dJSdgH83" &&
                <>
                  <div
                    className="myOffers-modal-param"
                    onClick={() => {
                      navigate("/partners-management");
                    }}
                  >
                    <p className="myOffers-modal-param-text">Gestion des partenaires</p>
                  </div>

                  <div
                    className="myOffers-modal-param"
                    onClick={() => {
                      navigate("/promotions-management");
                    }}
                  >
                    <p className="myOffers-modal-param-text">Gérer les promotions</p>
                  </div>
                </>
              }

              <div
                onClick={() => {
                  handleLogout();
                }}
                className="myOffers-modal-logoutBtn"
              >
                <p className="myOffers-modal-logoutText">Déconnexion</p>
              </div>
            </Box>
          </ClickAwayListener>
        </>
      </Modal>

      <Nav user={user} onPress={openAccountModal} />


      <div className="myOffers-main">
        <div className="myOffers-main-row">
          <div className="myOffers-main-col-1">
            <h1 className="myOffers-mainTitle">Mes statistiques</h1>
            <p className="myOffers-mainSubtitle">
              Consultez toutes les statistiques de votre activité sur Sharlock
            </p>
          </div>
        </div>

        <div className="myOffers-allOffers">
          <h2 className="myOffers-allOffers-title">Toutes mes statistiques</h2>
          <hr className="myOffers-allOffers-hr" />
        </div>

        <div className="myOffers-container-desktop-list">
          <div className="myOffers-content-list-stats">
            <div
              className="myOffers-content-card"
              onClick={() => {
                const parameterValue = "overview";
                //navigate(`/statistics/${parameterValue}`)
              }}
            >
              <div className="myOffers-content-card-unavailable">
                <p className="myOffers-content-card-unavailable-text">Bientôt disponible</p>
              </div>
              <img
                src={require("../../assets/img/stats_1.png")}
                alt="woman in black tank top wearing black framed sunglasses"
                className="myOffers-content-card-img"
              />
              <div className="myOffers-content-bottom-card">
                <p className="myOffers-content-bottom-card-text">Aperçu global de l’influence de mes communications</p>
              </div>
            </div>

            <div
              className="myOffers-content-card myOffers-content-card-lineargradient"
              style={{ textAlign: "center" }}
              onClick={() => {
                const parameterValue = "trafic";
                //navigate(`/statistics/${parameterValue}`)
              }}
            >
              <div className="myOffers-content-card-unavailable">
                <p className="myOffers-content-card-unavailable-text">Bientôt disponible</p>
              </div>
              <img
                src={require("../../assets/img/stats_2.png")}
                alt="trafic overview"
              />
              <div className="myOffers-content-bottom-card">
                <p className="myOffers-content-bottom-card-text">Aperçu du trafic</p>
              </div>
            </div>

            <div
              className="myOffers-content-card myOffers-content-card-lineargradient2"
              onClick={() => {
                const parameterValue = "audience";
              //  navigate(`/statistics/${parameterValue}`)
              }}
            >
              <div className="myOffers-content-card-unavailable">
                <p className="myOffers-content-card-unavailable-text">Bientôt disponible</p>
              </div>
              <img
                src={require("../../assets/img/stats_3.png")}
                alt="audience informations"
                className="myOffers-content-card-img"
              />
              <div className="myOffers-content-bottom-card">
                <p className="myOffers-content-bottom-card-text">Informations sur mon audience</p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default MyStats;



